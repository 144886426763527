<!--
   [2024/08/27 sb.hwang]
   AlertDialog.vue
   @note 알림 다이얼로그 컴포넌트
-->
<template>
    <v-dialog v-model="dialog" :max-width="width" persistent>
        <v-card elevation="0" class="rounded-sm pa-8">
            <v-card-title
                :class="titleStyle"
                class="justify-center star-xr-h5 font-semi-bold pa-0"
            >
                {{ $t(title) }}
            </v-card-title>
            <v-card-text class="text-center star-xr-h6 font-gray8 px-0 pt-5">
                <div v-html="$t(content)" />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    :class="agreeStyle"
                    :width="btnWidth"
                    height="40"
                    elevation="0"
                    class="mr-3"
                    @click="clickAgree"
                >
                    {{ $t(agree) }}
                </v-btn>
                <v-btn
                    v-if="isDisable"
                    :class="disagreeStyle"
                    :width="btnWidth"
                    height="40"
                    elevation="0"
                    class=""
                    @click="clickDisagree"
                >
                    {{ $t(disagree) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        title: {
            type: String,
        },
        content: {
            type: String,
        },
        agree: {
            type: String,
            default: "confirm",
        },
        disagree: {
            type: String,
            default: "cancel",
        },
        titleStyle: {
            type: String,
            default: "font-orange",
        },
        agreeStyle: {
            type: String,
            default: "star-xr-btn-orange-outlined",
        },
        disagreeStyle: {
            type: String,
            default: "star-xr-btn-gray-outlined",
        },
        value: {
            type: Boolean,
        },
        width: {
            type: String,
            default: "290",
        },
        btnWidth: {
            type: String,
            default: "110",
        },
        isDisable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        /**
         * @method clickAgree
         * @note 알림 다이얼로그 agree 클릭 함수
         * @email sb.hwang@naviworks.com
         */
        clickAgree() {
            this.dialog = false;
            this.$emit("agree");
        },
        /**
         * @method clickDisagree
         * @note 알림 다이얼로그 disagree 클릭 함수
         * @email sb.hwang@naviworks.com
         */
        clickDisagree() {
            this.dialog = false;
            this.$emit("disagree");
        },
    },
};
</script>

<style scoped></style>
