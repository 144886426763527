<!--
   [2024/08/21 gy.yang]
   StarXrToolsDownload.vue
   @note Tools 다운로드 사양 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="downloadInfo"
            hide-default-footer
            no-data-text="데이터 없음"
            class="star-xr-data-table read-only"
        >
            <template v-slot:body="{ items }">
                <tbody v-if="!$vuetify.breakpoint.xs">
                    <tr v-for="item in items" :key="item.type">
                        <td class="item-bg-gray font-semi-bold text-center">
                            {{ $t(item.type) }}
                        </td>
                        <td
                            :colspan="item.recommendSpecs ? 0 : 2"
                            class="font-light text-center"
                        >
                            {{ $t(item.minSpecs) }}
                        </td>
                        <td
                            v-if="item.recommendSpecs"
                            class="font-light text-center"
                        >
                            {{ $t(item.recommendSpecs) }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr
                        v-for="item in items"
                        :key="item.type"
                        class="v-data-table__empty-wrapper"
                    >
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">
                                {{ $t("category") }}
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell font-light"
                            >
                                {{ $t(item.type) }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">
                                {{ $t("minimumRequirements") }}
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell font-light"
                            >
                                {{ $t(item.minSpec) }}
                            </div>
                        </td>
                        <td
                            v-if="item.recommendSpecs"
                            class="v-data-table__mobile-row"
                        >
                            <div class="v-data-table__mobile-row__header">
                                {{ $t("recommendedRequirements") }}
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell font-light"
                            >
                                {{ $t(item.recommendSpecs) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "StarXrToolsDownload",
    components: {},
    computed: {
        headers() {
            return [
                {
                    text: this.$t("category"),
                    value: "type",
                    align: "center",
                    width: "15%",
                    sortable: false,
                },
                {
                    text: this.$t("minimumRequirements"),
                    value: "minSpecs",
                    align: "center",
                    sortable: false,
                },
                {
                    text: this.$t("recommendedRequirements"),
                    value: "recommendSpecs",
                    align: "center",
                    sortable: false,
                },
            ];
        },
    },
    data() {
        return {
            downloadInfo: [
                {
                    type: "os",
                    minSpecs: "osMinimumContent",
                    recommendSpecs: "",
                },
                {
                    type: "cpu",
                    minSpecs: "cpuMinimumContent",
                    recommendSpecs: "cpuRecommendedContent",
                },
                {
                    type: "ram",
                    minSpecs: "ramMinimumContent",
                    recommendSpecs: "ramRecommendedContent",
                },
                {
                    type: "hdd",
                    minSpecs: "hddMinimumContent",
                    recommendSpecs: "hddRecommendedContent",
                },
                {
                    type: "graphics",
                    minSpecs: "graphicsMinimumContent",
                    recommendSpecs: "graphicsRecommendedContent",
                },
            ],
        };
    },
    methods: {},
};
</script>

<style scoped>
.star-xr-data-table::v-deep .v-data-table-header th span {
    color: #202020;
    font-family: Pretendard-SemiBold, "Pretendard-SemiBold" !important;
}

.star-xr-data-table::v-deep .v-data-table-header th,
.star-xr-data-table::v-deep tbody td {
    color: #202020;
    font-size: 14px !important;
    border-bottom: 1px solid #e2e2e2 !important;
}

.star-xr-data-table::v-deep .v-data-table-header th:not(:last-child),
.star-xr-data-table::v-deep tbody td:not(:last-child) {
    border-right: 1px solid #e2e2e2 !important;
}

.star-xr-data-table .item-bg-gray {
    color: #202020;
    background-color: #fafafa !important;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .star-xr-data-table::v-deep .v-data-table__mobile-row__header {
        min-width: 100px;
        text-align: start;
    }

    .star-xr-data-table::v-deep .v-data-table-header th,
    .star-xr-data-table::v-deep tbody td,
    .star-xr-data-table::v-deep .v-data-table-header th:not(:last-child),
    .star-xr-data-table::v-deep tbody td:not(:last-child) {
        border: none !important;
    }

    .star-xr-data-table::v-deep .v-data-table__mobile-row {
        border-bottom: 1px solid #e2e2e2 !important;
    }
}
</style>
