import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from './user';
import service from './service';
import community from './community';
import docMenus from './docMenus';
import docs from './docs';
import adminUser from './adminUser';
import file from './file';
import payment from './payment';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        user,
        service,
        community,
        docMenus,
        docs,
        adminUser,
        file,
        payment
    },
    plugins: [
        createPersistedState({
            paths: [
                'user',
                'service',
                'community',
                'docs'
            ],
        })
    ]
});
