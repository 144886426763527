<!--
   [2024/08/09 gy.yang]
   StarXrLogin.vue
   @note STAR-XR 로그인 화면
-->
<template>
    <v-container fluid fill-height class="main-wrap">
        <v-row justify="center">
            <v-col class="px-md-0 px-6" cols="12" sm="8" lg="4" xl="3">
                <v-card class="login-box py-14">
                    <v-img
                        :src="require('@/assets/images/star-xr-logo-dark.svg')"
                        contain
                        height="50"
                        class="cursor-pointer mb-16"
                    />
                    <v-row justify="center" class="mt-8">
                        <v-col cols="10" xl="8">
                            <v-text-field
                                v-model="emlAddr"
                                class="star-xr-input"
                                :placeholder="$t('enterEmail')"
                                @keyup.enter="userLogin"
                            />
                            <v-text-field
                                v-model="pswd"
                                type="password"
                                class="star-xr-input pt-0 mt-0"
                                :placeholder="$t('enterPassword')"
                                @keyup.enter="userLogin"
                            />
                            <v-btn
                                block
                                height="40"
                                class="star-xr-btn-orange"
                                @click="userLogin"
                            >
                                {{ $t("login") }}
                            </v-btn>
                            <div class="text-center font-gray6 star-xr-h6 mt-3">
                                <span
                                    class="bottom-btn font-gray7 star-xr-h6 font-medium cursor-pointer"
                                    @click="routerSignUp"
                                >
                                    {{ $t("signUp") }}
                                </span>
                                <v-divider vertical class="mx-2" />
                                <span
                                    class="bottom-btn font-gray7 star-xr-h6 font-medium cursor-pointer"
                                    @click="routerFindPw"
                                >
                                    {{ $t("findPassword") }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="showDialog"
            title="signInFailure"
            :content="content"
            :isDisable="false"
        />
    </v-container>
</template>

<script>
import AlertDialog from "@/components/com/AlertDialog.vue";

export default {
    name: "StarXrLogin",
    components: {
        AlertDialog,
    },
    data() {
        return {
            emlAddr: "",
            pswd: "",
            token: "",
            showDialog: false,
            content: "",
        };
    },
    methods: {
        /**
         * @method routerSignUp
         * @note 회원가입으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerSignUp() {
            this.$router
                .push({
                    name: "starXrSignUp",
                })
                .catch(() => {});
        },
        /**
         * @method routerFindPw
         * @note 비멀번호 찾기로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerFindPw() {
            this.$router
                .push({
                    name: "starXrFindPw",
                })
                .catch(() => {});
        },
        /**
         * @method routerMyPage
         * @note 마이페이지로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        routerMyPage() {
            this.$router
                .push({
                    name: "starXrMyPage",
                })
                .catch(() => {});
        },
        /**
         * @method userLogin
         * @note 로그인하는 함수
         * @email sb.hwang@naviworks.com
         */
        userLogin() {
            const params = {
                emlAddr: this.emlAddr,
                pswd: this.pswd,
            };

            this.$store
                .dispatch("userLogin", params)
                .then((res) => {
                    let loginUser = res.user;
                    loginUser.emlAddr = this.emlAddr;
                    this.$store.commit("setLoginUser", loginUser);
                    this.$store.commit("setToken", res.token);

                    this.$cookies.set("user_id", this.emlAddr, 0);
                    this.$cookies.set("user_token", res.token, 0);
                    location.href = "/";
                })
                .catch(() => {
                    this.content = "signInFailureMessage";
                    this.showDialog = true;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.main-wrap {
    max-height: 100%;
    background-color: #f9f9f9;
}

.login-box {
    height: 470px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: none !important;
}

.star-xr-input::v-deep .v-input__slot {
    margin-top: 3px !important;
    margin-bottom: 1px !important;
}

.bottom-btn:hover {
    color: #939393;
    text-decoration: underline;
}

.v-divider {
    height: 8px;
    border-color: #b4b4b4;
    vertical-align: middle;
}
</style>
