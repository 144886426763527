/**
 * [2024/08/27 sb.hwang]
 * community.js
 * @note 커뮤니티 store
 */

import axios from '../axios';
import config from '../config';

const state =
{
    communitySn: 0,
    postTtl: '',
    postCn: '',
    userNm: '',
    companyNm: '',
    crtDt: '',
    uploadFileSn: 0,
};
  
const actions=
{
    /**
     * @method resetCommunity
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email sb.hwang@naviworks.com
     */
    resetCommunity(context) {
        const emptyContent = {};

        context.commit('setCommunity', emptyContent);
    },
    /**
    * @method selectCommunityList
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 커뮤니티 목록을 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    selectCommunityList() {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/community`)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method selectCommunity
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 커뮤니티 상세를 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    selectCommunity(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/community/${params}`)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method postCommunity
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 게시글을 등록하는 함수
    * @email sb.hwang@naviworks.com
    */
    postCommunity(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .post(`${config.apiServerUrl}/community`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method editCommunity
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 게시글을 수정하는 함수
    * @email sb.hwang@naviworks.com
    */
    editCommunity(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .put(`${config.apiServerUrl}/community`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method deleteCommunity
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 게시글을 삭제하는 함수
    * @email sb.hwang@naviworks.com
    */
    deleteCommunity(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .delete(`${config.apiServerUrl}/community`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method postCommunityResponse
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 답변을 등록하는 함수
    * @email sb.hwang@naviworks.com
    */
    postCommunityResponse(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .post(`${config.apiServerUrl}/community/response`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method updateCommunityResponse
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 답변을 수정하는 함수
    * @email sb.hwang@naviworks.com
    */
    updateCommunityResponse(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .put(`${config.apiServerUrl}/community/response`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method deleteCommunityResponse
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 답변을 삭제하는 함수
    * @email sb.hwang@naviworks.com
    */
    deleteCommunityResponse(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .delete(`${config.apiServerUrl}/community/response`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
};

const getters=
{
    
};

const mutations= 
{
    setCommunity(state, community) {
        state.communitySn = community.communitySn || 0;
        state.postTtl = community.postTtl || '',
        state.postCn = community.postCn || '',
        state.userNm = community.userNm || '',
        state.companyNm = community.companyNm || '',
        state.crtDt = community.crtDt || '',
        state.uploadFileSn = community.uploadFileSn || 0
    },
    setCommunitySn(state, communitySn) {
        state.communitySn = communitySn;
    },
    setCommunityPostTtl(state, postTtl) {
        state.postTtl = postTtl;
    },
    setCommunityPostCn(state, postCn) {
        state.postCn = postCn;
    },
};

export default
{
    state, actions, getters, mutations
};