/**
 * [2024/09/05 sb.hwang]
 * user.js
 * @note 사용자 store
 */

import axios from "../axios";
import config from "../config";

const state = {
    accountSn: 0,
    emlAddr: "",
    pswd: "",
    userNm: "",
    brDt: "",
    telNo: "",
    companyNm: "",
    nickNm: "",
    subscriptSn: 0,
    approvedSn: 0,
    authrtSn: 0,
    token: "",
    translation: "ko",
};

const actions = {
    /**
     * @method resetUser
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email sb.hwang@naviworks.com
     */
    resetUser(context) {
        const emptyContent = {};

        context.commit("setUser", emptyContent);
    },
    /**
     * @method resetLoginUser
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email sb.hwang@naviworks.com
     */
    resetLoginUser(context) {
        const emptyContent = {};

        context.commit("setLoginUser", emptyContent);
    },
    /**
     * @method emlCheck
     * @param {object} context 컨텍스트 객체
     * @param {string} email 이메일
     * @note 이메일 중복 여부를 확인하는 함수
     * @email gy.yang@naviworks.com
     */
    emlCheck(_, email) {
        return new Promise(function (resolve, reject) {
            axios
                .get(
                    `${config.apiServerUrl}/accounts/checkDupEmlAddr?emlAddr=${email}`
                )
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method emlCertifySend
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 인증 메일을 발송하는 함수
     * @email sb.hwang@naviworks.com
     */
    emlCertifySend(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(
                    `${config.apiServerUrl}/accounts/email/verification/requests`,
                    params
                )
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method emlCertifyCheck
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 인증번호를 확인하는 함수
     * @email sb.hwang@naviworks.com
     */
    emlCertifyCheck(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(
                    `${config.apiServerUrl}//accounts/email/verification?emlAddr=${params.emlAddr}&randomNumber=${params.randomNumber}`
                )
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method nickNmCheck
     * @param {object} context 컨텍스트 객체
     * @param {string} nickNm 닉네임
     * @note 닉네임 중복 여부를 확인하는 함수
     * @email gy.yang@naviworks.com
     */
    nickNmCheck(_, nickNm) {
        return new Promise(function (resolve, reject) {
            axios
                .get(
                    `${config.apiServerUrl}/accounts/checkDupNickNm?nickNm=${nickNm}`
                )
                .then((res) => {
                    resolve(res.data.errorMsg);
                })
                .catch((err) => {
                    reject(err.response);
                });
        });
    },
    /**
     * @method userSignup
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 회원가입하는 함수
     * @email sb.hwang@naviworks.com
     */
    userSignup(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(`${config.apiServerUrl}/accounts/register`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    /**
     * @method userLogin
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 로그인하는 함수
     * @email sb.hwang@naviworks.com
     */
    userLogin(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(`${config.apiServerUrl}/accounts/login`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    /**
     * @method findPswd
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 비밀번호 찾기 함수
     * @email sb.hwang@naviworks.com
     */
    findPswd(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/accounts/email/findPswd`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method checkOldPswd
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 비밀번호 찾기 함수
     * @email sb.hwang@naviworks.com
     */
    checkOldPswd(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/accounts/checkOldPswd`, { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updatePswd
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 비밀번호 변경 함수
     * @email sb.hwang@naviworks.com
     */
    updatePswd(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/accounts/updatePswd`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method getAccount
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 마이페이지 조회 함수
     * @email sb.hwang@naviworks.com
     */
    getAccount() {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/accounts/mypage`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updateAccount
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 마이페이지 수정 함수
     * @email sb.hwang@naviworks.com
     */
    updateAccount(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/accounts/mypage`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method deleteAccount
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 회원 탈퇴 함수
     * @email sb.hwang@naviworks.com
     */
    deleteAccount() {
        return new Promise(function (resolve, reject) {
            axios
                .delete(`${config.apiServerUrl}/accounts/deleteAccount`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
};

const getters = {
    getTranslation: function (state) {
        return {
            translation: state.translation,
        };
    },
};

const mutations = {
    setUser(state, user) {
        (state.emlAddr = user.emlAddr || ""),
            (state.pswd = user.pswd || ""),
            (state.userNm = user.userNm || ""),
            (state.brDt = user.brDt || ""),
            (state.telNo = user.telNo || "");
        state.authrtSn = user.authrtSn || 0;
        state.approvedSn = user.approvedSn || 0;
        state.companyNm = user.companyNm || "";
        state.nickNm = user.nickNm || "";
    },
    setUserEmlAddr(state, emlAddr) {
        state.emlAddr = emlAddr;
    },
    setUserPswd(state, pswd) {
        state.pswd = pswd;
    },
    setUserCompanyNm(state, companyNm) {
        state.companyNm = companyNm;
    },
    setUserNickNm(state, nickNm) {
        state.nickNm = nickNm;
    },
    setUserUserNm(state, userNm) {
        state.userNm = userNm;
    },
    setUserBrDt(state, brDt) {
        state.brDt = brDt;
    },
    setUserTelNo(state, telNo) {
        state.telNo = telNo;
    },
    setUserCompany(state, userInfo) {
        (state.nickNm = userInfo.nickNm),
            (state.companyNm = userInfo.companyNm);
    },
    setUserInfo(state, userInfo) {
        (state.userNm = userInfo.userNm),
            (state.brDt = userInfo.brDt),
            (state.telNo = userInfo.telNo);
    },
    setLoginUser(state, user) {
        (state.accountSn = user.accountSn || 0),
            (state.emlAddr = user.emlAddr || ""),
            (state.userNm = user.userNm || ""),
            (state.brDt = user.brDt || ""),
            (state.telNo = user.telNo || "");
        state.nickNm = user.nickNm || "";
        (state.companyNm = user.companyNm || ""),
            (state.authrtSn = user.authrtSn || 0),
            (state.subscriptSn = user.subscriptSn || 0),
            (state.approvedSn = user.approvedSn || 0),
            (state.token = user.token || "");
    },
    setToken(state, token) {
        state.token = token || "";
    },
    setMypage(state, user) {
        (state.accountSn = user.accountSn || 0),
            (state.emlAddr = user.emlAddr || ""),
            (state.userNm = user.userNm || ""),
            (state.brDt = user.brDt || ""),
            (state.telNo = user.telNo || "");
        state.companyNm = user.companyNm || "";
        state.nickNm = user.nickNm || "";
    },
    setTranslation(state, translation) {
        state.translation = translation;
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
};
