<!--
   [2024/02/13 gy.yang]
   StarXrHomeTools.vue
   @note 홈 화면 도구 컴포넌트
-->
<template>
    <v-container fluid>
        <div class="setion-title">{{ $t('tools') }}</div>
        <v-row>
            <v-col
                v-for="(tools, i) in toolsList"
                :key="i"
                cols="12"
                md="6"
            >
                <div class="item py-8">
                    <v-img
                        :src="require(`@/assets/images/icon-${tools.value}.svg`)"
                        cover
                        width="30"
                        height="30"
                        class="mb-3"
                    />
                    <p class="item-title mb-2">
                        {{ $t(tools.title) }}
                    </p>
                    <div
                        class="item-sub-title"
                        v-html="$t(tools.sub)"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrHomeTools',
    data() {
        return{
            toolsList: [
                { title: 'sdk', sub: 'sdkContent', value: 'sdk' },
                { title: 'pay', sub: 'payContent', value: 'pay' }
            ]
        }
    }
}
</script>

<style scoped>
.item {
    min-height: 154px;
}

/* vuetify md(<1280px) */
@media (max-width: 1279px) {
    .item {
        min-height: 230px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .item {
        min-height: 210px;
    }
}
</style>