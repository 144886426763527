/**
 * [2024/09/23 gy.yang]
 * payment.js
 * @note 결제 store
 */

import axios from '../axios';
import config from '../config';


/**
 *  paymentDt: 결제일,
    paymentTtl: 결제 상품,
    paymentSn: 결제 일련 번호,
    stateSn: 결제 상태,
    expiredDt: 이용권 만료일,
    paymentMtd: 결제 방법,
    price: 가격,
    userNm: 주문자,
    telNo: 주문자 전화번호,
    emlAddr: 주문자 이메일
 */

const state =
{
    paymentDt: '2024-08-08',
    paymentTtl: '90일 이용권',
    paymentSn: 0,
    stateSn: 0,
    expiredDt: '2024-11-05',
    paymentMtd: '',
    price: 0,
    userNm: '',
    telNo: '',
    emlAddr: ''
};
  
const actions=
{
    /**
     * @method resetCommunity
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email gy.yang@naviworks.com
     */
    resetCommunity(context) {
        const emptyContent = {};

        context.commit('setCommunity', emptyContent);
    },
    /**
    * @method getPaymentList
    * @param {object} context 컨텍스트 객체
    * @note 결제 내역을 조회하는 함수
    * @email gy.yang@naviworks.com
    */
    getPaymentList() {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/payment`)
            .then(res => {
                resolve(res.data.paymentList);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method getPaymentDetail
    * @param {object} context 컨텍스트 객체
    * @param {Number} paymentSn 결제내역 일련번호
    * @note 결제 내역 상세 조회하는 함수
    * @email gy.yang@naviworks.com
    */
    getPaymentDetail(context, paymentSn) {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/payment/${paymentSn}`)
            .then(res => {
                context.commit('setPayment', res.data.payment[0]);
                resolve(res.data.payment[0]);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method cancelPayment
    * @param {object} context 컨텍스트 객체
    * @param {Number} paymentSn 결제내역 일련번호
    * @note 결제 취소하는 함수
    * @email gy.yang@naviworks.com
    */
    cancelPayment(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .put(`${config.apiServerUrl}/payment`, params)
            .then(res => {
                resolve(res);
            }).catch(err => {
                console.log(err)
                reject(err.response.data);
            });
        });
    }
};

const getters=
{
    
};

const mutations= 
{
    setPayment(state, payment) {
        state.paymentDt = payment.paymentDt,
        state.paymentTtl = payment.paymentTtl,
        state.paymentSn = payment.paymentSn,
        state.stateSn = payment.stateSn,
        state.expiredDt = payment.expiredDt
        state.paymentMtd = payment.paymentMtd,
        state.price = payment.price,
        state.userNm = payment.userNm,
        state.telNo = payment.telNo,
        state.emlAddr = payment.emlAddr
    }
};

export default
{
    state, actions, getters, mutations
};