<!--
   [2024/08/30 sb.hwang]
   StarXrAvatar.vue
   @note STAR-XR 아바타 관리 화면 
-->
<template>
    <v-container
        fluid
        tabindex="0"
        class="my-page-container"
        @keyup.enter="fullScreen"
    >
        <v-snackbar
            v-model="showSnackbar"
            class="fullscreen-snackbar"
            :timeout="3000"
        >
            <div class="font-white font-medium star-xr-h6">
                {{ $t("fullScreenInstruction") }}
            </div>
        </v-snackbar>
        <div class="star-xr-h1 font-semi-bold">
            {{ $t("avatarManagement") }}
        </div>

        <div class="d-flex avatar-wrap">
            <unity-vue :unity="unityContext" class="avatar-webgl" />
            <star-xr-avatar-key-guide />
        </div>
    </v-container>
</template>

<script>
import UnityWebgl from "unity-webgl";
import UnityVue from "unity-webgl/vue";
import StarXrAvatarKeyGuide from "@/components/account/avatar/StarXrAvatarKeyGuide.vue";
import { unityLangCd } from "@/commons/resources";

export default {
    name: "StarXrAvatar",
    components: {
        UnityVue,
        StarXrAvatarKeyGuide,
    },
    computed: {
        token() {
            return this.$store.state.user.token;
        },
        langCd() {
            return this.$store.state.user.translation;
        },
    },
    data() {
        return {
            editMode: false,
            unityContext: null,
            showSnackbar: false,
        };
    },
    methods: {
        /**
         * @method setUnity
         * @note 유니티 webgl을 세팅하는 함수
         * @email sb.hwang@naviworks.com
         */
        setUnity() {
            this.unityContext = new UnityWebgl({
                loaderUrl: "/unity/AvatarEdit/AvatarEdit.loader.js",
                dataUrl: "/unity/AvatarEdit/AvatarEdit.data",
                frameworkUrl: "/unity/AvatarEdit/AvatarEdit.framework.js",
                codeUrl: "/unity/AvatarEdit/AvatarEdit.wasm",
                streamingAssetsUrl: "/unity/AvatarEdit/StreamingAssets",
            });

            this.unityContext.on("mounted", () => {
                console.log("mounted");

                this.resToken();
                this.resLangCd();
            });
        },
        /**
         * @method resToken
         * @note Unity에 토큰 전달하는 함수
         * @email gy.yang@naviworks.com
         */
        async resToken() {
            // Unity 양방향 통신 send('게임 오프젝트 이름', '메서드 이름', '보낼 데이터')
            // 게임 오프젝트 이름: 유니티 씬 내의 게임 오브젝트 이름
            // 메서드 이름: 해당 게임 오브젝트에 붙어있는 스크립트의 메서트 이름
            // 보낼 데이터: 전달하려는 데이터(하나의 인자만 보낼 수 있음 리스트&배열 전달 불가능)
            this.unityContext.send("TokenManager", "GetToken", this.token);
        },
        async resLangCd() {
            // Unity 양방향 통신 send('게임 오프젝트 이름', '메서드 이름', '보낼 데이터')
            // 게임 오프젝트 이름: 유니티 씬 내의 게임 오브젝트 이름
            // 메서드 이름: 해당 게임 오브젝트에 붙어있는 스크립트의 메서트 이름
            // 보낼 데이터: 전달하려는 데이터(하나의 인자만 보낼 수 있음 리스트&배열 전달 불가능)
            this.unityContext.send(
                "LocalizationManager",
                "GetLangCode",
                unityLangCd[this.langCd]
            );
        },
        /**
         * @method fullScreen
         * @note WebGL 전체화면 함수
         * @email gy.yang@naviworks.com
         */
        fullScreen() {
            this.unityContext.setFullscreen(true);
        },
    },
    created() {
        this.setUnity();
        this.showSnackbar = true;
    },
    watch: {
        langCd() {
            this.resLangCd();
        },
    },
    async beforeRouteLeave(to, from, next) {
        this.unityContext.clear();
        await this.unityContext.unload();
        // Cache 삭제 작업
        const cacheStoragePromise = caches
            .open("UnityCache_Unity_Unity")
            .then((cache) => {
                return cache.delete("/unity/AvatarEdit/AvatarEdit.data").then(() => {
                    console.log("deleted cache");
                });
            })
            .catch((err) => console.error("Error deleting cache:", err));

        // UnityCache 삭제 작업
        const unityCachePromise = new Promise((resolve, reject) => {
            const unityCache = indexedDB.open("UnityCache", 4);

            unityCache.onsuccess = (event) => {
                const db = event.target.result;
                if (db.objectStoreNames.contains("RequestMetaDataStore")) {
                    const objectStore = db
                        .transaction(["RequestMetaDataStore"], "readwrite")
                        .objectStore("RequestMetaDataStore");
                    const deleteRequest = objectStore.clear();

                    deleteRequest.onsuccess = () => {
                        console.log("deleted UnityCache");
                        resolve();
                    };

                    deleteRequest.onerror = (e) => reject(e);
                } else {
                    resolve(); // Object store가 없으면 성공 처리
                }
            };

            unityCache.onerror = (event) => reject(event);
        });

        // idbfs 삭제 작업
        const idbfsCachePromise = new Promise((resolve, reject) => {
            const idbfsCache = indexedDB.open("/idbfs", 21);

            idbfsCache.onsuccess = (event) => {
                const db = event.target.result;
                if (db.objectStoreNames.contains("FILE_DATA")) {
                    const objectStore = db
                        .transaction(["FILE_DATA"], "readwrite")
                        .objectStore("FILE_DATA");
                    const deleteRequest = objectStore.clear();

                    deleteRequest.onsuccess = () => {
                        console.log("deleted idbfsCache");
                        resolve();
                    };

                    deleteRequest.onerror = (e) => reject(e);
                } else {
                    resolve(); // Object store가 없으면 성공 처리
                }
            };

            idbfsCache.onerror = (event) => reject(event);
        });

        // 모든 작업이 완료될 때까지 대기
        Promise.all([cacheStoragePromise, unityCachePromise, idbfsCachePromise])
            .then(() => {
                console.log("All cleanup tasks completed");
                this.unityContext = null;
                next(); // 라우터 이동 허용
            })
            .catch((err) => {
                console.error("Error during cleanup tasks:", err);
                next(false); // 라우터 이동 취소
            });
    },
};
</script>

<style scoped>
.my-page-container {
    padding: 0;
    padding-right: 125px;
}

.avatar-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
    gap: 10px;
}

.fullscreen-snackbar::v-deep .v-snack__wrapper {
    top: -680px;
    left: -20px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: none;
}

.avatar-webgl {
    min-width: 960px;
    min-height: 540px;
    flex: 1;
    background-color: #ececec;
    border-radius: 10px;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}

@media (max-width: 1279px) {
    .avatar-wrap {
        flex-direction: column;
    }

    .avatar-webgl {
        width: 100%;
    }
}
</style>
