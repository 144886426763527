<!--
   [2024/08/20 gy.yang]
   StarXrFindPwCheckEmail.vue
   @note 비밀번호 찾기 이메일 인증 컴포넌트
-->
<template>
    <v-container fluid class="mt-5">
        <v-row>
            <v-col cols="12">
                <div
                    class="font-gray7 text-center"
                    v-html="$t('temporaryPasswordIssued')"
                ></div>
                <v-img
                    :src="require('@/assets/images/email-check.svg')"
                    contain
                    height="135"
                    class="mt-4"
                />
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange"
                    @click="checkEmail"
                >
                    {{ $t("changePassword") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { findPwStep } from "@/commons/resources";

export default {
    name: "StarXrFindPwCheckEmail",
    data() {
        return {};
    },
    methods: {
        /**
         * @method checkEmail
         * @note 이메일 인증 확인하는 함수
         * @email gy.yang@naviworks.com
         */
        checkEmail() {
            // 임시
            this.$emit("nextPage", findPwStep.ChagnePw);
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 55px;
}
</style>
