<!--
   [2024/08/20 gy.yang]
   StarXrFindPwChangePw.vue
   @note 비밀번호 찾기 비밀번호 변경 컴포넌트
-->
<template>
    <v-container fluid class="mt-15">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("password") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                    <v-text-field
                        v-model="pswd"
                        :type="showPw ? 'password' : 'text'"
                        class="star-xr-input pt-0"
                        :placeholder="$t('enterPassword')"
                        hide-details
                    >
                        <template v-slot:append>
                            <v-btn icon @click="showPw = !showPw">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-eyes-${showPw}.svg`)
                                    "
                                    contain
                                    height="16"
                                />
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col class="pa-0 star-xr-h7" cols="12">
                    <div class="text-right font-red" v-if="!isMatch">
                        {{ $t("verificationCodeMismatch") }}
                    </div>
                    <div v-else>&nbsp;</div>
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("newPassword") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                    <v-text-field
                        v-model="newPswd"
                        :type="showNewPw ? 'password' : 'text'"
                        class="star-xr-input pt-0"
                        :placeholder="$t('enterPassword')"
                    >
                        <template v-slot:append>
                            <v-btn icon @click="showNewPw = !showNewPw">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-eyes-${showNewPw}.svg`)
                                    "
                                    contain
                                    height="16"
                                />
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("confirmPassword") }}
                </v-col>
                <v-col cols="12" md="9" class="pb-0">
                    <v-text-field
                        v-model="pswdCof"
                        :type="showPwCof ? 'password' : 'text'"
                        class="star-xr-input pt-0"
                        :placeholder="t('reenterPassword')"
                    >
                        <template v-slot:append>
                            <v-btn icon @click="showPwCof = !showPwCof">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-eyes-${showPwCof}.svg`)
                                    "
                                    contain
                                    height="16"
                                />
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12">
                <v-btn
                    block
                    height="40"
                    :disabled="disabled"
                    class="star-xr-btn-orange"
                    @click="updatePswd"
                >
                    {{ $t("changePassword") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { findPwStep } from "@/commons/resources";

export default {
    name: "StarXrFindPwChangePw",
    data() {
        return {
            pswd: "",
            newPswd: "",
            pswdCof: "",
            showPw: true,
            showNewPw: true,
            showPwCof: true,
            disabled: true,
            isMatch: true,
        };
    },
    methods: {
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            this.$emit("nextPage", findPwStep.End);
            this.$store.dispatch("resetUser");
        },
        /**
         * @method updatePswd
         * @note 비밀번호 변경 함수
         * @email sb.hwang@naviworks.com
         */
        updatePswd() {
            const params = {
                emlAddr: this.$store.state.user.emlAddr,
                pswd: this.newPswd,
            };
            this.$store
                .dispatch("updatePswd", params)
                .then(() => {
                    this.clickNext();
                })
                .catch(() => {});
        },
        /**
         * @method checkOldPswd
         * @note 기존 비밀번호 확인 함수
         * @email sb.hwang@naviworks.com
         */
        checkOldPswd(value) {
            if (value) {
                const params = {
                    emlAddr: this.$store.state.user.emlAddr,
                    pswd: this.pswd,
                };
                this.$store
                    .dispatch("checkOldPswd", params)
                    .then((res) => {
                        if (res.checkOldPswd === "다시 입력 부탁드립니다.") {
                            this.isMatch = false;
                        } else {
                            this.isMatch = true;
                        }
                    })
                    .catch(() => {});
            } else {
                this.isMatched = false;
            }
        },
    },
    watch: {
        pswd(value) {
            this.checkOldPswd(value);
        },
        pswdCof() {
            const validate = this.$refs.form.validate();

            if (validate && this.newPswd === this.pswdCof) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 67px;
}
</style>
