export const menus = {
    api: {
        name: "API",
        to: "/api",
    },
    document: {
        name: "Document",
        to: "/document",
    },
    tools: {
        name: "Tools",
        to: "/tools",
    },
    community: {
        name: "Community",
        to: "/community",
    },
    service: {
        name: "Service",
        to: "/service",
    },
};

export const accountAdminMenus = {
    avatar: {
        to: "/account/avatar",
        name: "avatarManagement",
    },
    mypage: {
        to: "/account/mypage",
        name: "myPage",
    },
    payment: {
        to: "/account/payment-history",
        name: "paymentHistory",
    },
    user: {
        to: "/account/admin/user",
        name: "userManagement",
    },
    service: {
        to: "/account/admin/service",
        name: "serviceManagement",
    },
};

export const accountUserMenus = {
    avatar: {
        to: "/account/avatar",
        name: "avatarManagement",
    },
    mypage: {
        to: "/account/mypage",
        name: "myPage",
    },
    payment: {
        to: "/account/payment-history",
        name: "paymentHistory",
    },
};

export const apiMenus = {
    account: {
        name: "계정",
        to: "account",
        subMenus: {
            signup: {
                to: "#signup",
                name: "회원가입",
            },
            login: {
                to: "#login",
                name: "로그인",
            },
            edit: {
                to: "#edit",
                name: "정보수정",
            },
        },
    },
    avatar: {
        name: "아바타",
        to: "avatar",
        subMenus: {
            decoration: {
                to: "#decoration",
                name: "아바타 꾸미기",
            },
            import: {
                to: "#import",
                name: "아바타 불러오기",
            },
        },
    },
    chatting: {
        name: "채팅",
        to: "chatting",
        subMenus: {
            normalChat: {
                to: "#normalChat",
                name: "일반채팅",
            },
            voiceChat: {
                to: "#voiceChat",
                name: "음성채팅",
            },
            faceChat: {
                to: "#faceChat",
                name: "화상채팅",
            },
        },
    },
};

export const documentMenus = {
    account: {
        name: "계정",
        to: "account",
        subMenus: {
            signup: {
                to: "#signup",
                name: "회원가입",
            },
            login: {
                to: "#login",
                name: "로그인",
            },
            edit: {
                to: "#edit",
                name: "정보수정",
            },
        },
    },
    avatar: {
        name: "아바타",
        to: "avatar",
        subMenus: {
            decoration: {
                to: "#decoration",
                name: "아바타 꾸미기",
            },
            import: {
                to: "#import",
                name: "아바타 불러오기",
            },
        },
    },
};
