<!--
   [2024/08/14 sb.hwang]
   StarXrMyPageInfoEdit.vue
   @note STAR-XR 마이페이지 info edit
-->
<template>
    <div class="my-page-info">
        <div class="my-page-info-label star-xr-h6 font-gray7">
            {{ $t(label) }}
        </div>

        <v-text-field
            v-if="type === 'emlAddr'"
            class="star-xr-input readonly"
            v-model="user.emlAddr"
            readonly
            hide-details
        />
        <v-text-field
            v-else-if="type === 'companyNm'"
            class="star-xr-input"
            v-model="companyNm"
            hide-details
        />
        <v-text-field
            v-else-if="type === 'nickNm'"
            class="star-xr-input"
            v-model="nickNm"
            messages=" "
        >
            <template v-slot:message>
                <span
                    :class="{
                        'font-green': message == 'nicknameAvailable',
                        'font-red': message != 'nicknameAvailable',
                    }"
                >
                    {{ $t(message) }}
                </span>
            </template>
            <template v-slot:append-outer>
                <v-btn
                    min-width="73"
                    max-width="177"
                    height="40"
                    class="star-xr-btn-gray-outlined"
                    @click="clickCheckNickNm"
                >
                    {{ $t("checkDuplicate") }}
                </v-btn>
            </template>
        </v-text-field>
        <v-text-field
            v-else-if="type === 'userNm'"
            class="star-xr-input"
            v-model="userNm"
            hide-details
        />
        <v-btn
            v-else-if="type === 'pwd'"
            width="250"
            height="40"
            class="star-xr-btn-gray-outlined mt-3"
            @click="showDialog = true"
        >
            {{ $t("changePassword") }}
        </v-btn>
        <div v-else-if="type === 'date'" class="pt-3">
            <date-picker v-model="brDt" />
        </div>
        <v-row align="center" v-else-if="type === 'phone'" class="pt-4">
            <v-col cols="5" class="pr-1">
                <v-select
                    :items="phoneNumList"
                    height="40"
                    hide-details
                    class="star-xr-select mt-0 pt-0"
                    v-model="phone[0]"
                />
            </v-col>
            -
            <v-col cols="3" class="px-1">
                <v-text-field
                    hide-details
                    class="star-xr-input mt-0 pt-0"
                    placeholder="1234"
                    v-model="phone[1]"
                />
            </v-col>
            -
            <v-col cols="3" class="pl-1 pr-0">
                <v-text-field
                    hide-details
                    class="star-xr-input mt-0 pt-0"
                    placeholder="1234"
                    v-model="phone[2]"
                />
            </v-col>
        </v-row>
        <v-text-field
            v-else
            class="star-xr-input"
            :class="{ readonly: readonly }"
            :value="value"
            :readonly="readonly"
            hide-details
        />
        <star-xr-my-page-change-pw-dialog
            v-model="showDialog"
            @completeEdit="completePwdEdit"
        />
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            :is-disable="false"
        />
    </div>
</template>

<script>
import StarXrMyPageChangePwDialog from "./StarXrMyPageChangePwDialog.vue";
import DatePicker from "../com/DatePicker.vue";
import AlertDialog from "../com/AlertDialog.vue";

export default {
    name: "StarXrMyPageInfoEdit",
    components: {
        StarXrMyPageChangePwDialog,
        DatePicker,
        AlertDialog,
    },
    props: {
        label: String,
        type: String,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        companyNm: {
            get() {
                return this.user.companyNm;
            },
            set(value) {
                this.$store.commit("setUserCompanyNm", value);
            },
        },
        nickNm: {
            get() {
                return this.user.nickNm;
            },
            set(value) {
                this.$store.commit("setUserNickNm", value);
            },
        },
        userNm: {
            get() {
                return this.user.userNm;
            },
            set(value) {
                this.$store.commit("setUserUserNm", value);
            },
        },
        brDt: {
            get() {
                return this.user.brDt;
            },
            set(value) {
                this.$store.commit("setUserBrDt", value);
            },
        },
        telNo: {
            get() {
                return this.user.telNo;
            },
            set(value) {
                this.$store.commit("setUserTelNo", value);
            },
        },
    },
    data() {
        return {
            message: "",
            showPw: true,
            showDialog: false,
            phoneNumList: ["010", "011", "016", "017", "019"],
            phone: [],
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
        };
    },
    methods: {
        /**
         * @method valueToPhone
         * @note value를 phone으로 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        valueToPhone() {
            if (this.telNo.length < 11) {
                this.phone.push(
                    this.telNo.substring(0, 3),
                    this.telNo.substring(3, 6),
                    this.telNo.substring(6)
                );
            } else {
                this.phone.push(
                    this.telNo.substring(0, 3),
                    this.telNo.substring(3, 7),
                    this.telNo.substring(7)
                );
            }
        },
        /**
         * @method completePwdEdit
         * @note 비밀번호 변경 성공 함수
         * @email sb.hwang@naviworks.com
         */
        completePwdEdit() {
            this.openDialog = true;
            this.dialogTitle = "passwordChangeSuccess";
            this.dialogContent = "passwordChangeSuccessMessage";
        },
        /**
         * @method clickCheckNickNm
         * @note 닉네임 중복확인 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickCheckNickNm() {
            this.$store
                .dispatch("nickNmCheck", this.nickNm)
                .then((res) => {
                    switch (res) {
                        case "사용 가능한 닉네임 입니다.":
                            this.message = "nicknameAvailable";
                            break;
                        case "닉네임을 입력 바랍니다":
                            this.message = "enterNickname";
                            break;
                        case "이미 등록된 닉네임 입니다":
                            this.message = "nicknameAlreadyRegistered";
                            break;
                    }
                })
                .catch((err) => {
                    switch (err.data.errorMsg) {
                        case "사용 가능한 닉네임 입니다.":
                            this.message = "nicknameAvailable";
                            break;
                        case "닉네임을 입력 바랍니다":
                            this.message = "enterNickname";
                            break;
                        case "이미 등록된 닉네임 입니다":
                            this.message = "nicknameAlreadyRegistered";
                            break;
                    }
                });
        },
    },
    watch: {
        phone() {
            this.$store.commit("setUserTelNo", this.phone.join(""));
        },
    },
    created() {
        this.valueToPhone();
    },
};
</script>

<style scoped>
.my-page-info {
    min-width: 250px;
    max-width: 400px;
}

.readonly::v-deep .v-input__slot input {
    color: #707070;
}
</style>
