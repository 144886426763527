<!--
   [2024/08/26 sb.hwang]
   StarXrCommunityDetail.vue
   @note STAR-XR Community 게시글 상세 화면
-->
<template>
    <v-container
        fluid
        class="view-container"
    >
        <star-xr-community-detail-content class="star-xr-community-detail-content"/>
        <star-xr-community-detail-answer-input 
            class="star-xr-community-detail-answer-input"
            @addContent="addContent"
        />
        <star-xr-community-detail-answer
            v-for="(answer) in answers"
            :key="answer.sn"
            :answer="answer"
            @editContent="editContent"
            @deleteContent="deleteContent"
        />
    </v-container>
</template>

<script>
import StarXrCommunityDetailContent from '@/components/community/StarXrCommunityDetailContent.vue';
import StarXrCommunityDetailAnswerInput from '@/components/community/StarXrCommunityDetailAnswerInput.vue';
import StarXrCommunityDetailAnswer from '@/components/community/StarXrCommunityDetailAnswer.vue';
import {updateIndentStyle} from '@/commons/functions';

export default {
    name: 'StarXrCommunityDetail',
    components: {
        StarXrCommunityDetailContent,
        StarXrCommunityDetailAnswerInput,
        StarXrCommunityDetailAnswer
    },
    data() {
        return{
            answers: []
        }
    },
    computed: {
        item() {
            return this.$store.state.community;
        },
    },
    mounted() {
        updateIndentStyle();
    },
    updated() {
        updateIndentStyle();
    },
    methods: {
        /**
         * @method selectCommunityDetail
         * @note 게시글 상세 조회 함수
         * @email sb.hwang@naviworks.com
         */
        selectCommunityDetail() {
            this.$store.dispatch('selectCommunity', this.item.communitySn)
            .then((res) => {
                this.$store.commit('setCommunity', res.community[0]);
                this.$store.commit('setCommunitySn', this.$route.params.idx); 

                this.answers = res.community[0].res;
            })
        },
        /**
         * @method addContent
         * @note 답변을 추가하는 함수
         * @email sb.hwang@naviworks.com
         */
        addContent(content) {
            const params = {
                communitySn: this.item.communitySn,
                responseCn: content
            }
            this.$store.dispatch('postCommunityResponse', params)
            .then(() => {
                this.selectCommunityDetail();
            })
        },
        /**
         * @method clickToSave
         * @note 댓글 편집을 저장하는 함수
         * @email sb.hwang@naviworks.com
         */
        editContent(content, sn) {
            const params = {
                responseSn: sn,
                responseCn: content
            }

            this.$store.dispatch('updateCommunityResponse', params)
            .then(()=> {
                this.selectCommunityDetail();
            })
        },
        /**
         * @method deleteContent
         * @note 댓글을 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        deleteContent(sn) {
            const params = {
                data: {
                    responseSn: sn,
                }
            }

            this.$store.dispatch('deleteCommunityResponse', params)
            .then(()=> {
                this.selectCommunityDetail();
            })
        }
    },
    created() {
        this.selectCommunityDetail();
    }
}
</script>

<style scoped src="@/assets/css/community-style.css" />
<style scoped>
.star-xr-community-detail-content {
    margin-bottom: 90px;
}

.star-xr-community-detail-answer-input {
    margin-bottom: 100px;
}


::v-deep .quillWrapper .ql-snow.ql-toolbar {
    padding-top: 4px;
}

::v-deep .quillWrapper .ql-snow.ql-toolbar .ql-formats {
    margin-bottom: 0;
}

::v-deep svg,
::v-deep .ql-picker.ql-color-picker svg {
    width: 18px !important;
    height: 18px !important;
}

::v-deep .ql-editor {
    min-height: 166px;
}
</style>