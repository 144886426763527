<template>
    <v-container fluid class="admin-service-container">
        <div class="star-xr-h1 font-semi-bold mb-12">
            {{ $t("serviceType", { type: type }) }}
        </div>
        <v-row>
            <v-col cols="4" class="service-edit-col">
                <div class="font-gray7 mb-4">{{ $t("thumbnail") }}</div>
                <input
                    ref="imageUploader"
                    name="image"
                    id="item-image-input"
                    type="file"
                    class="item-image-input"
                    accept="image/*"
                    @change="onImageChange"
                />
                <label for="item-image-input">
                    <div
                        v-if="thumblImg === null"
                        class="item-image item-image-null"
                    >
                        <v-img
                            :src="require(`@/assets/images/icon-upload.svg`)"
                            contain
                            width="16"
                            height="16"
                            class="mr-2"
                        />
                        <div class="font-gray star-xr-h7">
                            {{ $t("fileUpload") }}
                        </div>
                    </div>
                    <v-img
                        v-else
                        :src="thumblImg"
                        cover
                        class="item-image"
                        :alt="`썸네일 이미지_${thumblImg}`"
                    />
                </label>
            </v-col>
            <v-col xl="8" cols="12">
                <v-row>
                    <v-col
                        lg="6"
                        cols="12"
                        class="service-edit-col"
                        style="margin-bottom: 30px"
                    >
                        <div class="font-gray7">{{ $t("serviceName") }}</div>
                        <v-text-field
                            v-model="srvcTtl"
                            hide-details
                            class="star-xr-input mt-0 pt-0"
                            :placeholder="$t('enterServiceName')"
                            @input="checkValidate"
                        />
                    </v-col>
                    <v-col
                        lg="6"
                        cols="12"
                        class="service-edit-col"
                        style="margin-bottom: 30px"
                    >
                        <div class="font-gray7">{{ $t("developer") }}</div>
                        <v-text-field
                            v-model="companyNm"
                            hide-details
                            class="star-xr-input mt-0 pt-0"
                            :placeholder="$t('enterDeveloper')"
                            @input="checkValidate"
                        />
                    </v-col>
                    <v-col cols="12" class="service-edit-col px-lg-9">
                        <div class="font-gray7">{{ $t("description") }}</div>
                        <v-textarea
                            v-model="srvcCn"
                            hide-details
                            class="star-xr-input mt-0 pt-0"
                            :placeholder="$t('enterDescription')"
                            auto-grow
                            @input="checkValidate"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col xl="4" cols="12" class="service-edit-col">
                <div class="font-gray7">{{ $t("videoFile") }}</div>
                <file-input
                    v-model="videoFile"
                    accept=".mp4"
                    id="video-file-input"
                    placeholder="video.mp4"
                    @input="checkValidate"
                    @clearFile="clearFile('video')"
                />
                <div class="font-gray5 font-light star-xr-h7 mt-1">
                    {{ $t("uploadLimit") }}
                </div>
            </v-col>
            <v-col xl="4" cols="12" class="service-edit-col">
                <div class="font-gray7">{{ $t("selectCategory") }}</div>
                <v-select
                    v-model="srvcCtgrySn"
                    :items="srvcCategoryItems"
                    height="40"
                    hide-details
                    return-object
                    :placeholder="$t('selectCategory')"
                    class="star-xr-select mt-0 pt-0"
                />
            </v-col>
            <v-col xl="4" cols="12" class="service-edit-col">
                <div class="font-gray7">{{ $t("webglBuildFile") }}</div>
                <file-input
                    v-model="webglFile"
                    id="webGl-file-input-1"
                    accept=".zip"
                    class="mb-2"
                    placeholder="Build.zip"
                    @input="setWebGlFile"
                    @clearFile="clearFile('webgl')"
                />
            </v-col>
            <v-col xl="4" cols="12" class="service-edit-col mb-3">
                <div class="font-gray7">{{ $t("tags") }}</div>
                <v-text-field
                    v-model="tagItem"
                    hide-details
                    class="star-xr-input mt-0 pt-0"
                    :placeholder="$t('enterTags')"
                    @input="checkValidate"
                    @keyup.enter="addTags"
                />
            </v-col>
            <v-col xl="8" cols="12" class="service-edit-col pl-xl-0 pt-xl-1">
                <div class="tags-wrap mt-xl-9">
                    <star-xr-badge
                        v-for="(tagItem, i) in tag"
                        :key="i"
                        :text="tagItem"
                        is-delete
                        @deleteTag="clickToDeleteTag(i)"
                    />
                </div>
            </v-col>
        </v-row>
        <div class="float-right btn-wrap">
            <v-btn
                class="star-xr-btn-orange-outlined mr-3"
                width="130"
                height="40"
                @click="clickToCancel"
            >
                {{ $t("cancel") }}
            </v-btn>
            <v-btn
                class="star-xr-btn-orange"
                width="130"
                height="40"
                :disabled="!isFormValid"
                @click="clickToPost"
            >
                {{ type }}
            </v-btn>
        </div>
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            @agree="agreeToPost"
        />
        <alert-dialog
            v-model="errDialog"
            :title="dialogTitle"
            :content="dialogContent"
            :isDisable="false"
        />
        <star-xr-progress-dialog v-model="uploadFileDialog" />
    </v-container>
</template>

<script>
import FileInput from "@/components/com/FileInput.vue";
import StarXrBadge from "@/components/com/StarXrBadge.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import StarXrProgressDialog from "@/components/com/StarXrProgressDialog.vue";
import { srvcCategory } from "@/commons/resources";
import { storageServerUrl } from "@/config";
// import JSZip from "jszip";

export default {
    name: "StarXrAdminServiceDetail",
    components: {
        FileInput,
        StarXrBadge,
        AlertDialog,
        StarXrProgressDialog,
    },
    data() {
        return {
            ctgryList: ["액션게임", "멀티"],
            srvcCategory,
            thumblFile: "",
            uploadFileAddr: "",
            videoFile: null,
            webglFile: null,
            tagItem: "",
            isFormValid: false,
            openDialog: false,
            errDialog: false,
            dialogTitle: "",
            dialogContent: "",
            webGlFileValid: false,
            uploadFileDialog: false,
        };
    },
    computed: {
        service() {
            return this.$store.state.service;
        },
        srvcSn() {
            return this.$route.params.idx;
        },
        type() {
            return this.srvcSn == "new" ? this.$t("add") : this.$t("edit");
        },
        thumblImg() {
            if (this.thumblFile) {
                return URL.createObjectURL(this.thumblFile);
            } else if (this.service.thumbl) {
                return this.service.thumbl;
            } else {
                return null;
            }
        },
        srvcTtl: {
            get() {
                return this.service.srvcTtl;
            },
            set(value) {
                this.$store.commit("setServiceTitle", value);
            },
        },
        companyNm: {
            get() {
                return this.service.companyNm;
            },
            set(value) {
                this.$store.commit("setServiceCompany", value);
            },
        },
        srvcCn: {
            get() {
                return this.service.srvcCn;
            },
            set(value) {
                this.$store.commit("setServiceDesc", value);
            },
        },
        videoUploadFileSn: {
            get() {
                return this.service.videoUploadFileSn;
            },
            set(value) {
                this.$store.commit("setServiceVideoUploadFileSn", value);
            },
        },
        srvcCtgrySn: {
            get() {
                return String(this.service.srvcCtgrySn);
            },
            set(value) {
                this.$store.commit("setServiceCategory", Number(value.value));
            },
        },
        webglUploadFileSn: {
            get() {
                return this.service.webglUploadFileSn;
            },
            set(value) {
                this.$store.commit("setServiceWebglUploadFileSn", value);
            },
        },
        tag: {
            get() {
                return this.service.tag;
            },
            set(value) {
                this.$store.commit("setServiceTags", value);
            },
        },
        srvcCategoryItems() {
            return Object.keys(srvcCategory).map((key) => ({
                text: this.$t(srvcCategory[key]),
                value: key,
            }));
        },
    },
    methods: {
        /**
         * @method checkValidate
         * @note 게시글 유효성을 체크하는 함수
         * @email sb.hwang@naviworks.com
         */
        checkValidate() {
            this.isFormValid =
                this.srvcTtl !== "" &&
                this.companyNm !== "" &&
                this.srvcCn !== "" &&
                this.srvcCtgrySn !== "" &&
                this.webglFile !== null;
        },
        /**
         * @method setWebGlFile
         * @note WebGL 파일 압축 해제하는 함수
         * @email gy.yang@naviworks.com
         */
        async setWebGlFile() {
            this.uploadFileDialog = true;

            const file = this.webglFile;
            if (file) {
                this.checkValidate();
                this.uploadFileDialog = false;
            }
        },
        /**
         * @method clearFile
         * @param {String} field
         * @note 파일 첨부를 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clearFile(field) {
            switch (field) {
                case "video":
                    this.videoFile = null;
                    break;
                case "webgl":
                    this.webglFile = null;
                    break;
            }
        },
        /**
         * @method clickToDeleteTag
         * @note 태그를 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDeleteTag(index) {
            this.tag.splice(index, 1);
        },
        /**
         * @method clickToDeleteTag
         * @note 태그를 추가하는 함수
         * @email sb.hwang@naviworks.com
         */
        addTags() {
            this.tag.push(this.tagItem);
            this.tagItem = "";
        },
        /**
         * @method onImageChange
         * @note 썸네일 변경 함수
         * @email gy.yang@naviworks.com
         */
        onImageChange(event) {
            this.thumblFile = event.target.files[0];
            this.$refs.imageUploader.value = ""; // input 요소의 value 값 초기화
        },
        /**
         * @method clickToCancel
         * @note 게시글 추가/수정을 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToCancel() {
            this.$router.push({
                name: "starXrAdminService",
            });
        },
        /**
         * @method clickToPost
         * @note 서비스 추가/수정을 위해 alert를 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToPost() {
            this.openDialog = true;
            this.dialogTitle = `서비스 ${this.type}`;
            this.dialogContent = `서비스를 ${this.type}하시겠습니까?`;
        },
        /**
         * @method agreeToPost
         * @note 게시글 추가/수정을 허용하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToPost() {
            if (
                this.srvcTtl &&
                this.companyNm &&
                this.srvcCn &&
                (this.thumblFile || this.thbnilUploadFileSn) &&
                this.srvcCtgrySn &&
                (this.webglFile || this.webglUploadFileSn)
            ) {
                this.fileThbnilUpload();
                this.uploadFileDialog = true;
            } else {
                this.errDialog = true;
                this.dialogTitle = `${
                    this.type == this.$t("add") ? "upload" : "edit"
                } 실패`;
                this.dialogContent =
                    "비디오, 태그를 제외한 값들은 필수로 입력해주세요.";
            }
        },
        /**
         * @method fileThbnilUpload
         * @note 썸네일 업로드 함수
         * @email sb.hwang@naviworks.com
         */
        fileThbnilUpload() {
            if (!this.thumblFile && this.thbnilUploadFileSn) {
                this.fileVideoUpload();
            } else {
                const formData = new FormData();
                formData.append("file", this.thumblFile);

                this.$store
                    .dispatch("fileUpload", formData)
                    .then((res) => {
                        this.thbnilUploadFileSn = res.fileInfo.uploadFileSn;
                        this.fileVideoUpload();
                    })
                    .catch(() => {
                        this.uploadFileDialog = false;
                        this.errDialog = true;
                        this.dialogTitle = "업로드 실패";
                        this.dialogContent =
                            "썸네일 업로드에 실패했습니다. 다시 시도해주세요.";
                    });
            }
        },
        /**
         * @method fileVideoUpload
         * @note 비디오 업로드 함수
         * @email sb.hwang@naviworks.com
         */
        fileVideoUpload() {
            if (this.videoFile) {
                const formData = new FormData();
                formData.append("file", this.videoFile);

                this.$store
                    .dispatch("fileUpload", formData)
                    .then((res) => {
                        this.videoUploadFileSn = res.fileInfo.uploadFileSn;
                        this.fileWebglUpload();
                    })
                    .catch(() => {
                        this.uploadFileDialog = false;
                        this.errDialog = true;
                        this.dialogTitle = "업로드 실패";
                        this.dialogContent =
                            "비디오 업로드에 실패했습니다. 다시 시도해주세요.";
                    });
            } else {
                this.fileWebglUpload();
            }
        },
        /**
         * @method fileWebglUpload
         * @note WebGL 파일 업로드 함수
         * @email gy.yang@naviworks.com
         */
        async fileWebglUpload() {
            const formData = new FormData();
            formData.append("file", this.webglFile);

            await this.$store
                .dispatch("fileUpload", formData)
                .then((res) => {
                    this.webglUploadFileSn = res.fileInfo.uploadFileSn;

                    if (this.type == this.$t("add")) {
                        this.postAdminService();
                    } else {
                        this.updateAdminService();
                    }
                })
                .catch(() => {
                    this.uploadFileDialog = false;
                    this.errDialog = true;
                    this.dialogTitle = "업로드 실패";
                    this.dialogContent =
                        "Buile 파일 업로드에 실패했습니다. 다시 시도해주세요.";
                    return;
                });
        },
        /**
         * @method postAdminService
         * @note 서비스 등록 함수
         * @email sb.hwang@naviworks.com
         */
        postAdminService() {
            this.uploadFileDialog = false;

            const params = {
                srvcTtl: this.srvcTtl,
                companyNm: this.companyNm,
                srvcCn: this.srvcCn,
                thbnilUploadFileSn: this.thbnilUploadFileSn,
                srvcCtgrySn: Number(this.srvcCtgrySn) || 0,
                tag: this.tag,
                videoUploadFileSn: this.videoUploadFileSn || 0,
                webglUploadFileSn: this.webglUploadFileSn,
                chatServiceYn: 0,
            };

            this.$store
                .dispatch("postAdminService", params)
                .then(() => {
                    this.$router.push({
                        name: "starXrAdminService",
                    });
                })
                .catch(() => {
                    this.errDialog = true;
                    this.dialogTitle = "업로드 실패";
                    this.dialogContent =
                        "서비스 업로드에 실패했습니다. 다시 시도해주세요.";
                });
        },
        /**
         * @method updateAdminService
         * @note 서비스 업데이트 함수
         * @email sb.hwang@naviworks.com
         */
        updateAdminService() {
            this.uploadFileDialog = false;

            const params = {
                srvcSn: Number(this.srvcSn),
                srvcTtl: this.srvcTtl,
                companyNm: this.companyNm,
                srvcCn: this.srvcCn,
                thbnilUploadFileSn:
                    this.thbnilUploadFileSn || this.service.thbnilUploadFileSn,
                srvcCtgrySn: Number(this.srvcCtgrySn),
                tag: this.tag,
                videoUploadFileSn:
                    this.videoUploadFileSn ||
                    this.service.videoUploadFileSn ||
                    0,
                webglUploadFileSn:
                    this.webglUploadFileSn || this.service.webglUploadFileSn,
                chatServiceYn: 0,
            };

            this.$store
                .dispatch("updateAdminService", params)
                .then(() => {
                    this.$router.push({
                        name: "starXrAdminServiceDetail",
                        params: { idx: this.srvcSn },
                    });
                })
                .catch(() => {
                    this.errDialog = true;
                    this.dialogTitle = "수정 실패";
                    this.dialogContent =
                        "서비스 수정에 실패했습니다. 다시 시도해주세요.";
                });
        },
        /**
         * @method setThumbnail
         * @note 썸네일 파일 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        setThumbnail() {
            const params = {
                uploadFileSn: this.service.thbnilUploadFileSn,
            };

            this.$store.dispatch("selectFile", params).then((res) => {
                this.service.thumbl = storageServerUrl + res.fileInfo.fileNm;
                this.thbnilUploadFileSn = this.service.thbnilUploadFileSn;
            });
        },
        /**
         * @method setVideo
         * @note 비디오 파일 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        setVideo() {
            const params = {
                uploadFileSn: this.service.videoUploadFileSn,
            };

            this.$store.dispatch("selectFile", params).then((res) => {
                let videoInfo = res.fileInfo;
                this.videoFile = storageServerUrl + res.fileInfo.fileNm;

                this.$store
                    .dispatch("fileDownload", res.fileInfo.fileNm)
                    .then((res) => {
                        this.videoFile = new File(
                            [res],
                            videoInfo.originalFileNm,
                            { type: `${videoInfo.mimeType}` }
                        );
                    });
            });
        },
        /**
         * @method setWebGl
         * @note WebGL 파일 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        async setWebGl() {
            const params = {
                uploadFileSn: this.service.webglUploadFileSn,
            };

            this.$store.dispatch("selectFile", params).then((res) => {
                let webglFileInfo = res.fileInfo;
                this.webglFile = storageServerUrl + res.fileInfo.fileNm;

                this.$store
                    .dispatch("fileDownload", res.fileInfo.fileNm)
                    .then((res) => {
                        this.webglFile = new File(
                            [res],
                            webglFileInfo.originalFileNm,
                            { type: `${webglFileInfo.mimeType}` }
                        );
                    });
            });
        },
    },
    created() {
        if (this.video) {
            this.videoFile = {};
            this.$set(this.videoFile, "name", String(this.video));
        }

        if (this.type == "edit") {
            this.setWebGl();
            this.setThumbnail();
            this.setVideo();
        }
    },
};
</script>

<style scoped>
.admin-service-container {
    padding: 0;
    padding-right: 125px;
}

@media (max-width: 1904px) {
    .admin-service-container {
        padding-right: 0;
    }
}
.item-image-input {
    display: none;
}

.item-image {
    cursor: pointer;
    min-width: 320px;
    min-height: 175px;
    max-height: 280px;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.item-image-null {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90% !important;
    border: 1px solid #eaeaea;
}

.item-image-null::v-deep .v-responsive {
    flex: none;
}

.service-edit-thumbnail {
    width: 320px;
    height: 175px;
    border: 1px solid black;
    border-radius: 5px;
}

.font-gray7 {
    margin-bottom: 10px;
}

.service-edit-col {
    margin-bottom: 50px;
    padding: 0 35px;
}

.tags-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

::v-deep .star-xr-badge {
    padding: 5px 12px;
}

.btn-wrap {
    padding-right: 23px;
    margin-top: 105px;
}
</style>
