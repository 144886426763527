var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"star-xr-data-table",attrs:{"headers":_vm.headers,"items":_vm.historyList,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","no-data-text":_vm.$t('noData'),"no-results-text":_vm.$t('noData')},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.clickItem,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:`item.paymentTtl`,fn:function({ item }){return [_c('span',{staticClass:"font-color-black font-medium star-xr-h5"},[_vm._v(_vm._s(item.paymentTtl))])]}},{key:`item.paymentDt`,fn:function({ item }){return [_c('span',{staticClass:"star-xr-h5 font-gray7",class:{
                    'text-line font-gray5': item.stateSn < 1,
                }},[_vm._v(" "+_vm._s(item.paymentDt)+" ")])]}},{key:`item.expiredDt`,fn:function({ item }){return [_c('span',{staticClass:"star-xr-h5 font-gray7",class:{
                    'text-line font-gray5': item.stateSn < 1,
                }},[_vm._v(" "+_vm._s(item.expiredDt)+" ")])]}},{key:`item.stateSn`,fn:function({ item }){return [_c('span',{staticClass:"star-xr-h5",class:{
                    'font-gray7': item.stateSn,
                    'font-gray5': !item.stateSn,
                }},[_vm._v(" "+_vm._s(item.stateSn > 0 ? "결제 완료" : "취소")+" ")])]}}],null,true)}),_c('v-pagination',{staticClass:"star-xr-pagination mt-16",attrs:{"length":_vm.pageCount,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }