<!--
   [2024/08/12 sb.hwang]
   StarXrAccountNav.vue
   @note STAR-XR 마이페이지 nav
-->
<template>
    <v-navigation-drawer width="325px" color="#F8F8F8">
        <v-list-item
            v-for="(menu, index) in menus"
            :key="index"
            :to="menu.to"
            link
            title="List Item 1"
            class="star-xr-account-nav-list star-xr-h5"
            :class="activeClass(menu.to)"
            active-class="nav-active font-semi-bold font-orange star-xr-h5"
        >
            {{ $t(menu.name) }}
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
import { accountAdminMenus, accountUserMenus } from "@/commons/menus";

export default {
    name: "StarXrAccountNav",
    data() {
        return {
            accountAdminMenus,
            accountUserMenus,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        menus() {
            if (this.user.authrtSn == 2) {
                return accountAdminMenus;
            } else {
                return accountUserMenus;
            }
        },
    },
    methods: {
        /**
         * @method isActive
         * @param {String} to 선택한 메뉴 url
         * @returns {Boolean} active 여부
         * @note 아이템 active 확인 함수
         * @email sb.hwang@naviworks.com
         */
        isActive(to) {
            return to && this.$route.path.includes(to);
        },
        /**
         * @method activeClass
         * @param {String} to 선택한 메뉴 url
         * @returns {String} 클래스 명
         * @note activce 아이템 클래스 명 추가 함수
         * @email sb.hwang@naviworks.com
         */
        activeClass(to) {
            return this.isActive(to)
                ? "nav-active font-orange font-semi-bold"
                : "font-gray8";
        },
    },
};
</script>

<style scoped>
.star-xr-account-nav-list {
    padding: 12.5px 40px;
    min-height: 0;
    color: #202020;
    border-bottom: 1px solid #e9e9e9;
}

.v-navigation-drawer__border {
    width: 0;
}

.star-xr-account-nav-list.nav-active::before {
    opacity: 0;
}
</style>
