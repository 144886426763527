<!--
   [2024/08/20 gy.yang]
   StarXrAdminUserList.vue
   @note 사용자 관리 사용자 목록 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="userList"
            :items-per-page="itemsPerPage"
            :search="search"
            :page.sync="page"
            hide-default-footer
            :no-data-text="$t('noData')"
            :no-results-text="$t('noData')"
            class="star-xr-data-table"
            @click:row="clickItem"
            @page-count="pageCount = $event"
        >
            <template v-slot:[`item.userNm`]="{ item }">
                <span class="font-color-black font-medium star-xr-h5">{{
                    item.userNm
                }}</span>
            </template>
            <template v-slot:[`item.authrtSn`]="{ item }">
                {{ $t(authrtStatus[item.authrtSn]) || "-" }}
            </template>
            <template v-slot:[`item.approvedSn`]="{ item }">
                <span
                    class="font-medium star-xr-h5"
                    :class="{
                        'font-green': item.approvedSn === 1,
                        'font-orange': item.approvedSn === 2,
                        'font-gray5': item.approvedSn === 0,
                    }"
                >
                    {{ $t(approvedStatus[item.approvedSn]) }}
                </span>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            class="star-xr-pagination mt-16"
        />
        <star-xr-admin-user-dialog
            v-model="showDialog"
            @completeEdit="completeEdit"
            @errorEdit="errorEdit"
        />
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            :title-style="titleStyle"
            :agree-style="agreeStyle"
            :is-disable="false"
        />
    </v-container>
</template>

<script>
import StarXrAdminUserDialog from "./StarXrAdminUserDialog.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import { approvedStatus, authrtStatus } from "@/commons/resources";
import { windowScrollTop } from "@/commons/functions";

export default {
    name: "StarXrAdminUserList",
    props: {
        search: {
            type: String,
        },
    },
    components: {
        StarXrAdminUserDialog,
        AlertDialog,
    },
    data() {
        return {
            userList: [],
            page: 1,
            itemsPerPage: 10,
            pageCount: 0,
            showDialog: false,
            approvedStatus,
            authrtStatus,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            titleStyle: "",
            agreeStyle: "",
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t("name"), value: "userNm" },
                { text: this.$t("email"), value: "emlAddr" },
                {
                    text: this.$t("organization"),
                    value: "companyNm",
                    align: "center",
                },
                {
                    text: this.$t("permissions"),
                    value: "authrtSn",
                    align: "center",
                },
                { text: this.$t("name"), value: "approvedSn", align: "center" },
            ];
        },
    },
    methods: {
        /**
         * @method clickItem
         * @param {Objace} item 선택한 아이템
         * @note 아이템 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickItem(item) {
            this.$store.commit("setAdminUser", item);
            this.showDialog = true;
        },
        /**
         * @method selectUserLists
         * @param {Objace} item 선택한 아이템
         * @note 사용자 관리 목록 조회 함수
         * @email sb.hwang@naviworks.com
         */
        selectUserLists() {
            this.$store
                .dispatch("selectUserLists")
                .then((res) => {
                    this.userList = res.userList;
                })
                .catch(() => {});
        },
        /**
         * @method completeEdit
         * @note 수정 성공 시 실행되는 함수
         * @email sb.hwang@naviworks.com
         */
        completeEdit(cd) {
            this.titleStyle = "font-orange";
            this.agreeStyle = "star-xr-btn-orange-outlined";
            this.openDialog = true;
            if (cd === 1) {
                this.dialogTitle = "userApprovalSuccess";
                this.dialogContent = "userApprovalSuccessMessage";
            } else if (cd === 2) {
                this.dialogTitle = "userRejectionSuccess";
                this.dialogContent = "userRejectionSuccessMessage";
            } else if (cd === 3) {
                this.dialogTitle = "userPermissionChangeSuccess";
                this.dialogContent = "userPermissionChangeSuccessMessage";
            }

            this.selectUserLists();
        },
        /**
         * @method errorEdit
         * @note 수정 실패 시 실행되는 함수
         * @email sb.hwang@naviworks.com
         */
        errorEdit(cd) {
            this.titleStyle = "font-red";
            this.agreeStyle = "star-xr-btn-red-outlined";
            this.openDialog = true;
            if (cd === 1) {
                this.dialogTitle = "userApprovalFailure";
                this.dialogContent = "userApprovalFailureMessage";
            } else if (cd === 2) {
                this.dialogTitle = "userRejectionFailure";
                this.dialogContent = "userRejectionFailureMessage";
            } else if (cd === 3) {
                this.dialogTitle = "userPermissionChangeFailure";
                this.dialogContent = "userPermissionChangeFailureMessage";
            }
        },
    },
    created() {
        this.selectUserLists();
    },
    watch: {
        page() {
            windowScrollTop();
        },
    },
};
</script>

<style scoped></style>
