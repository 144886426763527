<!--
   [2024/08/26 gy.yang]
   StarXrPaymentInfo.vue
   @note 결제 정보 컴포넌트
-->
<template>
    <v-container
        fluid
        class="pa-0"
    >
        <div>
            <div class="star-xr-h5 font-gray6 mb-2">주문 정보</div>
            <div class="info-text font-medium mb-5">90일 이용권</div>
            <div class="star-xr-h5 font-gray7">2024.11.05 만료</div>
            <v-divider class="mt-8 mb-12"/>
        </div>
        <div class="star-xr-h5 font-gray6 mb-2">주문자</div>
        <div>
            <div class="info-text font-medium mb-5">김네비</div>
            <div class="star-xr-h5 font-gray7">010-1234-1234 | nv.kim@naviworks.com</div>
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'StarXrPaymentInfo',
    components: {
    },
    data() {
        return{
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
</style>