<!--
   [2024/08/20 gy.yang]
   StarXrFindPw.vue
   @note STAR-XR 비밀번호 찾기 화면
-->
<template>
    <v-container fluid fill-height class="main-wrap">
        <v-row justify="center">
            <v-col class="px-md-0 px-6" cols="12" sm="8" lg="4" xl="3">
                <v-card class="find-pw-box py-12">
                    <v-row justify="center">
                        <v-col cols="10" xl="9">
                            <div class="star-xr-h2 font-semi-bold text-center">
                                {{ $t("passwordType", { text: $t(text) }) }}
                            </div>
                            <template>
                                <component
                                    :is="page"
                                    @nextPage="movePage"
                                    @backPage="movePage"
                                    @clickSignUp="clickSignUp"
                                />
                            </template>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrFindPwInfoEmail from "@/components/login/StarXrFindPwInfoEmail.vue";
import StarXrFindPwCheckEmail from "@/components/login/StarXrFindPwCheckEmail.vue";
import StarXrFindPwChangePw from "@/components/login/StarXrFindPwChangePw.vue";
import StarXrFindPwEnd from "@/components/login/StarXrFindPwEnd.vue";
import { findPwStep } from "@/commons/resources";

export default {
    name: "StarXrFindPw",
    components: {
        StarXrFindPwInfoEmail,
        StarXrFindPwCheckEmail,
        StarXrFindPwChangePw,
        StarXrFindPwEnd,
    },
    data() {
        return {
            page: findPwStep.InfoEmail,
            text: "find",
        };
    },
    methods: {
        /**
         * @method clickSignUp
         * @note 회원가입 완료해주는 함수
         * @email gy.yang@naviworks.com
         */
        clickSignUp() {
            console.log("signUp");
        },
        /**
         * @method movePage
         * @param {String} newPage
         * @note 비밀번호 찾기 화면 변경 함수
         * @email gy.yang@naviworks.com
         */
        movePage(newPage) {
            this.page = newPage;
        },
    },
    watch: {
        page() {
            if (this.page === "StarXrFindPwChangePw") {
                this.text = "change";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-wrap {
    max-height: 100%;
    background-color: #f9f9f9;
}

.find-pw-box {
    min-height: 470px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: none !important;
}

.star-xr-input::v-deep .v-input__slot {
    margin-top: 3px !important;
    margin-bottom: 1px !important;
}
</style>
