<!--
   [2024/06/25 gy.yang]
   StarXrProgressDialog.vue
   @note STAR-XR 프로그래스 다이얼로그 컴포넌트
-->
<template>
    <v-dialog 
        v-model="dialog"
        maxWidth="300"
        persistent 
    >
        <v-card class="text-center">
            <v-card-text>
                <v-progress-circular 
                    indeterminate 
                    color="#FF6D2F"
                    class="my-5"
                />
                <div class="star-xr-h5 font-semi-bold font-gray7">로딩 중</div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'StarXrProgressDialog',
    props: {
        value: {
            type: Boolean,
        }
    },
    data() {
        return {
        };
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
};
</script>

<style scoped>
::v-deep .v-dialog {
    box-shadow: none !important;
}

.v-card {
    background-color: transparent !important;
    box-shadow: none !important;
}

.v-progress-circular,
.v-progress-circular::v-deep .v-progress-circular__overlay {
    color: #707070 !important;
    caret-color: #707070 !important;
}
</style>