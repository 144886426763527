<!--
   [2024/08/09 gy.yang]
   StarXrSignUpTerms.vue
   @note 회원가입 약관 동의 컴포넌트
-->
<template>
    <v-container fluid>
        <div class="font-gray7 text-center mt-2 mb-14">
            {{ $t("agreeToTerms") }}
        </div>
        <v-checkbox
            v-model="selectedTerms"
            value="terms1"
            hide-details
            class="star-xr-checkbox"
        >
            <template v-slot:label>
                <span class="font-light">
                    {{ $t("termsOfServiceAgreement") }}
                </span>
                <v-icon color="#202020" size="16" class="arrow">
                    mdi-chevron-right
                </v-icon>
            </template>
        </v-checkbox>
        <v-checkbox
            v-model="selectedTerms"
            value="terms2"
            hide-details
            class="star-xr-checkbox mt-1"
        >
            <template v-slot:label>
                <span class="font-light">
                    {{ $t("privacyPolicyAgreement") }}
                </span>
                <v-icon color="#202020" size="16" class="arrow">
                    mdi-chevron-right
                </v-icon>
            </template>
        </v-checkbox>
        <v-divider class="my-5" />
        <v-checkbox
            v-model="selectedTermsAll"
            hide-details
            class="star-xr-checkbox mb-16"
        >
            <template v-slot:label>
                <span class="font-light"> {{ $t("agreeToAllTerms") }} </span>
            </template>
        </v-checkbox>
        <v-btn
            block
            height="40"
            :disabled="disabled"
            class="star-xr-btn-orange"
            @click="clickNext"
        >
            {{ $t("next") }}
        </v-btn>
    </v-container>
</template>

<script>
import { signUpStep } from "@/commons/resources";

export default {
    name: "StarXrSignUpTerms",
    computed: {
        selectedTermsAll: {
            get() {
                return this.selectedTerms.length == 2;
            },
            set(value) {
                this.selectedTerms = value ? ["terms1", "terms2"] : [];
            },
        },
    },
    data() {
        return {
            selectedTerms: [],
            disabled: true,
        };
    },
    methods: {
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            this.$emit("nextPage", signUpStep.InfoEmail);
        },
    },
    watch: {
        selectedTerms(value) {
            if (value.length == 2) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
    },
    created() {
        this.$store.dispatch("resetUser");
    },
};
</script>

<style lang="scss" scoped>
.star-xr-checkbox::v-deep .v-input--selection-controls__input {
    margin-right: 0;
    padding-bottom: 5px;
}
</style>
