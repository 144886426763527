<!--
   [2024/08/30 sb.hwang]
   StarXrAvatarKeyGuide.vue
   @note STAR-XR 아바타 편집 키 가이드 컴포넌트 
-->
<template>
    <div class="avatar-key-guide-content">
        <div class="font-semi-bold font-gray8">{{ title }}</div>
        <div class="font-medium font-gray7">{{ desc }}</div>
    </div>
</template>

<script>

export default {
    name: 'StarXrAvatarKeyGuideContent',
    props: {
        title: {
            type: String
        },
        desc: {
            type: String
        },
    },
    components: {
    },
    data() {
        return{
        }
    },
    methods: {
    },
    created() {
    },
}
</script>

<style scoped>
</style>