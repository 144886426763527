<!--
   [2024/08/21 gy.yang]
   StarXrAdminUserDialog.vue
   @note 사용자 관리 사용자 편집 다이얼로그 컴포넌트
-->
<template>
    <v-dialog v-model="dialog" maxWidth="550" persistent class="">
        <v-card class="py-20 px-30">
            <v-card-title>
                <v-row>
                    <v-col cols="auto" class="star-xr-h4 font-semi-bold">
                        {{
                            user.state == "승인대기"
                                ? "사용자 승인"
                                : "사용자 관리"
                        }}
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-icon
                            size="22"
                            color="#1E1E1E"
                            @click="dialog = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="mt-4">
                <div class="info-box py-5 px-8">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="3" class="font-gray6 px-0 pb-0">
                                    {{ $t("email") }}
                                </v-col>
                                <v-col cols="9" class="pb-0">
                                    {{ user.emlAddr }}
                                </v-col>
                                <v-col
                                    cols="3"
                                    class="font-gray6 pt-2 px-0 pb-0"
                                >
                                    {{ $t("dateOfBirth") }}
                                </v-col>
                                <v-col cols="9" class="pt-2 pb-0">
                                    {{ user.brDt }}
                                </v-col>
                                <v-col
                                    cols="3"
                                    class="font-gray6 pt-2 px-0 pb-0"
                                >
                                    {{ $t("phoneNumber") }}
                                </v-col>
                                <v-col cols="9" class="pt-2 pb-0">
                                    {{
                                        user.telNo
                                            ? processTelNo(user.telNo)
                                            : ""
                                    }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col
                                    cols="3"
                                    class="font-gray6 pt-md-3 pt-2 px-0 pb-0"
                                >
                                    {{ $t("name") }}
                                </v-col>
                                <v-col cols="9" class="pt-md-3 pt-2 pb-0">
                                    {{ user.userNm }}
                                </v-col>
                                <v-col
                                    cols="3"
                                    class="font-gray6 pt-2 px-0 pb-0"
                                >
                                    {{ $t("email") }}
                                </v-col>
                                <v-col cols="9" class="pt-2 pb-0">
                                    {{ user.companyNm }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <v-row align="center" class="mt-3 mb-9 px-5">
                    <v-col cols="auto" class="font-gray6">
                        {{ $t("permissions") }}
                    </v-col>
                    <v-col cols="auto">
                        <v-radio-group
                            v-model="authrtSn"
                            row
                            mandatory
                            hide-details
                            class="star-xr-radio-group ma-0 mb-2"
                        >
                            <v-radio
                                :label="$t('user')"
                                :value="0"
                                class="mr-5"
                            />
                            <v-radio
                                :label="$t('systemAdministrator')"
                                :value="1"
                                class="mr-5"
                            />
                            <v-radio
                                :label="$t('superAdministrator')"
                                :value="2"
                            />
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" sm="6" lg="3" class="pr-sm-1">
                        <v-btn
                            block
                            height="40"
                            class="star-xr-btn-gray-outlined"
                            @click="clickToDisApprove"
                        >
                            {{
                                user.approvedSn === 1
                                    ? $t("cancel")
                                    : $t("reject")
                            }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3" class="pt-sm-3 pt-0 pl-sm-1">
                        <v-btn
                            v-if="user.approvedSn === 1"
                            block
                            height="40"
                            class="star-xr-btn-orange"
                            @click="clickToSave"
                        >
                            {{ $t("save") }}
                        </v-btn>
                        <v-btn
                            v-else
                            block
                            height="40"
                            class="star-xr-btn-orange-outlined"
                            @click="clickToApprove"
                        >
                            {{ $t("approve") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "StarXrAdminUserDialog",
    props: {
        value: {
            type: Boolean,
        },
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        user() {
            return this.$store.state.adminUser;
        },
        authrtSn: {
            get() {
                return this.user.authrtSn;
            },
            set(value) {
                this.$store.commit("setAdminUserAuthrtSn", value);
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method processTelNo
         * @note 전화번호 가공하는 함수
         * @email sb.hwang@naviworks.com
         */
        processTelNo(telNo) {
            if (telNo.length < 11) {
                return (
                    telNo.substring(0, 3) +
                    "-" +
                    telNo.substring(3, 6) +
                    "-" +
                    telNo.substring(6)
                );
            } else {
                return (
                    telNo.substring(0, 3) +
                    "-" +
                    telNo.substring(3, 7) +
                    "-" +
                    telNo.substring(7)
                );
            }
        },
        /**
         * @method clickToApprove
         * @note 사용자 승인 함수
         * @email sb.hwang@naviworks.com
         */
        clickToApprove() {
            const params = {
                accountSn: this.user.accountSn,
                approvedSn: 1,
            };

            this.$store
                .dispatch("updateApproval", params)
                .then(() => {
                    this.$emit("completeEdit", 1);
                    this.dialog = false;
                })
                .catch(() => {
                    this.$emit("errorEdit", 1);
                });
        },
        /**
         * @method clickToDisApprove
         * @note 거절/취소 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDisApprove() {
            if (this.user.approvedSn === 0) {
                const params = {
                    accountSn: this.user.accountSn,
                    approvedSn: 2,
                };

                this.$store
                    .dispatch("updateApproval", params)
                    .then(() => {
                        this.$emit("completeEdit", 2);
                        this.dialog = false;
                    })
                    .catch(() => {
                        this.$emit("errorEdit", 2);
                    });
            }
        },
        /**
         * @method clickToSave
         * @note 사용자 권한 수정 함수
         * @email sb.hwang@naviworks.com
         */
        clickToSave() {
            const params = {
                accountSn: this.user.accountSn,
                authrtSn: this.authrtSn,
            };

            this.$store
                .dispatch("updateAuthrt", params)
                .then(() => {
                    this.$emit("completeEdit", 3);
                    this.dialog = false;
                })
                .catch(() => {
                    this.$emit("errorEdit", 3);
                });
        },
    },
};
</script>

<style scoped>
.info-box {
    min-height: 120px;
    border-radius: 5px;
    background-color: #fafafa;
}
</style>
