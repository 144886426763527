import { render, staticRenderFns } from "./StarXrSignUpInfoName.vue?vue&type=template&id=5c481b84&scoped=true&"
import script from "./StarXrSignUpInfoName.vue?vue&type=script&lang=js&"
export * from "./StarXrSignUpInfoName.vue?vue&type=script&lang=js&"
import style0 from "./StarXrSignUpInfoName.vue?vue&type=style&index=0&id=5c481b84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c481b84",
  null
  
)

export default component.exports