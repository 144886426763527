<!--
   [2024/08/26 gy.yang]
   starXrPaymentHistory.vue
   @note STAR-XR 결제 내역 화면
-->
<template>
    <v-container fluid class="admin-payment-history-container">
        <div class="star-xr-h1 font-semi-bold mb-12">
            {{ $t("paymentHistory") }}
        </div>
        <v-row>
            <v-col cols="10" sm="6" lg="4" xl="3">
                <search-input @search="search" />
            </v-col>
            <v-col cols="12" class="pt-0">
                <star-xr-payment-history-list :search="keyword" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrPaymentHistoryList from "@/components/account/StarXrPaymentHistoryList.vue";
import SearchInput from "@/components/com/SearchInput.vue";

export default {
    name: "starXrPaymentHistory",
    components: {
        StarXrPaymentHistoryList,
        SearchInput,
    },
    data() {
        return {
            keyword: "",
        };
    },
    methods: {
        /**
         * @method search
         * @param {String} search 검색어
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search(search) {
            this.keyword = search;
        },
    },
};
</script>

<style scoped>
.admin-payment-history-container {
    padding: 0;
    padding-right: 195px;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}
</style>
