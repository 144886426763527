<!--
   [2024/02/13 gy.yang]
   StarXrHome.vue
   @note STAR-XR 홈 화면
-->
<template>
    <v-container
        fluid
        class="pa-0"
    >
        <video
            :src="require('@/assets/images/main-banner.mp4')"
            width="100%"
            autoplay
            loop
        />
        <v-row
            justify="center"
            align="center"
            class="home-setion my-15 py-15"
        >
            <v-col
                cols="11"
                md="9"
                class="px-0"
            >
                <star-xr-home-feature class="mb-15 pb-15"/>
                <star-xr-home-tools class="my-15 py-10"/>
            </v-col>
            <v-col
                cols="12"
                class="px-0"
            >
                <star-xr-home-value class="my-15 py-10"/>
            </v-col>
            <v-col
                cols="11"
                md="9"
                class="px-0"
            >
                <star-xr-home-use-cases class="my-15 py-15"/>
                <star-xr-home-news class="my-15 pt-15"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrHomeFeature from '@/components/home/StarXrHomeFeature.vue';
import StarXrHomeTools from '@/components/home/StarXrHomeTools.vue';
import StarXrHomeValue from '@/components/home/StarXrHomeValue.vue';
import StarXrHomeUseCases from '@/components/home/StarXrHomeUseCases.vue';
import StarXrHomeNews from '@/components/home/StarXrHomeNews.vue';

export default {
    name: 'StarXrHome',
    components: {
        StarXrHomeFeature,
        StarXrHomeTools,
        StarXrHomeValue,
        StarXrHomeUseCases,
        StarXrHomeNews
    },
    data() {
        return{
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.main-banner {
    color: #FFFFFF;
    padding: 85px 0;
}

.main-banner .title {
    font-size: 85px !important;
    font-weight: 600;
    line-height: 70px;
}

.main-banner .sub-title {
    color: #CECECE;
    font-size: 35px;
    font-weight: 500;
}

.home-setion {
    ::v-deep .item {
        padding: 25px 30px;
        border-radius: 5px;
        background-color: #F8F8F8;
    }

    ::v-deep .setion-title {
        margin-bottom: 30px;
        color: #434343;
        font-size: 30px;
        font-weight: 600;
    }

    ::v-deep .item-title {
        color: #4A4A4A;
        font-size: 22px;
        font-weight: 600;
    }

    ::v-deep .item-sub-title {
        color: #484848;
        font-size: 16px;
        line-height: 23px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .main-banner .title {
        font-size: 75px !important;
        font-weight: 600;
        line-height: 90px;
    }

    .main-banner .sub-title {
        color: #CECECE;
        font-size: 30px;
        font-weight: 500;
    }
}

</style>