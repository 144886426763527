/**
 * [2024/09/12 sb.hwang]
 * file.js
 * @note 파일 store
 */

import axios from '../axios';
import config from '../config';

const state =
{
};
  
const actions=
{
    /**
    * @method fileUpload
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 파일을 업로드하는 함수
    * @email sb.hwang@naviworks.com
    */
    fileUpload(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .post(`${config.apiServerUrl}/storage`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method fileDownload
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 파일을 다운로드하는 함수
    * @email sb.hwang@naviworks.com
    */
    fileDownload(_, path) {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.storageServerUrl}${path}`)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method selectFile
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 파일을 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    selectFile(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/storage/uploadFileInfo`, {params})
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
};

const getters=
{
    
};

const mutations= 
{
};

export default
{
    state, actions, getters, mutations
};