import Vue from 'vue';
import VueRouter from 'vue-router';
import StarXrAvatar from '../views/account/StarXrAvatar.vue';
import StarXrMyPage from '../views/account/StarXrMyPage.vue';
import StarXrAdminUser from '@/views/account/starXrAdminUser.vue';
import StarXrAdminService from '@/views/account/StarXrAdminService.vue';
import StarXrAdminServiceDetail from '@/views/account/StarXrAdminServiceDetail.vue';
import StarXrAdminServiceEdit from '@/views/account/StarXrAdminServiceEdit.vue';
import StarXrPaymentHistory from '@/views/account/starXrPaymentHistory.vue';
import StarXrPaymentHistoryDetail from '@/views/account/starXrPaymentHistoryDetail.vue';
 
Vue.use(VueRouter);

const accountRoutes = [
    {
        // 임시 이동
        path: '/',
        redirect: {
            name: 'starXrMyPage'
        }
    },
    {
        path: 'avatar',
        name: 'starXrAvatar',
        component: StarXrAvatar
    },
    {
        path: 'mypage',
        name: 'starXrMyPage',
        component: StarXrMyPage
    },
    {
        path: 'payment-history',
        name: 'starXrPaymentHistory',
        component: StarXrPaymentHistory
    },
    {
        path: 'payment-history/detail/:idx',
        name: 'starXrPaymentHistoryDetail',
        component: StarXrPaymentHistoryDetail
    },
    {
        path: 'admin/user',
        name: 'starXrAdminUser',
        component: StarXrAdminUser
    },
    {
        path: 'admin/service',
        name: 'starXrAdminService',
        component: StarXrAdminService
    },
    {
        path: 'admin/service/detail/:idx',
        name: 'starXrAdminServiceDetail',
        component: StarXrAdminServiceDetail
    },
    {
        path: 'admin/service/edit/:idx',
        name: 'starXrAdminServiceEdit',
        component: StarXrAdminServiceEdit
    },
];

export default accountRoutes;