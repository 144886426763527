<!--
   [2024/08/21 gy.yang]
   starXrTools.vue
   @note STAR-XR Tools 화면
-->
<template>
    <v-container fluid class="view-container">
        <div class="content-box">
            <div class="star-xr-h3 font-semi-bold mb-5">SDK</div>
            <div class="sdk-info-box mb-4">{{ $t("sdkDescription") }}</div>
            <v-btn
                :block="$vuetify.breakpoint.xs"
                min-width="130"
                height="40"
                class="star-xr-btn-orange"
                @click="routerPayment"
            >
                {{ $t("download") }}
            </v-btn>
        </div>
        <div class="content-box">
            <div class="star-xr-h3 font-semi-bold mb-5">
                {{ $t("systemRequirements") }}
            </div>
            <star-xr-tools-download />
        </div>
        <div class="content-box">
            <div class="star-xr-h3 font-semi-bold mb-5">{{ $t("pay") }}</div>
            <star-xr-tools-payment-plan @routerPayment="routerPayment" />
        </div>
    </v-container>
</template>

<script>
import StarXrToolsDownload from "@/components/tools/StarXrToolsDownload.vue";
import StarXrToolsPaymentPlan from "@/components/tools/StarXrToolsPaymentPlan.vue";

export default {
    name: "starXrTools",
    components: {
        StarXrToolsDownload,
        StarXrToolsPaymentPlan,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method routerPayment
         * @note 결제 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerPayment() {
            // 임시 결제
            if (this.user.approvedSn == 1) {
                const url = "https://starxr.co.kr/starxr/upload/ad7a543b-53bb-44c6-b284-9e4bcd3c65a5.unitypackage"
                const name = "STAR-XR SDK.unitypackage";
                const downloadLink = document.createElement("a");
                downloadLink.href = url;
                // 다운로드 이름 설정
                downloadLink.setAttribute("download", name);

                // 링크 클릭으로 다운로드 실행
                document.body.appendChild(downloadLink); // 일부 브라우저에서 링크를 DOM에 추가해야 작동할 수 있음
                downloadLink.click();
                document.body.removeChild(downloadLink); // 사용 후 DOM에서 제거
            } else {
                this.$router
                    .push({
                        name: "starXrLogin",
                    })
                    .catch(() => {});
            }

            // 추후 결제 기능 구현 후 주석 해제
            // this.$router.push({
            //     name: 'starXrPayment'
            // }).catch(()=>{});
        },
    },
};
</script>

<style scoped>
.content-box {
    margin-bottom: 100px;
}

.sdk-info-box {
    padding: 14px;
    background-color: #fafafa;
}
</style>
