/**
 * [2024/08/29 sb.hwang]
 * api.js
 * @note 커뮤니티 store
 */

import axios from "../axios";
import config from "../config";

const state = {
    apiText: "",
    // apiText: '<iframe width="560" height="315" src="https://www.youtube.com/embed/GkFIErtM3D4?si=R-pJIeLDP6otU1k-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> \n\n# 계정\n## 회원가입\n## 로그인\n## 정보수정\n# 아바타\n## 아바타 꾸미기\n',
    // documentText: '# 문서\n## docs',
    documentText: "",
};

const actions = {
    /**
     * @method selectApi
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note api내용을 조회하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectApi(context, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/api`, { params: params })
                .then((res) => {
                    context.commit("setApiText", res.data.apiCn);
                    resolve(res.data.apiCn);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updateApi
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note api내용을 수정하는 함수
     * @email sb.hwang@naviworks.com
     */
    updateApi(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/api`, params)
                .then((res) => {
                    resolve(res.data.apiCn);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method selectDocument
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note document내용을 조회하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectDocument(context, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/document`, { params: params })
                .then((res) => {
                    context.commit("setDocumentText", res.data.docCn);
                    resolve(res.data.docCn);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updateDocument
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note document내용을 수정하는 함수
     * @email sb.hwang@naviworks.com
     */
    updateDocument(context, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/document`, params)
                .then((res) => {
                    resolve(res.data.docCn);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
};

const getters = {};

const mutations = {
    setApiText(state, apiText) {
        state.apiText = apiText || "";
    },
    setDocumentText(state, documentText) {
        state.documentText = documentText;
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
};
