<!--
   [2024/02/13 gy.yang]
   App.vue
   @note STAR-XR 웹 프로젝트 App.vue
-->
<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
};
</script>

<style src="./assets/css/common-style.css" />