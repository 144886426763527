<!--
   [2024/08/26 sb.hwang]
   StarXrCommunityEdit.vue
   @note STAR-XR Community 게시글 등록/수정 화면
-->
<template>
    <v-container fluid class="view-container">
        <div class="star-xr-h1 font-semi-bold mb-9">
            {{ $t("postType", { type: type }) }}
        </div>

        <v-row>
            <v-col cols="12">
                <div class="star-xr-h4 star-xr-input-title">
                    {{ $t("title") }}
                </div>
                <v-text-field
                    v-model="postTtl"
                    hide-details
                    class="star-xr-input mt-0 pt-0"
                    :placeholder="$t('enterTitle')"
                    @input="checkValidate"
                />
            </v-col>
            <v-col cols="12">
                <div class="star-xr-h4 star-xr-input-title">
                    {{ $t("content") }}
                </div>
                <vue-editor
                    v-model="postCn"
                    :editor-toolbar="editorToolbar"
                    class="star-xr-text-editor"
                    :placeholder="$t('writeContent')"
                    @input="checkValidate"
                    @ready="setQuillCustomEvent"
                />
            </v-col>
            <v-col cols="12" lg="4">
                <div class="star-xr-h4 star-xr-input-title">
                    {{ $t("attachFile") }}
                </div>
                <file-input
                    v-model="file"
                    accept=".jpeg, .png, .jpg, .svg"
                    @clearFile="clearFile"
                />
            </v-col>
        </v-row>

        <div class="btn-wrap">
            <v-btn
                class="star-xr-btn-orange-outlined mr-4"
                width="130"
                height="40"
                @click="clickToCancel"
            >
                {{ $t("cancel") }}
            </v-btn>
            <v-btn
                class="star-xr-btn-orange"
                :disabled="!isFormValid"
                width="130"
                height="40"
                @click="clickToPost"
            >
                {{ type }}
            </v-btn>
        </div>

        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            @agree="agreeToPost"
        />
    </v-container>
</template>

<script>
import FileInput from "@/components/com/FileInput.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import { VueEditor, Quill } from "vue2-editor";
import Vue from "vue";

const fontList = [
    "돋움",
    "굴림",
    "맑은고딕",
    "바탕",
    "궁서",
    "Arial",
    "Courier",
    "Garamond",
    "Tahoma",
    "Verdana",
];
const fonts = Quill.import("formats/font");
fonts.whitelist = fontList;
Quill.register(fonts, true);

const sizeList = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "22px",
    "24px",
    "26px",
    "28px",
    "36px",
];
const sizes = Quill.import("attributors/style/size");
sizes.whitelist = sizeList;
Quill.register(sizes, true);

const block = Quill.import("blots/block");
block.tagName = "DIV";
Quill.register(block, true);

let Inline = Quill.import("blots/inline");
class PreviewBlock extends Inline {
    static create() {
        let node = super.create();
        node.setAttribute("class", "preview-block");
        return node;
    }
}
PreviewBlock.blotName = "previewblock";
PreviewBlock.tagName = "div";
Quill.register(PreviewBlock);

function urlify(text) {
    let urlRegex =
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/g;
    let urlArr = text.match(urlRegex);
    return Array.isArray(urlArr) ? urlArr[0] : null;
}

export default {
    name: "StarXrCommunityEdit",
    components: {
        FileInput,
        AlertDialog,
        VueEditor,
    },
    computed: {
        community() {
            return this.$store.state.community;
        },
        type() {
            return this.community.communitySn
                ? this.$t("edit")
                : this.$t("add");
        },
        postTtl: {
            get() {
                return this.community.postTtl;
            },
            set(value) {
                this.$store.commit("setCommunityPostTtl", value);
            },
        },
        postCn: {
            get() {
                return this.community.postCn;
            },
            set(value) {
                this.$store.commit("setCommunityPostCn", value);
            },
        },
    },
    data() {
        return {
            file: null,
            disabled: false,
            isFormValid: false,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            editorToolbar: [
                [{ font: [false, ...fonts.whitelist] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: [false, ...sizes.whitelist] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["clean"],
            ],
        };
    },
    methods: {
        /**
         * @method getWrapNode
         * @param {node} node
         * @note 에디터 내에서 파라미터로 전달한 노드의 최상위 wrap 노드를 반환하는 함수
         * @email se.yoo@naviworks.com
         */
        getWrapNode(node) {
            let result = node;
            let flag = true;

            while (flag) {
                let parentNode = result.parentNode;

                if (parentNode.classList.contains("ql-editor")) {
                    flag = false;
                } else {
                    result = parentNode;
                }
            }

            return result;
        },
        /**
         * @method appendPreview
         * @param {obejct} previewHtml 수정 정보
         * @note 미리보기 컴포넌트 HTML 코드를 추가하는 함수
         * @email se.yoo@naviworks.com
         */
        appendPreview(previewHtml) {
            // 현재 위치 가져오기
            const selection = window.getSelection();

            // 현재 커서 위치 wrap 이후로 selection range 변경
            const range = document.createRange();
            range.setStartAfter(this.getWrapNode(selection.anchorNode));
            selection.removeAllRanges();
            selection.addRange(range);

            // contenteditable 때문에 커서 이동 못하는 현상 해결을 위한 줄바꿈
            selection.getRangeAt(0).insertNode(document.createElement("p"));

            // 미리보기 노드 추가 후 노드 전체 선택 해제
            let preview = document.createElement("div");
            preview.innerHTML = previewHtml;
            selection.getRangeAt(0).insertNode(preview);
            selection.collapseToEnd();
        },
        /**
         * @method urlToComponent
         * @param {string} externalUrl URL 정보
         * @note URL을 컴포넌트로 생성하는 함수
         * @email se.yoo@naviworks.com
         */
        urlToComponent(externalUrl) {
            if (!externalUrl) return null;

            let previewComponent = new Vue({
                el: document.createElement("div"),
            });

            previewComponent.url = externalUrl;
            previewComponent.$on("onLoaded", this.appendPreview);
        },
        /**
         * @method pasteContent
         * @param {event} e 이벤트 정보
         * @note 붙여 넣은 텍스트에 링크가 있는지 확인해 처리하는 함수
         * @email se.yoo@naviworks.com
         */
        pasteContent(e) {
            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData("Text");

            let url = urlify(pastedData);
            if (url) {
                e.preventDefault();

                this.urlToComponent(url);

                const selection = window.getSelection();

                if (!selection.rangeCount) return false;

                selection.deleteFromDocument();

                let link = document.createElement("a");
                link.href = url;
                link.innerHTML = url;

                selection.getRangeAt(0).insertNode(link);
            }
        },
        /**
         * @method setQuillCustomEvent
         * @param {quill} quill quill 객체
         * @note 모듈에 사용자 정의 함수(붙여넣기)를 적용하는 함수
         * @email se.yoo@naviworks.com
         */
        setQuillCustomEvent(quill) {
            this.quill = quill;
            this.quill.root.addEventListener("paste", this.pasteContent);
        },
        /**
         * @method checkValidate
         * @note 게시글 유효성을 체크하는 함수
         * @email sb.hwang@naviworks.com
         */
        checkValidate() {
            this.isFormValid = this.postTtl !== "" && this.postCn !== "";
        },
        /**
         * @method clearFile
         * @note 파일 첨부를 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clearFile() {
            this.file = null;
        },
        /**
         * @method clickToCancel
         * @note 게시글 추가/수정을 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToCancel() {
            this.community.communitySn
                ? this.$router.push({
                      name: "starXrCommunityDetail",
                      params: { idx: this.community.communitySn },
                  })
                : this.$router.push({
                      name: "starXrCommunity",
                  });
        },
        /**
         * @method clickToPost
         * @note 게시글 추가/수정을 위해 alert를 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToPost() {
            this.openDialog = true;
            this.dialogTitle = "게시글 " + this.type;
            this.dialogContent = `게시글을 ${this.type}하시겠습니까?`;
        },
        /**
         * @method agreeToPost
         * @note 게시글 추가/수정을 허용하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToPost() {
            if (this.file !== null) {
                this.fileUpload(this.file);
            } else {
                this.updateCommunity(0);
            }
        },
        /**
         * @method updateCommunity
         * @note 게시글 추가/수정하는 함수
         * @email sb.hwang@naviworks.com
         */
        updateCommunity(fileSn) {
            const actionNm = this.community.communitySn
                ? "editCommunity"
                : "postCommunity";

            const params = {
                communitySn: this.community.communitySn,
                postTtl: this.postTtl,
                postCn: this.postCn,
            };

            if (fileSn !== 0) {
                params.uploadFileSn = fileSn;
            }

            this.$store.dispatch(actionNm, params).then(() => {
                this.community.communitySn
                    ? this.$router.push({
                          name: "starXrCommunityDetail",
                          params: { idx: this.community.communitySn },
                      })
                    : this.$router.push({
                          name: "starXrCommunity",
                      });
            });
        },
        /**
         * @method fileUpload
         * @note 파일 업로드 함수
         * @email sb.hwang@naviworks.com
         */
        fileUpload() {
            const formData = new FormData();
            formData.append("file", this.file);

            this.$store
                .dispatch("fileUpload", formData)
                .then((res) => {
                    this.updateCommunity(res.fileInfo.uploadFileSn);
                })
                .catch(() => {});
        },
        /**
         * @method selectFile
         * @note 게시글 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        selectFile() {
            if (
                this.community.communitySn &&
                this.community.uploadFileSn !== 0
            ) {
                const params = {
                    uploadFileSn: this.community.uploadFileSn,
                };

                this.$store
                    .dispatch("selectFile", params)
                    .then((res) => {
                        this.file = {};
                        this.$set(
                            this.file,
                            "name",
                            res.fileInfo.originalFileNm
                        );
                    })
                    .catch(() => {});
            }
        },
    },
    watch: {},
    created() {
        this.isFormValid = this.community.communitySn ? true : false;

        this.selectFile();
    },
    beforeDestroy() {
        this.quill.root.removeEventListener("paste", this.pasteContent);
        this.quill = null;
        delete this.quill;
    },
};
</script>

<style scoped src="@/assets/css/community-style.css" />
<style scoped>
.star-xr-input-title {
    margin-bottom: 10px;
}

.btn-wrap {
    margin: 140px 0px;
    float: right;
}

@media (max-width: 1279px) {
    .btn-wrap {
        margin-bottom: 100px;
    }
}
</style>
