<!--
   [2024/08/27 sb.hwang]
   StarXrCommunityDetailContent.vue
   @note STAR-XR Community 게시글 상세 내용 컴포넌트
-->
<template>
    <div>
        <div class="star-xr-community-detail-title mb-2">
            <div class="star-xr-h1 font-semi-bold"></div>
            <div v-if="user.accountSn == item.accountSn || user.authrtSn == 2">
                <img-btn
                    icon="icon-edit"
                    class="mr-3"
                    @clickBtn="clickToEdit"
                />
                <img-btn icon="icon-delete-fill" @clickBtn="clickToDelete" />
            </div>
        </div>
        <div class="star-xr-community-detail-sub-title star-xr-h5 mb-2">
            {{ item.userNm + " (" + item.companyNm + ") | " + item.crtDt }}
        </div>
        <div class="star-xr-community-detail-sub-title star-xr-h5 mb-8">
            {{ $t("attachedFile") }} |
            <span
                v-if="fileNm !== ''"
                class="star-xr-community-detail-sub-title-file star-xr-h5 cursor-pointer"
                @click="clickToDownload"
            >
                {{ fileNm }}
            </span>
            <span v-else class="star-xr-community-detail-sub-title star-xr-h5">
                {{ $t("noFile") }}
            </span>
        </div>
        <div
            class="star-xr-community-detail-content star-xr-h5"
            v-html="item.postCn"
        ></div>

        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="agreeToDelete"
        />
    </div>
</template>

<script>
import AlertDialog from "@/components/com/AlertDialog.vue";
import ImgBtn from "@/components/com/ImgBtn.vue";
import { apiServerUrl } from "@/config";

export default {
    name: "StarXrCommunityDetailContent",
    components: {
        AlertDialog,
        ImgBtn,
    },
    data() {
        return {
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            fileNm: "",
            apiServerUrl,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        item() {
            return this.$store.state.community;
        },
    },
    methods: {
        /**
         * @method selectFile
         * @note 게시글 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        selectFile() {
            if (this.item.uploadFileSn !== 0) {
                const params = {
                    uploadFileSn: this.item.uploadFileSn,
                };

                this.$store
                    .dispatch("selectFile", params)
                    .then((res) => {
                        this.fileNm = res.fileInfo.originalFileNm;
                    })
                    .catch(() => {});
            }
        },
        /**
         * @method clickToDownload
         * @note 게시글 파일 다운로드 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDownload() {
            const url =
                apiServerUrl +
                "/storage/download?uploadFileSn=" +
                this.item.uploadFileSn;
            const downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.setAttribute("download", name);
            downloadLink.click();
        },
        /**
         * @method clickToEdit
         * @note 게시글 수정으로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToEdit() {
            this.$router.push({
                name: "starXrCommunityEdit",
                params: { idx: this.item.communitySn },
            });
        },
        /**
         * @method clickToDelete
         * @note 게시글 삭제alert 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDelete() {
            this.openDialog = true;
            this.dialogTitle = "게시글 삭제";
            this.dialogContent =
                "게시글을 삭제하시겠습니까?<br/>삭제된 글을 복구되지 않습니다.";
        },
        /**
         * @method agreeToDelete
         * @note 게시글을 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToDelete() {
            const params = {
                data: {
                    communitySn: this.item.communitySn,
                },
            };

            this.$store.dispatch("deleteCommunity", params).then(() => {
                this.$router.push({
                    name: "starXrCommunity",
                });
            });
        },
    },
    created() {
        this.selectFile();
    },
};
</script>

<style scoped>
.star-xr-community-detail-title {
    display: flex;
    justify-content: space-between;
}

.star-xr-community-detail-sub-title {
    color: #7b7b7b;
}

.star-xr-community-detail-sub-title-file {
    color: #5792ff;
    text-decoration: underline;
}

.star-xr-community-detail-content {
    line-height: 140%;
}
</style>
