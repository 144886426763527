<!--
   [2024/08/27 sb.hwang]
   StarXrAdminServiceDetailItem.vue
   @note 서비스 관리 서비스 정보 컴포넌트
-->
<template>
    <v-container fluid tabindex="0" class="pa-0" @keyup.enter="fullScreen">
        <v-snackbar
            v-model="showSnackbar"
            class="fullscreen-snackbar"
            :timeout="3000"
        >
            <div class="font-white font-medium star-xr-h6">
                {{ $t("fullScreenInstruction") }}
            </div>
        </v-snackbar>
        <div class="star-xr-admin-service-detail-item-title-wrap">
            <div class="star-xr-admin-service-detail-item-title">
                <div class="detail-item-title font-semi-bold">
                    {{ item.srvcTtl }}
                </div>
                <div class="font-orange font-bold star-xr-h5">
                    {{ $t(srvcCategory[item.srvcCtgrySn]) }}
                </div>
            </div>
            <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="star-xr-community-detail-answer-mode"
                    >
                        <v-icon size="22"> mdi-dots-horizontal </v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="clickToEdit">
                        <v-img
                            :src="require(`@/assets/images/icon-edit.svg`)"
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-gray8 font-medium">
                            {{ $t("edit") }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickToDelete">
                        <v-img
                            :src="
                                require(`@/assets/images/icon-delete-outline.svg`)
                            "
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-red font-medium">
                            {{ $t("delete") }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <div class="canvas-container">
            <unity-vue
                v-if="fileDown"
                :unity="unityContext"
                class="service-detail-webgl"
            />
            <div v-else class="service-detail-webgl" />
        </div>
        <div class="star-xr-admin-service-detail-item-info-wrap">
            <div class="star-xr-h4 font-medium font-gray7">
                {{ item.companyNm + " | " + item.uploadDt }}
            </div>
            <div class="badge-wrap">
                <star-xr-badge
                    v-for="(tagItem, i) in item.tag"
                    :key="i"
                    :text="tagItem"
                />
            </div>
        </div>
        <div
            class="star-xr-admin-service-detail-item-desc star-xr-h5 font-gray8"
        >
            {{ item.srvcCn }}
        </div>
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="agreeToDelete"
        />
    </v-container>
</template>

<script>
import UnityWebgl from "unity-webgl";
import UnityVue from "unity-webgl/vue";
import StarXrBadge from "@/components/com/StarXrBadge.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import { srvcCategory } from "@/commons/resources";
import { storageServerUrl } from "@/config";

export default {
    name: "StarXrAdminServiceDetailItem",
    props: {
        fileDown: {
            type: Boolean,
        },
    },
    components: {
        UnityVue,
        StarXrBadge,
        AlertDialog,
    },
    computed: {
        item() {
            return this.$store.state.service;
        },
        webglFile() {
            return this.$store.getters.getServiceWebglFileUrl;
        },
        token() {
            return this.$store.state.user.token;
        },
    },
    data() {
        return {
            unityContext: null,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            srvcCategory,
            loaderUrl: "",
            dataUrl: "",
            frameworkUrl: "",
            codeUrl: "",
            showSnackbar: false,
        };
    },
    methods: {
        /**
         * @method clickToEdit
         * @note 서비스 편집 화면으로 전환하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToEdit() {
            this.$router.push({
                name: "starXrAdminServiceEdit",
                params: { idx: this.item.srvcSn },
            });
        },
        /**
         * @method clickToDelete
         * @note 서비스 정보 삭제alert 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDelete() {
            this.openDialog = true;
            this.dialogTitle = "deleteService";
            this.dialogContent = "deleteServiceConfirmation";
        },
        /**
         * @method agreeToDelete
         * @note 서비스를 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToDelete() {
            this.$store
                .dispatch("deleteAdminService", this.item.srvcSn)
                .then(() => {
                    this.$router
                        .push({ name: "starXrAdminService" })
                        .catch(() => {});
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        /**
         * @method setUnity
         * @note 유니티 webgl을 세팅하는 함수
         * @email sb.hwang@naviworks.com
         */
        setUnity() {
            this.unityContext = new UnityWebgl({
                loaderUrl:
                    storageServerUrl + this.webglFile.webglLoaderUploadFileUrl,
                dataUrl:
                    storageServerUrl + this.webglFile.webglDataUploadFileUrl,
                frameworkUrl:
                    storageServerUrl +
                    this.webglFile.webglFrameworkUploadFileUrl,
                codeUrl:
                    storageServerUrl + this.webglFile.webglWasmUploadFileUrl,
            });

            this.unityContext.on("mounted", () => {
                console.log("mounted");

                this.resToken();
            });
        },
        /**
         * @method resToken
         * @note Unity에 토큰 전달하는 함수
         * @email gy.yang@naviworks.com
         */
        async resToken() {
            // Unity 양방향 통신 send('게임 오프젝트 이름', '메서드 이름', '보낼 데이터')
            // 게임 오프젝트 이름: 유니티 씬 내의 게임 오브젝트 이름
            // 메서드 이름: 해당 게임 오브젝트에 붙어있는 스크립트의 메서트 이름
            // 보낼 데이터: 전달하려는 데이터(하나의 인자만 보낼 수 있음 리스트&배열 전달 불가능)
            this.unityContext.send("TokenManager", "GetToken", this.token);
        },
        /**
         * @method fullScreen
         * @note WebGL 전체화면 함수
         * @email gy.yang@naviworks.com
         */
        fullScreen() {
            this.unityContext.setFullscreen(true);
        },
    },
    created() {
        this.setUnity();
        this.showSnackbar = true;
    },
    watch: {
        fileDown() {
            this.setUnity();
        },
    },
    beforeDestroy() {
        // UnityCache 삭제
        const unityCache = indexedDB.open("UnityCache", 4);
        unityCache.onsuccess = (event) => {
            const db = event.target.result;
            const objectStore = db
                .transaction(["RequestMetaDataStore"], "readwrite")
                .objectStore("RequestMetaDataStore");
            const deleteReqeust = objectStore.clear();
            deleteReqeust.onsuccess = () => {
                console.log("deleted");
            };
        };
        unityCache.onerror = (event) => {
            console.error("Error opening IndexedDB:", event);
        };

        // /idbfs 삭제
        const idbfsCache = indexedDB.open("/idbfs", 21);
        idbfsCache.onsuccess = (event) => {
            const db = event.target.result;
            const objectStore = db
                .transaction(["FILE_DATA"], "readwrite")
                .objectStore("FILE_DATA");
            const deleteReqeust = objectStore.clear();
            deleteReqeust.onsuccess = () => {
                console.log("deleted");
            };
        };
        idbfsCache.onerror = (event) => {
            console.error("Error opening IndexedDB:", event);
        };

        // cacheStorage 삭제
        const cacheStorage = caches.open("UnityCache_Unity_Unity");
        cacheStorage.then((event) => {
            const deleteCache = event.delete([
                "/starxr/upload/" + this.webglFile.webglDataUploadFileUrl,
            ]);

            deleteCache.then(() => {
                console.log("deleted cahce");
            });
        });

        this.unityContext = null;
    },
};
</script>

<style scoped>
.star-xr-admin-service-detail-item-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.fullscreen-snackbar::v-deep .v-snack__wrapper {
    top: -620px;
    left: 120px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: none;
}

.star-xr-admin-service-detail-item-title {
    display: flex;
    align-items: center;
    gap: 14px;
}

.detail-item-title {
    font-size: 24px;
}

.v-list {
    background-color: #f5f5f5;
}

.v-list-item {
    gap: 3px;
}

.v-list-item__title {
    font-size: 14px;
}

.canvas-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 5px;
}

.service-detail-webgl {
    width: 100%;
    height: auto;
    background-color: #ececec;
    border-radius: 10px;
}

.star-xr-admin-service-detail-item-info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 14px;
}

.badge-wrap {
    display: flex;
    gap: 6px;
}

.star-xr-admin-service-detail-item-desc {
    padding: 14px;
    background-color: #fafafa;
    border-radius: 5px;
}
</style>
