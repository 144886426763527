<!--
   [2024/08/09 gy.yang]
   DatePicker.vue
   @note 날짜 선택 컴포넌트
-->
<template>
    <v-menu
        ref="menu"
        :close-on-content-click="false"
        offset-y
        min-width="auto"
        transition="scale-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                v-bind="attrs"
                v-on="on"
                append-icon="mdi-calendar-blank"
                hide-details
                class="star-xr-input date-picker-input pt-0"
                placeholder="YYYY-MM-DD"
            />
        </template>
        <v-date-picker
            v-model="date"
            :day-format="dayFormat"
            :header-date-format="headerDateFormat"
            no-title
            scrollable
            locale="ko-KR"
            class="star-xr-date-picker"
        >
            <v-spacer />
            <v-btn
                text
                @click="onPick"
            >
                선택
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>


<script>
export default {
    name: 'DatePicker',
    props: {
        value: {
            type: String,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        innerIcon: {
            type: Boolean,
            default: false,
        },
        pickText: {
            type: String,
            default: '선택',
        },
    },
    data() {
        return {
            date: this.value,
            menu: false
        };
    },
    computed: {
        dateText() {
            return this.date;
        },
    },
    methods: {
        /**
         * @method dayFormat
         * @param {string} date ISO 8601 형식의 날짜
         * @returns {string} 날짜 문자열의 형식
         * @note datePicker 날짜 테이블에 표시되는 날짜 문자열의 형식을 반환하는 함수
         * @email gy.yang@naviworks.com
         */
        dayFormat(date) {
            let i = new Date(date).getUTCDate();
            return i;
        },
        /**
         * @method headerDateFormat
         * @param {string} date ISO 8601 형식의 날짜
         * @returns {string} 월 문자열의 형식
         * @note datePicker 머리글에 표시되는 월 문자열의 형식을 반환하는 함수
         * @email gy.yang@naviworks.com
         */
        headerDateFormat(date) {
            let dateSplit = date.split('-');
            if (1 === dateSplit.length) {
                return dateSplit[0] + '년';
            }

            return dateSplit[0] + '. ' + dateSplit[1];
        },
        /**
         * @method onPick
         * @note 선택 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        onPick() {
            this.$refs.menu.save(this.date);
            this.$emit('input', this.date);
        },
    },
    watch: {
        value(val) {
            this.date = val;
        },
    },
};
</script>

<style scoped>
.date-picker-input::v-deep .v-icon{
    margin-top: 8px;
    margin-right: 10px;
    color: #707070 !important;
}

.star-xr-date-picker {
    margin-top: 10px;
    border: 1px solid rgba(136, 136, 136, 0.25);
    border-radius: 5px;
    background-color: #FFFFFF !important;
}
</style>
