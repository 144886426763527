<!--
   [2024/08/16 sb.hwang]
   StarXrDocsNav.vue
   @note STAR-XR docs nav
-->
<template>
    <v-navigation-drawer width="325px" color="#F8F8F8" mobile-breakpoint="sm">
        <v-list
            subheader
            v-for="(menu, index) in menus"
            class="docs-nav-list"
            :key="index"
        >
            <v-subheader
                class="docs-nav-list-item star-xr-h5 cursor-pointer"
                @click="clickMenu(menu.to)"
                :class="activeClass(menu.to)"
            >
                {{ menu.name }}
            </v-subheader>
            <v-list
                v-for="(subMenu, subIndex) in menu.subMenus"
                :key="subIndex"
                :title="'List Item' + subIndex"
            >
                <v-list-item-title
                    class="docs-nav-list-item sub-menu star-xr-h5 cursor-pointer"
                    :class="activeClass(subMenu.to)"
                    @click="clickMenu(subMenu.to)"
                >
                    {{ subMenu.name }}
                </v-list-item-title>
                <v-list-item
                    v-for="(subsubMenu, subsubIndex) in subMenu.subsubMenus"
                    :key="subsubIndex"
                    class="docs-nav-list-item sub-sub-menu star-xr-h5"
                    :class="activeClass(subsubMenu.to)"
                    @click="clickMenu(subsubMenu.to)"
                >
                    {{ subsubMenu.name }}
                </v-list-item>
            </v-list>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { marked } from "marked";

export default {
    name: "StarXrDocsNav",
    props: {
        routerNm: {
            type: String,
        },
    },
    computed: {
        menus() {
            return this.$store.state.docMenus.menus;
        },
        docs() {
            return this.$store.state.docs;
        },
        text() {
            return this.routerNm === "api"
                ? this.docs.apiText
                : this.docs.documentText;
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method generateTableOfContents
         * @param {String} markdownContent 마크다운내용
         * @returns {String} 클래스 명
         * @note 마크다운에서 메뉴 추출하는 함수
         * @email sb.hwang@naviworks.com
         */
        generateTableOfContents(markdownContent) {
            const tocItems = [];
            const tokens = marked.lexer(markdownContent);

            marked.walkTokens(tokens, (token) => {
                if (token.type === "heading" && token.depth < 4) {
                    tocItems.push({
                        text: token.text,
                        level: token.depth,
                    });
                }
            });

            this.processMenus(tocItems);
        },
        /**
         * @method processMenus
         * @param {String} tocs 추출된 메뉴
         * @returns {String} 클래스 명
         * @note 추출된 메뉴를 가공하는 함수
         * @email sb.hwang@naviworks.com
         */
        processMenus(tocs) {
            let index = -1;
            let subIndex = -1;
            let menus = {};

            tocs.map((item) => {
                if (item.level === 1) {
                    index++;
                    subIndex = -1;
                    menus[index] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                        subMenus: {},
                    };
                } else if (item.level === 2) {
                    subIndex++;
                    menus[index].subMenus[subIndex] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                        subsubMenus: {},
                    };
                } else if (item.level === 3) {
                    menus[index].subMenus[subIndex].subsubMenus[item.text] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                    };
                }
            });
            this.$store.commit("setDocMenus", menus);
        },
        /**
         * @method isActive
         * @param {String} to 선택한 메뉴 url
         * @returns {Boolean} active 여부
         * @note 아이템 active 확인 함수
         * @email sb.hwang@naviworks.com
         */
        isActive(to) {
            return this.$route.fullPath.split("#")[1] === to.toLowerCase();
        },
        /**
         * @method activeClass
         * @param {String} to 선택한 메뉴 url
         * @returns {String} 클래스 명
         * @note activce 아이템 클래스 명 추가 함수
         * @email sb.hwang@naviworks.com
         */
        activeClass(to) {
            return this.isActive(to)
                ? "nav-active font-orange font-semi-bold"
                : "font-gray8";
        },
        /**
         * @method clickMenu
         * @param {String} topMenutTo 선택한 상단 메뉴 url
         * @param {String} subMenuTo 선택한 서브 메뉴 url
         * @note 메뉴 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickMenu(menuTo) {
            let menu = menuTo.toLowerCase();

            if (this.$route.fullPath != `/${this.routerNm}#${menu}`) {
                this.$router.push(`/${this.routerNm}#${menu}`).catch();
            }
        },
    },
    watch: {
        text() {
            this.generateTableOfContents(this.text);
        },
    },
    created() {
        if (!this.menus.length) {
            this.generateTableOfContents(this.text);
        }
    },
};
</script>

<style>
.v-list {
    padding: 0;
}

.nav-active::before {
    opacity: 0 !important;
}

.docs-nav-list-item {
    min-height: 0;
    padding: 12.5px 40px;
    border-bottom: 1px solid #e9e9e9;
}

.sub-menu {
    padding-left: 52px;
}
.sub-sub-menu {
    padding-left: 64px;
}

.v-navigation-drawer__border {
    width: 0;
}

.docs-nav-list-items.nav-active::before {
    opacity: 0;
}
</style>
