import { render, staticRenderFns } from "./KakaoPayTest.vue?vue&type=template&id=6cd2a99b&scoped=true&"
import script from "./KakaoPayTest.vue?vue&type=script&lang=js&"
export * from "./KakaoPayTest.vue?vue&type=script&lang=js&"
import style0 from "./KakaoPayTest.vue?vue&type=style&index=0&id=6cd2a99b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd2a99b",
  null
  
)

export default component.exports