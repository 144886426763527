<!--
   [2024/08/20 gy.yang]
   StarXrSignUpInfoEmail.vue
   @note 회원가입 이메일 입력 컴포넌트
-->
<template>
    <v-container fluid class="mt-15">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="2" class="font-gray7 px-md-0">
                    {{ $t("email") }}
                </v-col>
                <v-col cols="12" md="10" class="py-0 px-0">
                    <v-row align="center">
                        <v-col cols="5" class="pr-1">
                            <v-text-field
                                v-model="email"
                                hide-details
                                height="40"
                                class="star-xr-input mt-0 pt-0"
                                :placeholder="$t('enterEmail')"
                            />
                        </v-col>
                        @
                        <v-col cols="6" class="pl-1 pr-0">
                            <v-select
                                v-if="!showInput"
                                v-model="domain"
                                :items="domainList"
                                height="40"
                                hide-details
                                class="star-xr-select mt-0 pt-0"
                            >
                                <template v-slot:append-item>
                                    <v-list-item
                                        class="select-input"
                                        @click="
                                            [(showInput = true), (domain = '')]
                                        "
                                    >
                                        {{ $t("enterManually") }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <v-text-field
                                v-else
                                v-model="domain"
                                height="40"
                                :placeholder="$t('enterManually')"
                                hide-details
                                class="star-xr-input domain-input mt-0 pt-0"
                            >
                                <template v-slot:append-outer>
                                    <v-btn
                                        icon
                                        @click="
                                            [
                                                (showInput = false),
                                                (domain = 'naver.com'),
                                            ]
                                        "
                                    >
                                        <v-icon> mdi-close </v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12" lg="5" class="px-lg-1 pb-md-3 pb-1">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange-outlined"
                    @click="clickBack"
                >
                    {{ $t("previous") }}
                </v-btn>
            </v-col>
            <v-col cols="12" lg="5" class="px-lg-1 pt-md-3 pt-1">
                <v-btn
                    block
                    height="40"
                    :disabled="disabled"
                    class="star-xr-btn-orange"
                    @click="clickCertify"
                >
                    {{ $t("verify") }}
                </v-btn>
            </v-col>
        </v-row>

        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            :is-disable="false"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
        />
    </v-container>
</template>

<script>
import { signUpStep } from "@/commons/resources";
import AlertDialog from "@/components/com/AlertDialog.vue";

export default {
    name: "StarXrSignUpInfoEmail",
    components: {
        AlertDialog,
    },
    data() {
        return {
            email: "",
            domainList: ["naver.com", "gmail.com", "kakao.com"],
            domain: "naver.com",
            showInput: false,
            disabled: true,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
        };
    },
    methods: {
        /**
         * @method clickBack
         * @note 이전 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickBack() {
            this.$emit("nextPage", signUpStep.Terms);
        },
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            this.$emit("nextPage", signUpStep.CheckEmail);
            this.$store.commit(
                "setUserEmlAddr",
                this.email + "@" + this.domain
            );
        },
        /**
         * @method clickCertify
         * @note 인증하기 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickCertify() {
            this.$store
                .dispatch("emlCheck", this.email + "@" + this.domain)
                .then(() => {
                    this.emlCertifySend();
                })
                .catch(() => {
                    this.openDialog = true;
                    this.dialogTitle = "signUpError";
                    this.dialogContent = "emailSendFailure";
                });
        },
        /**
         * @method emlCertifySend
         * @note 인증 메일 발송 함수
         * @email sb.hwang@naviworks.com
         */
        emlCertifySend() {
            const params = {
                emlAddr: this.email + "@" + this.domain,
            };
            this.$store
                .dispatch("emlCertifySend", params)
                .then(() => {
                    this.clickNext();
                })
                .catch((err) => {
                    if (err.errorMsg === "이미 등록된 이메일 입니다") {
                        this.openDialog = true;
                        this.dialogTitle = "signUpError";
                        this.dialogContent = "emailAlreadyRegistered";
                    }
                });
        },
    },
    watch: {
        email(value) {
            if (this.domain && value) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        domain(value) {
            if (this.email && value) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 195px;
}

.domain-input::v-deep .v-input__append-outer {
    margin-left: 0;
}
</style>
