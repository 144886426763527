<!--
   [2024/08/20 gy.yang]
   StarXrFindPwEnd.vue
   @note 비밀번호 변경 완료 컴포넌트
-->
<template>
    <v-container fluid class="mt-5">
        <v-row>
            <v-col cols="12">
                <div class="font-gray7 text-center">
                    {{ $t("passwordChangeComplete") }}
                </div>
                <v-img
                    :src="require('@/assets/images/signup-end.svg')"
                    contain
                    height="135"
                    class="mt-4"
                />
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange"
                    @click="routerLogin"
                >
                    {{ $t("login") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "StarXrFindPwEnd",
    data() {
        return {};
    },
    methods: {
        /**
         * @method routerLogin
         * @note 로그인으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerLogin() {
            this.$router
                .push({
                    name: "starXrLogin",
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 56px;
}
</style>
