<!--
   [2024/02/13 gy.yang]
   StarXrHeader.vue
   @note STAR-XR 웹 페이지 헤더
-->
<template>
    <v-app-bar app dark height="70" elevation="0" class="star-xr-header">
        <v-app-bar-nav-icon v-if="isMobile" @click="$emit('onControlMenu')" />
        <v-row justify="start" align="center" class="ma-auto pa-0 px-lg-10">
            <v-col v-show="!isMobile" cols="auto">
                <v-img
                    :src="require('@/assets/images/star-xr-logo.svg')"
                    contain
                    height="30"
                    class="cursor-pointer"
                    @click="routerHome"
                />
            </v-col>
            <v-col v-show="!isMobile" cols="auto" class="ml-xl-16 ml-lg-10">
                <v-tabs dark hide-slider class="star-xr-menus">
                    <v-tab
                        v-for="(menu, i) in menus"
                        :key="`star-xr-menus-${i}`"
                        :to="menu.to"
                        class="star-xr-menus-item custom-mouse-style px-lg-6 px-1"
                    >
                        <span class="star-xr-h4 font-medium">
                            {{ menu.name }}
                        </span>
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                    "
                >
                    <!-- flex-wrap: nowrap 추가 -->
                    <div style="display: flex; margin-right: 20px">
                        <!-- margin 값 줄임 -->
                        <v-select
                            v-model="translation"
                            :items="translations"
                            :menu-props="{
                                offsetY: true,
                                contentClass: 'font-white cursor-pointer',
                                openOnFocus: true,
                            }"
                            dense
                            rounded
                            outlined
                            hide-details
                            height="30"
                            style="max-width: 120px"
                            class="star-xr-header-select"
                            :aria-label="$t('hompage') + ' ' + $t('language')"
                            @change="changeLocale()"
                        />
                    </div>
                    <div v-if="loggedIn" class="star-xr-menus-login-wrap">
                        <div class="font-white star-xr-h4 mr-9 cursor-pointer">
                            {{ $t("welcomeMessage", { userNm: user.userNm }) }}
                        </div>
                        <v-menu open-on-hover offset-y left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-user.svg`)
                                    "
                                    cover
                                    width="30"
                                    height="30"
                                    class="cursor-pointer"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>

                            <v-list class="text-center">
                                <v-list-item @click="routerMyPage">
                                    <v-list-item-title class="py-2">
                                        {{ $t("myPage") }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="logout">
                                    <v-list-item-title class="py-2">
                                        {{ $t("logout") }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div v-else class="star-xr-menus-login-wrap">
                        <div
                            class="font-white star-xr-h4 mr-9 cursor-pointer"
                            @click="routerLogin"
                        >
                            {{ $t("login") }}
                        </div>
                        <v-img
                            :src="require(`@/assets/images/icon-user.svg`)"
                            cover
                            width="30"
                            height="30"
                        />
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
import { menus } from "@/commons/menus.js";

export default {
    name: "StarXrHeader",
    data() {
        return {
            translations: [
                { text: "한국어", value: "ko" },
                { text: "ENG", value: "en" },
            ],
            menus,
            showNav: false,
            name: "김네비",
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        loggedIn() {
            return this.$cookies.isKey("user_token");
        },
        user() {
            return this.$store.state.user;
        },
        translation: {
            get() {
                return this.$store.state.user.translation;
            },
            set(value) {
                this.$store.commit("setTranslation", value);
            },
        },
    },
    methods: {
        /**
         * @method routerHome
         * @note 메인화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerHome() {
            if (this.$route.name != "starXrHome") {
                this.$router.push({
                    name: "starXrHome",
                });
            }
        },
        /**
         * @method routerMyPage
         * @note 마이페이지로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        routerMyPage() {
            this.$router
                .push({
                    name: "starXrMyPage",
                })
                .catch(() => {});
        },
        /**
         * @method logout
         * @note 로그아웃하는 함수
         * @email sb.hwang@naviworks.com
         */
        logout() {
            this.$cookies
                .keys()
                .forEach((cookie) => this.$cookies.remove(cookie));
            this.$store.dispatch("resetLoginUser");
            location.href = "/";
        },
        /**
         * @method routerLogin
         * @note 로그인으로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        routerLogin() {
            this.$router
                .push({
                    name: "starXrLogin",
                })
                .catch(() => {});
        },
        /**
         * @method changeLocale
         * @note 한영 번역기 선택 함수
         * @email se.yoo@naviworks.com
         */
        changeLocale() {
            this.$i18n.locale = this.translation;
        },
    },
    created() {
        this.$i18n.locale = this.translation;
    },
};
</script>

<style scoped>
.star-xr-header {
    background-color: #0d1116 !important;
}

.star-xr-header::v-deep .v-toolbar__content {
    padding: 0 16px;
}

.star-xr-menus::v-deep .v-tabs-bar {
    background-color: transparent !important;
}

.star-xr-menus-item {
    font-weight: 400;
    letter-spacing: unset;
    color: #b1b1b1 !important;
    text-transform: none !important;
}

.star-xr-menus-item span {
    padding: 6px 16px;
    color: #909090 !important;
}

.star-xr-menus-item.v-tab--active span {
    color: #ff6f06 !important;
    font-family: Pretendard-SemiBold, "Pretendard-SemiBold" !important;
    border-radius: 100px;
    box-shadow: 0 0 0 1px #ff6f06 inset;
}

.v-divider {
    border-color: #999999 !important;
}

.v-list {
    background-color: #f0f0f0;
}

.star-xr-menus-login-wrap {
    display: flex;
    align-items: center;
}

@media (max-width: 1410px) {
    .star-xr-menus-item {
        padding: 0 8px !important; /* 패딩값 줄임 */
    }

    .ml-xl-16 {
        margin-left: 0px !important; /* 왼쪽 마진 줄임 */
    }
}

@media (max-width: 1279px) {
    .star-xr-menus-item span {
        font-size: 16px !important;
    }
    .star-xr-menus-login-wrap {
        margin-left: 10px !important;
    }
}

@media (max-width: 1127px) {
    .star-xr-menus-item span {
        font-size: 16px !important;
    }

    .star-xr-menus-login-wrap {
        margin-left: 0px !important;
    }

    .px-lg-6 {
        padding-inline: 0px !important;
    }

    .ml-lg-10 {
        padding-inline: 0px !important;
    }

    .col.col-auto {
        padding-inline: 0px !important;
    }
}

@media (max-width: 1077px) {
    .star-xr-menus-item span {
        font-size: 14px !important;
    }

    .star-xr-menus-login-wrap {
        margin-left: 0px !important;
    }

    .px-lg-6 {
        padding-inline: 0px !important;
    }

    .star-xr-h4 {
        padding-inline: 0px !important;
    }

    .ml-lg-10 {
        padding-inline: 0px !important;
        margin-left: -20px !important;
    }

    .col.col-auto {
        padding-inline: 0px !important;
        margin-left: -10px !important;
    }
}

.star-xr-header-select ::v-deep .v-select__selection,
.star-xr-header-select ::v-deep .v-select__selection--comma,
.star-xr-header-select ::v-deep .v-input__slot {
    color: white !important;
}

.star-xr-header-select ::v-deep .v-input__slot {
    border-color: rgba(255, 255, 255, 0.7) !important;
    border-width: 2px !important;
}

.star-xr-header-select ::v-deep .v-select__slot input {
    color: white !important;
}

.star-xr-header-select {
    min-width: 120px;
    max-width: 150px;
}
</style>
