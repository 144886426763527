<!--
   [2024/08/29 sb.hwang]
   StarXrDocsMenuContentMarkDownEdit.vue
   @note STAR-XR docs 메뉴 내용 마크다운 수정 컴포넌트
-->
<template>
    <v-container
        fluid
        class="pa-0"
    >
        <v-md-editor 
            v-model="text"
            :include-level="[1,2,3]"
            class="star-xr-docs mb-15"
        />
    </v-container>
</template>

<script>

export default {
    name: 'StarXrDocsMenuContentMarkDownEdit',
    props: {
    },
    components: {
    },
    computed: {
        routerNm() {
            return this.$route.path.split('/')[1];
        },
        mutations() {
            return this.routerNm === 'api' ? 'setApiText' : 'setDocumentText';
        },
        docs() {
            return this.$store.state.docs;
        },
        text: {
            get() {    
                return this.routerNm === 'api' ? this.docs.apiText : this.docs.documentText;
            },
            set(value) {
                this.$store.commit(this.mutations, value);
            }
        }
    },
    data() {
        return{
        }
    },
    methods: {  
    },
    created() {
    }
}
</script>

<style scoped> 
@import url('@/assets/css/docs-style.css');

.v-md-editor {
    height: 100%;
}

/* vuetify lg(<1904px) */
@media (max-width: 1904px) {
    .star-xr-document-container {
        padding: 0;
        padding-bottom: 20px
    }
}
</style>