<!--
   [2024/08/09 gy.yang]
   StarXrSignUpInfoCompany.vue
   @note 회원가입 사용자 소속 입력 컴포넌트
-->
<template>
    <v-container fluid class="mt-9">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("organization") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0 px-md-0">
                    <v-text-field
                        v-model="companyNm"
                        class="star-xr-input pt-0"
                        :placeholder="$t('enterOrganization')"
                    />
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("nickname") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0 px-md-0">
                    <v-text-field
                        v-model="nickNm"
                        height="40"
                        class="star-xr-input mt-0 pt-0"
                        messages=" "
                        :placeholder="$t('enterNickname')"
                    >
                        <template v-slot:message>
                            <span
                                :class="{
                                    'font-green':
                                        message == 'nicknameAvailable',
                                    'font-red': message != 'nicknameAvailable',
                                }"
                            >
                                {{ $t(message) }}
                            </span>
                        </template>
                        <template v-slot:append-outer>
                            <v-btn
                                min-width="73"
                                max-width="73"
                                height="40"
                                class="star-xr-btn-gray-outlined"
                                @click="clickCheckNickNm"
                            >
                                {{ $t("checkDuplicate") }}
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12" lg="5" class="px-lg-1 pb-md-3 pb-1">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange-outlined"
                    @click="clickBack"
                >
                    {{ $t("previous") }}
                </v-btn>
            </v-col>
            <v-col cols="12" lg="5" class="px-lg-1 pt-md-3 pt-1">
                <v-btn
                    block
                    height="40"
                    :disabled="disabled"
                    class="star-xr-btn-orange"
                    @click="clickNext"
                >
                    {{ $t("next") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { signUpStep } from "@/commons/resources";

export default {
    name: "StarXrSignUpInfoCompany",
    components: {},
    data() {
        return {
            companyNm: "",
            nickNm: "",
            message: "",
            disabled: true,
        };
    },
    methods: {
        /**
         * @method clickBack
         * @note 이전 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickBack() {
            this.$emit("nextPage", signUpStep.InfoName);
            this.$store.commit("setUserPswd", "");
        },
        /**
         * @method clickCheckNickNm
         * @note 닉네임 중복확인 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickCheckNickNm() {
            this.$store
                .dispatch("nickNmCheck", this.nickNm)
                .then((res) => {
                    switch (res) {
                        case "사용 가능한 닉네임 입니다.":
                            this.message = "nicknameAvailable";
                            break;
                        case "닉네임을 입력 바랍니다":
                            this.message = "enterNickname";
                            break;
                        case "이미 등록된 닉네임 입니다":
                            this.message = "nicknameAlreadyRegistered";
                            break;
                    }
                })
                .catch((err) => {
                    switch (err.data.errorMsg) {
                        case "사용 가능한 닉네임 입니다.":
                            this.message = "nicknameAvailable";
                            break;
                        case "닉네임을 입력 바랍니다":
                            this.message = "enterNickname";
                            break;
                        case "이미 등록된 닉네임 입니다":
                            this.message = "nicknameAlreadyRegistered";
                            break;
                    }
                });
        },
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            const userInfo = {
                companyNm: this.companyNm,
                nickNm: this.nickNm,
            };

            this.$store.commit("setUserCompany", userInfo);
            this.$emit("nextPage", signUpStep.InfoName);
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 40px;
}
</style>
