<!--
   [2024/08/20 gy.yang]
   StarXrSignUpCheckEmail.vue
   @note 회원가입 이메일 인증 확인 컴포넌트
-->
<template>
    <v-container fluid class="mt-2">
        <v-row align="center">
            <v-col cols="12">
                <div
                    class="font-gray7 text-center"
                    v-html="$t('emailSent')"
                ></div>
            </v-col>
            <v-col cols="12" class="check-email-input-wrap mt-11">
                <div class="font-gray7">{{ $t("verificationCode") }}</div>
                <v-text-field
                    v-model="confirmCode"
                    class="star-xr-input pt-0"
                    :placeholder="$t('enterVerificationCode')"
                    hide-details
                />
            </v-col>
            <v-col v-if="!isMatch && confirmCode" class="pt-0">
                <div class="font-red">{{ $t("verificationCodeMismatch") }}</div>
            </v-col>
        </v-row>
        <div class="check-email-footer">
            <div class="font-gray6 font-light d-flex justify-center">
                {{ $t("didNotReceiveEmail") }}
                <span
                    class="font-regular ml-2 cursor-pointer check-email-again"
                    @click="clickSendAgainEmail"
                >
                    {{ $t("resendEmail") }}
                </span>
            </div>
            <div class="d-flex justify-center mt-4">
                <v-btn
                    width="145"
                    height="40"
                    class="star-xr-btn-orange-outlined mr-2"
                    @click="clickBack"
                >
                    {{ $t("previous") }}
                </v-btn>
                <v-btn
                    width="145"
                    height="40"
                    class="star-xr-btn-orange"
                    :disabled="disabled"
                    @click="clickNext"
                >
                    {{ $t("next") }}
                </v-btn>
            </div>
        </div>

        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            :is-disable="false"
        />
    </v-container>
</template>

<script>
import { signUpStep } from "@/commons/resources";
import AlertDialog from "@/components/com/AlertDialog.vue";

export default {
    name: "StarXrSignUpCheckEmail",
    components: {
        AlertDialog,
    },
    data() {
        return {
            confirmCode: "",
            disabled: true,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            isMatch: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    methods: {
        /**
         * @method clickBack
         * @note 이전 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickBack() {
            this.$emit("nextPage", signUpStep.InfoEmail);
            this.$store.commit("setUserEmlAddr", "");
        },
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            this.$emit("nextPage", signUpStep.InfoPw);
        },
        /**
         * @method clickSendAgainEmail
         * @note 이메일 인증 메일 재전송하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickSendAgainEmail() {
            const params = {
                emlAddr: this.user.emlAddr,
            };
            this.$store
                .dispatch("emlCertifySend", params)
                .then(() => {
                    this.openDialog = true;
                    this.dialogTitle = "resendEmailSuccess";
                    this.dialogContent = "resendEmailMessage";
                })
                .catch((err) => {
                    if (err.errorMsg === "이미 등록된 이메일 입니다") {
                        this.openDialog = true;
                        this.dialogTitle = "signUpError";
                        this.dialogContent = "emailAlreadyRegistered";
                    }
                });
        },
        /**
         * @method emlCertifyCheck
         * @note 이메일 인증번호를 확인하는 함수
         * @email sb.hwang@naviworks.com
         */
        emlCertifyCheck(value) {
            if (value) {
                const params = {
                    emlAddr: this.user.emlAddr,
                    randomNumber: this.confirmCode,
                };
                this.$store
                    .dispatch("emlCertifyCheck", params)
                    .then((res) => {
                        if (
                            res.emailVerficationResult ===
                            "이메일 인증이 되었습니다."
                        ) {
                            this.isMatch = true;
                            this.disabled = false;
                        } else {
                            this.isMatch = false;
                            this.disabled = true;
                        }
                    })
                    .catch(() => {});
            } else {
                this.isMatch = false;
                this.disabled = true;
            }
        },
    },
    watch: {
        confirmCode(value) {
            this.emlCertifyCheck(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.check-email-input-wrap {
    display: flex;
    align-items: center;
    gap: 48px;
}

.check-email-footer {
    margin-top: 97px;
}

.check-email-again {
    color: #595959;
}

.check-email-again:hover {
    text-decoration: underline;
}
</style>
