<!--
   [2024/08/26 gy.yang]
   starXrPaymentHistoryDetail.vue
   @note STAR-XR 결제 내역 상세 화면
-->
<template>
    <v-container 
        fluid
        class="admin-payment-history-detail-container"
    >
        <div class="star-xr-h1 font-semi-bold mb-12">결제 내역</div>
        <v-row>
            <v-col
                cols="12"
                class="mt-2"
            >
                <star-xr-payment-history-info/>
            </v-col>
            <v-col
                cols="12"
                class="mt-12"
            >
                <star-xr-payment-history-charge/>
            </v-col>
        </v-row>
        <v-row
            align="center"
            class="mt-16"
        >
            <v-col
                v-if="payment.stateSn > 0"
                cols="6"
                md="auto"
            >
                <div
                    class="star-xr-btn-text cursor-pointer"
                    @click="clickCancelPayment"
                >
                    결제 취소
                </div>
            </v-col>
            <v-spacer/>
            <v-col
                cols="6"
                md="3"
                lg="2"
                xl="1"
            >
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-gray-outlined"
                    @click="routerPaymentHistory"
                >
                    목록 보기
                </v-btn>
            </v-col>
        </v-row>
        <alert-dialog
            v-model="showDialog"
            :title="title"
            :content="content"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="clickAgree"
        />
        <alert-dialog
            v-model="showAlertDialog"
            :title="title"
            :content="content"
            :is-disable="false"
        />
    </v-container>
</template>

<script>
import StarXrPaymentHistoryInfo from '@/components/account/StarXrPaymentHistoryInfo.vue';
import StarXrPaymentHistoryCharge from '@/components/account/StarXrPaymentHistoryCharge.vue';
import AlertDialog from '@/components/com/AlertDialog.vue';

export default {
    name: 'starXrPaymentHistoryDetail',
    components: {
        StarXrPaymentHistoryInfo,
        StarXrPaymentHistoryCharge,
        AlertDialog
    },
    computed: {
        payment() {
            return this.$store.state.payment;
        }
    },
    data() {
        return {
            showDialog: false,
            showAlertDialog: false,
            title: '',
            content: '',
        }
    },
    methods: {
        /**
         * @method clickCancelPayment
         * @note 결제취소 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickCancelPayment() {
            this.showDialog = true;
            this.title = '결제 취소하기';
            this.content = '정말 결제를 취소하시겠습니까?';
        },
        /**
         * @method clickAgree
         * @note 확인 다이얼로그 확인 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickAgree() {
            const params = {
                paymentSn: Number(this.$route.params.idx)
            };

            this.$store.dispatch('cancelPayment', params)
            .then(() => {
                this.showAlertDialog = true;
                this.title = '결제 취소 성공';
                this.content = '결제 취소를 완료했습니다.';
            }).catch(err => {
                this.showAlertDialog = true;
                this.title = '결제 취소 실패';
                this.content = err.errorMsg;
            });
        },
        /**
         * @method routerPaymentHistory
         * @note 결제 내역 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerPaymentHistory() {
            this.$router.push({
                name: 'starXrPaymentHistory'
            }).catch(()=>{});
        }
    }
}
</script>

<style scoped>
.admin-payment-history-detail-container {
    padding: 0;
    padding-right: 195px;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}
</style>