<!--
   [2024/08/13 sb.hwang]
   StarXrMyPage.vue
   @note STAR-XR 마이페이지 화면 
-->
<template>
    <v-container fluid class="my-page-container">
        <div class="star-xr-h1 font-semi-bold">{{ $t("myPage") }}</div>
        <star-xr-my-page-info-wrap v-if="!editMode" :key="editMode" />
        <star-xr-my-page-info-edit-wrap v-else />
        <div class="my-page-footer">
            <div
                class="star-xr-btn-text cursor-pointer"
                @click="clickToDeleteAccount"
            >
                {{ $t("deleteAccount") }}
            </div>
            <div>
                <v-btn
                    class="star-xr-btn-orange-outlined"
                    width="130px"
                    @click="changeEditMode"
                >
                    {{ $t(btnName) }}
                </v-btn>
                <v-btn
                    v-if="editMode"
                    class="star-xr-btn-orange ml-4"
                    width="130px"
                    @click="saveEdit"
                >
                    {{ $t("save") }}
                </v-btn>
            </div>
        </div>
        <alert-dialog
            v-model="openEditDialog"
            :title="dialogEditTitle"
            :content="dialogEditContent"
            :is-disable="false"
        />
        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="agreeToDelete"
        />
    </v-container>
</template>

<script>
import StarXrMyPageInfoWrap from "@/components/account/StarXrMyPageInfoWrap.vue";
import StarXrMyPageInfoEditWrap from "@/components/account/StarXrMyPageInfoEditWrap.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";

export default {
    name: "StarXrMyPage",
    components: {
        StarXrMyPageInfoWrap,
        StarXrMyPageInfoEditWrap,
        AlertDialog,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    data() {
        return {
            editMode: false,
            btnName: "edit",
            openEditDialog: false,
            dialogEditTitle: "",
            dialogEditContent: "",
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
        };
    },
    methods: {
        /**
         * @method changeBtnNm
         * @note 버튼 이름을 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        changeBtnNm() {
            if (this.editMode) {
                this.btnName = "cancel";
            } else {
                this.btnName = "edit";
            }
        },
        /**
         * @method changeEditMode
         * @note 편집모드를 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        changeEditMode() {
            this.editMode = !this.editMode;
            this.changeBtnNm();
        },
        /**
         * @method saveEdit
         * @note 편집 내용을 저장하는 함수
         * @email sb.hwang@naviworks.com
         */
        saveEdit() {
            this.updateAccount();
        },
        /**
         * @method updateAccount
         * @note 편집 내용을 저장하는 함수
         * @email sb.hwang@naviworks.com
         */
        updateAccount() {
            const params = {
                companyNm: this.user.companyNm,
                userNm: this.user.userNm,
                nickNm: this.user.nickNm,
                brDt: this.user.brDt.replaceAll("-", ""),
                telNo: this.user.telNo,
            };

            this.$store.dispatch("updateAccount", params).then(() => {
                this.changeEditMode();
                this.openEditDialog = true;
                this.dialogEditTitle = "editComplete";
                this.dialogEditContent = "editCompleteMessage";
            });
        },
        /**
         * @method clickToDeleteAccount
         * @note 회원 탈퇴하기 클릭 시 실행하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDeleteAccount() {
            this.openDialog = true;
            this.dialogTitle = "deleteAccount";
            this.dialogContent = "deleteAccountMessage";
        },
        /**
         * @method agreeToDelete
         * @note 회원 탈퇴 확인 시 실행하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToDelete() {
            this.$store.dispatch("deleteAccount").then(() => {
                this.$cookies
                    .keys()
                    .forEach((cookie) => this.$cookies.remove(cookie));
                this.$store.dispatch("resetLoginUser");
                location.href = "/";
            });
        },
    },
};
</script>

<style scoped>
.my-page-container {
    padding: 0;
    padding-right: 195px;
}

.my-page-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}
</style>
