<!--
   [2024/09/23 gy.yang]
   StarXrSignUpInfoName.vue
   @note 회원가입 사용자 이름 입력 컴포넌트
-->
<template>
    <v-container fluid class="mt-9">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("name") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0 px-md-0">
                    <v-text-field
                        v-model="userNm"
                        hide-details
                        height="40"
                        class="star-xr-input mt-0 pt-0"
                        :placeholder="$t('enterName')"
                    />
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0 mt-3">
                    {{ $t("dateOfBirth") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0 px-md-0 mt-md-3">
                    <date-picker v-model="brDt" />
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0 mt-3">
                    {{ $t("phoneNumber") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0 px-md-0 mt-md-4">
                    <v-row align="center">
                        <v-col cols="5" class="pr-1">
                            <v-select
                                v-model="phoneNum1"
                                :items="phoneNumList"
                                height="40"
                                hide-details
                                class="star-xr-select mt-0 pt-0"
                            />
                        </v-col>
                        -
                        <v-col cols="3" class="px-1">
                            <v-text-field
                                v-model="phoneNum2"
                                hide-details
                                class="star-xr-input mt-0 pt-0"
                                placeholder="1234"
                            />
                        </v-col>
                        -
                        <v-col cols="3" class="pl-1 pr-0">
                            <v-text-field
                                v-model="phoneNum3"
                                hide-details
                                class="star-xr-input mt-0 pt-0"
                                placeholder="1234"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12" lg="5" class="px-lg-1 pb-md-3 pb-1">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange-outlined"
                    @click="clickBack"
                >
                    {{ $t("previous") }}
                </v-btn>
            </v-col>
            <v-col cols="12" lg="5" class="px-lg-1 pt-md-3 pt-1">
                <v-btn
                    block
                    height="40"
                    :disabled="disabled"
                    class="star-xr-btn-orange"
                    @click="clickSignUp"
                >
                    {{ $t("signUp") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatePicker from "../com/DatePicker.vue";
import { signUpStep } from "@/commons/resources";

export default {
    name: "StarXrSignUpInfoName",
    components: {
        DatePicker,
    },
    data() {
        return {
            userNm: "",
            brDt: "",
            telNo: "",
            phoneNum1: "010",
            phoneNum2: "",
            phoneNum3: "",
            phoneNumList: ["010", "011", "016", "017", "019"],
            disabled: false,
        };
    },
    methods: {
        /**
         * @method clickBack
         * @note 이전 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickBack() {
            this.$emit("nextPage", signUpStep.InfoCompany);
            this.$store.commit("setUserPswd", "");
        },
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickSignUp() {
            this.telNo = this.phoneNum1 + this.phoneNum2 + this.phoneNum3;

            const userInfo = {
                userNm: this.userNm,
                brDt: this.brDt.replaceAll("-", ""),
                telNo: this.telNo,
            };

            this.$store.commit("setUserInfo", userInfo);
            this.$emit("clickSignUp");
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 40px;
}
</style>
