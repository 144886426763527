<!--
   [2024/08/26 sb.hwang]
   StarXrCommunityListItem.vue
   @note STAR-XR community list item 컴포넌트
-->
<template>
    <v-card
        tile
        elevation="0"
        class="list-item-wrap py-6"
        @click="clickItem"
        @keydown.enter.stop="clickItem"
    >
        <v-card-title class="pa-0 list-item-title">
            <div class="star-xr-h4 font-semi-bold">
                {{ item.postTtl }} 
            </div>
            <div class="star-xr-h6 list-item-right">
                <div>
                    {{ item.userNm+' ('+item.companyNm+')' }}
                </div>
                <div>|</div>
                <div>
                    {{ item.crtDt }}
                </div>
            </div>
        </v-card-title>
        <v-card-text class="pa-0 star-xr-h5 overflow-ellipsis">
            <div
                v-html="postCn"
                class="star-xr-h5 list-item-content"
            />
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: 'StarXrCommunityListItem',
    props: {
        item: {
            type: Object
        },
    },
    computed: {
        postCn() {
            return this.item.postCn.replaceAll('<p>', '').replaceAll('</p>', '')
        }
    },
    data() {
        return {
        }
    },
    methods: {
        /**
         * @method clickItem
         * @note 아이템을 클릭하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickItem() {
            this.$emit('clickItem', this.item);
        }
    }
}
</script>

<style scoped>
.list-item-wrap {
    border-bottom: 1px solid #F1F1F1;
}
.list-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
}

.list-item-right {
    display: flex;
    gap: 10px;
}

.list-item-content {
    color: #797979;
}
</style>
