<template>
    <div class="app">
    </div>
</template>
  
<script>
export default {
    name: 'KakaoPayEnd',
    created() {
        window.close();
    },
};
</script>
  
<style scoped>
.app {
    text-align: center;
    margin-top: 50px;
}
</style>
  