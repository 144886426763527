<!--
   [2024/02/13 gy.yang]
   StarXrFooter.vue
   @note STAR-XR 웹 페이지 푸터
-->
<template>
    <v-footer class="footer d-flex justify-center">
        <v-flex
            xs12
            lg11
        >
            <v-row
                class="pt-5"
            >
                <v-col
                    cols="auto"
                    class="px-xl-3 px-7"
                >
                    <v-img
                        :src="require('@/assets/images/star-xr-logo.svg')"
                        contain
                        height="38"
                    />
                </v-col>
                <v-col
                    align="end"
                    cols="auto"
                    xl="2"
                    class="pr-12 mt-lg-0 mt-2"
                >
                    <span class="footer-company">
                        {{ $t('company') }}
                    </span>
                </v-col>
                <v-col
                    cols="auto"
                    md="auto"
                    class="px-lg-0 px-7"
                >
                    <p class="footer-address">
                        {{ $t('address') }}<br/>
                        {{ $t('tel') }} | {{ $t('fax') }}
                    </p>
                    <p class="footer-copyright">Copylight ⓒ 2024 Naviworks Co.,Ltd. All rights reserved.</p>
                </v-col>
                <v-spacer/>
                <v-col
                    cols="12"
                    lg="auto"
                >
                    <ul>
                        <li><a href="https://naviworks.com/policy/privacy">{{ $t('privacy') }}</a></li>
                        <li><a href="https://naviworks.com/policy/terms">{{ $t('terms') }}</a></li>
                        <li><a href="https://naviworks.com/policy/email">{{ $t('permission') }}</a></li>
                    </ul>
                </v-col>
            </v-row>
        </v-flex>
    </v-footer>
</template>

<script>
export default {
    name: 'StarXrFooter'
}
</script>

<style scoped>
.footer {
    min-height: 225px;
    max-width: 100vw;
    font-size: 16px;
    line-height: 26px;
    background-color: #1E1E1E;
}

.footer-company{
    color: #D3D3D3;
}

.footer-address{
    color: #BFBFBF;
    font-weight: 300;
}

.footer-address span{
    font-weight: 500;
}

.footer-copyright{
    color: #555555;
    font-size: 14px;
    font-weight: 300;
}

ul {
    padding: 0;
    overflow: hidden;
}

ul li {
    padding: 0 15px;
    float: left;
    text-align: center;
    color: #D3D3D3;
    list-style: none;
    position: relative;
}

ul li::before {
    content: "";
    left: -1px;
    width: 1px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    background-color: #D3D3D3;
}

a {
    color: #D3D3D3 !important;
    text-decoration: none;
}

/* vuetify lg(<1920px) */
@media (max-width: 1919px) {
    .footer {
        min-height: 260px;
    }
}

/* vuetify md(<1280px) */
@media (max-width: 1279px) {
    .footer {
        min-height: 320px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .footer {
        min-height: 350px;
    }
}
</style>