import { menus } from './menus';

/**
 * @method windowScrollTop
 * @note 화면 스크롤을 최상단으로 이동시키는 함수
 * @email se.yoo@naviworks.com
 */
export const windowScrollTop = function() {
    window.scroll(0, 0);
};

/**
 * @method getCurrMenuTitle
 * @param {Object} to 이동하려는 페이지 정보 
 * @returns {String} 표시할 페이지 제목
 * @note router 정보를 받아 DOM 제목을 반환하는 함수
 * @email sb.hwang@naviworks.com
 */
export const getCurrMenuTitle = function (to) {
    let menuPages = getCurrMenuRoute(to);
    let title = 'STAR-XR';

    if(menuPages.length > 0) {
        title = `${menuPages.join(' > ')} | ` + title;
    }

    return title;
}

/**
 * @method getCurrMenuRoute
 * @param {Object} to 이동하려는 페이지 정보 
 * @returns {Array} 페이지 제목 배열
 * @note router 정보를 받아 페이지 제목 배열을 반환하는 함수
 * @email sb.hwang@naviworks.com
 */
export const getCurrMenuRoute = function (to) {
    const toPaths = to.path.split('/');
    const menuPaths = toPaths.splice(2) || [];
    let menuInfos = menus;
    let menuPages = [];

    menuPaths.forEach(path => {
        // 상세 > 숫자 메뉴
        if(!isNaN(+path)) return;

        if(
            menuInfos[path] == undefined ||
            path === 'service'
        ) {
            switch(path) { // 예외 메뉴들
                case 'login':
                    menuPages.push('로그인');
                    break;
                case 'signup':
                    menuPages.push('회원가입');
                    break;
                case 'search':
                    menuPages.push('비밀번호 찾기');
                    break;
                case 'mypage':
                    menuPages.push('마이페이지');
                    break;
            }
            return;
        }

        const nowMenu = menuInfos[path];

        if(nowMenu) {
            menuPages.push(nowMenu.name);
            menuInfos = nowMenu.subMenus;
        }

    });

    return menuPages;
}

/**
 * @method getPageLength
 * @param {number} listLength 아이템 목록 전체 개수
 * @param {number} itemsPerPage 페이지 별 아이템 전시 개수
 * @returns {number} 페이지 수
 * @note 몇 페이지가 필요한지 계산하여 반환하는 함수
 * @email sb.hwang@naviworks.com
 */
export const getPageLength = function(listLength, itemsPerPage) {
    return Math.ceil(Number(listLength) / Number(itemsPerPage));
};

/**
 * @method getPageList
 * @param {number} currentPage 현재 페이지
 * @param {array} items 아이템 목록
 * @param {number} itemsPerPage 페이지 별 아이템 전시 개수
 * @returns {number} 전시할 아이템
 * @note 페이지에 전시할 아이템을 반환하는 함수
 * @email sb.hwang@naviworks.com
 */
export const getPageList = function(currentPage, items, itemsPerPage) {
    let startIdx = (currentPage - 1) * itemsPerPage;
    let endIdx = 0;

    if((startIdx + itemsPerPage) > items.length){
        endIdx = items.length;
    }else{
        endIdx = startIdx + itemsPerPage;
    }

    let results = items.slice(startIdx, endIdx);
    
    return results;
};


/**
 * @method updateIndentStyle
 * @note vue editor indent 클래스에 style 주는 함수(padding left)
 * @email sb.hwang@naviworks.com
 */
export const updateIndentStyle = function() {
    document.querySelectorAll('[class*="ql-indent-"]').forEach(element => {
        const className = element.className;
        const match = className.match(/ql-indent-(\d+)/);
        
        if (match) {
            const indentLevel = match[1];
            element.style.paddingLeft = `${indentLevel * 3}rem`;
        }
    });
};