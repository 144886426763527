<!--
   [2024/08/27 sb.hwang]
   StarXrCommunityDetailAnswerInput.vue
   @note STAR-XR Community 게시글 상세 답변 입력 컴포넌트
-->
<template>
    <div>
        <div class="star-xr-h4 font-gray8 font-medium">{{ $t("reply") }}</div>
        <vue-editor
            v-model="answer"
            class="star-xr-text-editor mb-3"
            :placeholder="$t('writeContent')"
        ></vue-editor>
        <v-btn
            width="80"
            class="star-xr-btn-gray-outlined float-right"
            @click="addContent"
        >
            {{ $t("send") }}
        </v-btn>
        <alert-dialog
            v-model="show1Dialog"
            title="replyWriteFailure"
            content="loginRequiredMessage"
            @agree="$router.push('/login')"
        />
    </div>
</template>

<script>
import AlertDialog from "@/components/com/AlertDialog.vue";
import { VueEditor } from "vue2-editor";

export default {
    name: "StarXrCommunityDetailAnswerInput",
    components: {
        AlertDialog,
        VueEditor,
    },
    data() {
        return {
            answer: "",
            show1Dialog: false,
        };
    },
    computed: {
        item() {
            return this.$store.state.community;
        },
        token() {
            return this.$store.state.user.token;
        },
    },
    methods: {
        /**
         * @method addContent
         * @note 답변 작성 함수
         * @email gy.yang@naviworks.com
         */
        addContent() {
            if (this.token != "") {
                this.$emit("addContent", this.answer);
                this.answer = "";
            } else {
                this.show1Dialog = true;
            }
        },
    },
};
</script>

<style scoped>
.star-xr-text-editor {
    margin-top: 10px;
}
</style>
