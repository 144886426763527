import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import i18n from "./locales/i18n";
import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import Prism from "prismjs";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import hljs from "highlight.js";
import koKR from "@kangc/v-md-editor/lib/lang/ko-KR.js";
import "./plugins/serviceWorker";

VueMarkdownEditor.lang.use("ko-KR", koKR);

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});

Vue.use(VMdPreview);

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

Vue.use(VueMarkdownEditor);

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(VueCookies);

Vue.config.devtools = true;

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
