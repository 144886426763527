<!--
   [2024/08/21 gy.yang]
   StarXrToolsPaymentPlan.vue
   @note Tools 결제 플랜 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col
                v-for="(item, i) in plan"
                :key="`plan_${i}`"
                cols="12"
                sm="6"
                lg="3"
            >
                <div class="plan-item cursor-pointer" @click="clickItem">
                    <div class="star-xr-h0 font-semi-bold">
                        {{ $t(item.title) }}
                    </div>
                    <div class="star-xr-h4">{{ $t(item.price) }}</div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <div
                    class="plan-item subscribe-item cursor-pointer"
                    @click="clickItem"
                >
                    <v-img
                        :src="
                            require('@/assets/images/icon-arrow-right-btn.svg')
                        "
                        contain
                        height="30"
                    />
                    <div class="star-xr-h5 font-medium text-center mt-2">
                        {{ $t("subscribe") }}
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "StarXrToolsPaymentPlan",
    components: {},
    data() {
        return {
            plan: [
                { title: "pay7Day", price: "pay7DayContent" },
                { title: "pay60Day", price: "pay60DayContent" },
                { title: "pay90Day", price: "pay90DayContent" },
            ],
        };
    },
    methods: {
        /**
         * @method clickItem
         * @note 아이템 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickItem() {
            this.$emit("routerPayment");
        },
    },
};
</script>

<style scoped>
.plan-item {
    min-height: 190px;
    padding: 20px 24px;
    border-radius: 5px;
    background-color: #f6f6f6;
}

.subscribe-item {
    padding: 64px 24px !important;
}

.plan-item:hover {
    background-color: #f9f9f9;
}

.plan-item:active {
    background-color: #f1f1f1;
}
</style>
