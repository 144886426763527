<!--
   [2024/08/27 sb.hwang]
   StarXrAdminServiceDetail.vue
   @note STAR-XR 서비스 정보 화면
-->
<template>
    <v-container fluid class="admin-service-container">
        <div class="star-xr-h1 font-semi-bold mb-12">
            {{ $t("serviceInformation") }}
        </div>
        <star-xr-admin-service-detail-item :fileDown="fileDown" />
    </v-container>
</template>

<script>
import StarXrAdminServiceDetailItem from "@/components/account/service/StarXrAdminServiceDetailItem.vue";

export default {
    name: "StarXrAdminServiceDetail",
    components: {
        StarXrAdminServiceDetailItem,
    },
    computed: {
        srvcSn() {
            return this.$route.params.idx;
        },
        item() {
            return this.$store.state.service;
        },
    },
    data() {
        return {
            loaderUrl: "",
            dataUrl: "",
            frameworkUrl: "",
            codeUrl: "",
            fileDown: false,
        };
    },
    methods: {
        /**
         * @method selectService
         * @note unityContext를 지정해주는 함수
         * @email sb.hwang@naviworks.com
         */
        selectService() {
            this.$store
                .dispatch("selectAdminService", this.srvcSn)
                .then((res) => {
                    const data = res.srvcDetail;
                    this.$store.commit("setService", data);
                    this.$store.commit("setServiceWebglFileUrl", {});
                    this.setLoaderUrl();
                });
        },
        /**
         * @method selectFile
         * @note 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        setLoaderUrl() {
            const params = {
                uploadFileSn: this.item.webglLoaderUploadFileSn,
            };

            this.$store
                .dispatch("selectFile", params)
                .then((res) => {
                    this.loaderUrl = res.fileInfo.fileNm;
                    this.setDataUrl();
                })
                .catch(() => {});
        },
        /**
         * @method selectFile
         * @note 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        setDataUrl() {
            const params = {
                uploadFileSn: this.item.webglDataUploadFileSn,
            };

            this.$store
                .dispatch("selectFile", params)
                .then((res) => {
                    this.dataUrl = res.fileInfo.fileNm;
                    this.setFrameworkUrl();
                })
                .catch(() => {});
        },
        /**
         * @method selectFile
         * @note 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        setFrameworkUrl() {
            const params = {
                uploadFileSn: this.item.webglFrameworkUploadFileSn,
            };

            this.$store
                .dispatch("selectFile", params)
                .then((res) => {
                    this.frameworkUrl = res.fileInfo.fileNm;
                    this.setCodeUrl();
                })
                .catch(() => {});
        },
        /**
         * @method selectFile
         * @note 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        setCodeUrl() {
            const params = {
                uploadFileSn: this.item.webglWasmUploadFileSn,
            };

            this.$store
                .dispatch("selectFile", params)
                .then((res) => {
                    this.codeUrl = res.fileInfo.fileNm;

                    const fileUrl = {
                        webglLoaderUploadFileUrl: this.loaderUrl,
                        webglDataUploadFileUrl: this.dataUrl,
                        webglFrameworkUploadFileUrl: this.frameworkUrl,
                        webglWasmUploadFileUrl: this.codeUrl,
                    };

                    this.$store.commit("setServiceWebglFileUrl", fileUrl);
                    this.fileDown = true;
                })
                .catch(() => {});
        },
    },
    created() {
        this.selectService();
    },
};
</script>

<style scoped>
.admin-service-container {
    padding: 0;
}
.row {
    justify-content: space-between;
    align-items: flex-end;
}

@media (max-width: 1904px) {
    .admin-service-container {
        padding-right: 0;
    }
}
</style>
