<!--
   [2024/02/13 gy.yang]
   StarXrHomeUseCases.vue
   @note 홈 화면 적용사례 컴포넌트
-->
<template>
    <v-container fluid>
        <div class="setion-title">{{ $t('useCases') }}</div>
        <v-row>
            <v-col
                v-for="(example, i) in exampleList"
                :key="i"
                cols="12"
                md="4"
            >
                <v-img
                    :src="require(`@/assets/images/${example.value}.svg`)"
                    cover
                    height="235"
                    class="item cursor-pointer"
                    @click="clickItem(example)"
                >
                    <div class="img-title">
                        {{ $t(example.title) }}
                    </div>
                </v-img>
            </v-col>
        </v-row>
        <star-xr-home-use-cases-dialog
            v-model="showDialog"
            :example="seleceExample"
        />
    </v-container>
</template>

<script>
import StarXrHomeUseCasesDialog from './StarXrHomeUseCasesDialog.vue';

export default {
    name: 'StarXrHomeUseCases',
    components: {
        StarXrHomeUseCasesDialog
    },
    data() {
        return{
            exampleList: [
                { title: 'metaSeoul', value: 'meta-seoul', toKo: 'Sgwdsvcc_rM?si=wWKWttNhybCHDyXD', toEn: 'Y9U-k_SNdNA' },
                { title: 'metaPolice', value: 'meta-police', toKo: 'ylfLYQNwyMw', toEn: 'o4tP2DeEWKI' },
                { title: 'mediClub', value: 'medi-club', toKo: 'm4HeVwCkTc8', toEn: 'hBbE_o7_-NA' }
            ],
            showDialog: false,
            seleceExample: {}
        }
    },
    methods: {
        /**
         * @method clickItem
         * @param {Object} item 클릭한 아이템
         * @note 아이템 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickItem(item) {
            this.seleceExample = item;
            this.showDialog = true;
        }
    }
}
</script>

<style scoped>
.img-title {
    margin-top: 160px;
    color: #F6F6F6;
    font-size: 20px;
}
</style>