<!--
   [2024/08/27 sb.hwang]
   StarXrAdminService.vue
   @note STAR-XR 서비스 관리 화면
-->
<template>
    <v-container fluid class="admin-service-container">
        <div class="star-xr-h1 font-semi-bold mb-12">
            {{ $t("serviceManagement") }}
        </div>
        <v-row>
            <v-col cols="10" sm="6" lg="4" xl="3">
                <search-input @search="search" />
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="star-xr-btn-orange-outlined"
                    @click="clickAddItem"
                    >{{ $t("serviceRegistration") }}</v-btn
                >
            </v-col>
            <v-col cols="12" class="pt-0">
                <star-xr-admin-service-list :search="keyword" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrAdminServiceList from "@/components/account/service/StarXrAdminServiceList.vue";
import SearchInput from "@/components/com/SearchInput.vue";

export default {
    name: "StarXrAdminService",
    components: {
        StarXrAdminServiceList,
        SearchInput,
    },
    data() {
        return {
            editMode: false,
            keyword: "",
        };
    },
    methods: {
        /**
         * @method search
         * @param {String} search 검색어
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search(search) {
            this.keyword = search;
        },
        /**
         * @method clickAddItem
         * @note 서비스 등록으로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickAddItem() {
            this.$store.dispatch("resetService");
            this.$router.push({
                name: "starXrAdminServiceEdit",
                params: { idx: "new" },
            });
        },
    },
};
</script>

<style scoped>
.row {
    justify-content: space-between;
    align-items: flex-end;
}

.admin-service-container {
    padding: 0;
    padding-right: 195px;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}
</style>
