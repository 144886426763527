import { render, staticRenderFns } from "./StarXrCommunityDetailContent.vue?vue&type=template&id=64aa41dc&scoped=true&"
import script from "./StarXrCommunityDetailContent.vue?vue&type=script&lang=js&"
export * from "./StarXrCommunityDetailContent.vue?vue&type=script&lang=js&"
import style0 from "./StarXrCommunityDetailContent.vue?vue&type=style&index=0&id=64aa41dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64aa41dc",
  null
  
)

export default component.exports