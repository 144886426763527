<!--
   [2024/08/26 sb.hwang]
   StarXrCommunity.vue
   @note STAR-XR Community 화면
-->
<template>
    <v-container fluid class="view-container">
        <div class="star-xr-h1 font-semi-bold mb-9">{{ $t("community") }}</div>

        <v-row>
            <v-col cols="auto">
                <v-row class="star-xr-community-search-wrap">
                    <v-col class="pr-3">
                        <v-select
                            :items="sortList"
                            v-model="sort"
                            height="40"
                            hide-details
                            return-object
                            :placeholder="$t('order')"
                            class="star-xr-select mt-0 pt-0"
                        />
                    </v-col>
                    <v-col class="pl-sm-0" cols="12" sm="8">
                        <search-input @search="search" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    class="star-xr-btn-orange-outlined"
                    @click="clickAddItem"
                    >{{ $t("addPost") }}</v-btn
                >
            </v-col>
        </v-row>

        <star-xr-community-list
            id="list"
            :items="filterCommuitys"
            @clickItem="routerDetail"
        />
    </v-container>
</template>

<script>
import SearchInput from "@/components/com/SearchInput.vue";
import StarXrCommunityList from "@/components/community/StarXrCommunityList";

export default {
    name: "StarXrCommunity",
    components: {
        SearchInput,
        StarXrCommunityList,
    },
    computed: {
        token() {
            return this.$store.state.user.token;
        },
        sortList() {
            return [
                { text: this.$t("sortByNewest"), value: "asc" },
                { text: this.$t("sortByOldest"), value: "desc" },
            ];
        },
    },
    data() {
        return {
            sort: { text: "최신순", value: "asc" },
            items: [],
            filterCommuitys: [],
        };
    },
    methods: {
        /**
         * @method routerDetail
         * @note 게시글 상세로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        routerDetail(item) {
            this.$store.commit("setCommunitySn", item.communitySn);
            this.$router.push({
                name: "starXrCommunityDetail",
                params: { idx: item.communitySn },
            });
        },
        /**
         * @method clickAddItem
         * @note 게시글 추가로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickAddItem() {
            if (this.token) {
                this.$store.dispatch("resetCommunity");
                this.$router.push({
                    name: "starXrCommunityEdit",
                    params: { idx: "new" },
                });
            } else {
                this.$router.push({ name: "starXrLogin" });
            }
        },
        /**
         * @method search
         * @param {String} search 검색어
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search(search) {
            this.filterCommuitys = this.items.filter(
                (item) =>
                    item.postTtl.toLowerCase().includes(search.toLowerCase()) ||
                    item.postTtl.toUpperCase().includes(search.toUpperCase()) ||
                    item.postCn.toLowerCase().includes(search.toLowerCase()) ||
                    item.postCn.toUpperCase().includes(search.toUpperCase())
            );
        },
        /**
         * @method selectCommunityList
         * @note 게시글 목록을 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectCommunityList() {
            this.$store.dispatch("selectCommunityList").then((res) => {
                this.items = res.communityList;
                this.filterCommuitys = res.communityList;
                this.items.sort((a, b) => a.crtDt - b.crtDt);
            });
        },
    },
    created() {
        this.selectCommunityList();
    },
    watch: {
        sort(value) {
            if (value.value == "asc") {
                this.filterCommuitys.sort((a, b) => a.crtDt - b.crtDt);
            } else {
                this.filterCommuitys.sort((a, b) => b.crtDt - a.crtDt);
            }
        },
    },
};
</script>

<style scoped>
.row {
    justify-content: space-between;
    align-items: center;
}

.star-xr-community-search-wrap {
    align-items: center;
}

::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
}
</style>
