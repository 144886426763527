/**
 * [2024/09/12 sb.hwang]
 * adminUser.js
 * @note 사용자 관리 store
 */

import axios from '../axios';
import config from '../config';

const state =
{
    accountSn: 0,
    userNm: '',
    emlAddr: '',
    approvedSn: 0,
    companyNm: '',
    authrtSn: 0,
    brDt : '',
    telNo: '',
};
  
const actions=
{
    /**
    * @method selectUserLists
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 사용자 관리 목록을 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    selectUserLists() {
        return new Promise(function(resolve, reject)
        {
            axios
            .get(`${config.apiServerUrl}/accounts/usersList`)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method updateApproval
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 사용자 관리 목록을 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    updateApproval(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .put(`${config.apiServerUrl}/accounts/updateApproval`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
    /**
    * @method updateAuthrt
    * @param {object} context 컨텍스트 객체
    * @param {object} params 사용자 정보
    * @note 사용자 관리 목록을 조회하는 함수
    * @email sb.hwang@naviworks.com
    */
    updateAuthrt(_, params) {
        return new Promise(function(resolve, reject)
        {
            axios
            .put(`${config.apiServerUrl}/accounts/updateAuthrt`, params)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    },
};

const getters=
{
    
};

const mutations= 
{
    setAdminUser(state, adminUser) {
        state.accountSn = adminUser.accountSn || 0,
        state.approvedSn = adminUser.approvedSn || 0,
        state.authrtSn = adminUser.authrtSn || 0,
        state.userNm = adminUser.userNm || '',
        state.emlAddr = adminUser.emlAddr || '',
        state.companyNm = adminUser.companyNm || '',
        state.brDt = adminUser.brDt || '',
        state.telNo = adminUser.telNo || ''
    },
    setAdminUserAuthrtSn(state, authrtSn) {
        state.authrtSn = authrtSn
    },
};

export default
{
    state, actions, getters, mutations
};