<!--
   [2024/08/26 gy.yang]
   StarXrPaymentComplete.vue
   @note STAR-XR 결제 성공 화면
-->
<template>
    <v-container
        fluid
        class="full-height"
    >
        <v-row
            align="center"
            class="full-height ma-0"
        >
            <v-col
                align="center"
                cols="12"
            >
                <v-img
                    :src="require('@/assets/images/payment-complete.svg')"
                    contain
                    height="247"
                    class="mb-10"
                />
                <div class="star-xr-h4 text-center">
                    결제가 성공적으로 완료되었습니다.<br/>
                    결제 내역은 마이페이지에서 확인할 수 있습니다.
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrPaymentComplete',
    components: {
    },
    data() {
        return{
        }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>