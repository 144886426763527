<!--
   [2024/08/13 sb.hwang]
   StarXrMyPageInfoWrap.vue
   @note STAR-XR 마이페이지 info wrap 
-->
<template>
    <v-row>
        <v-col
            v-for="(info, index) in infoList"
            :key="index"
            class="px-md-0 px-6"
            cols="12"
            sm="6"
            lg="4"
        >
            <star-xr-my-page-info
                :label="info.label"
                :value="info.value"
                class="pa-2 pl-0"
            />
        </v-col>
    </v-row>
</template>

<script>
import StarXrMyPageInfo from "./StarXrMyPageInfo.vue";
export default {
    name: "StarXrMyPageInfoWrap",
    components: {
        StarXrMyPageInfo,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    data() {
        return {
            infoList: [],
        };
    },
    methods: {
        /**
         * @method processInfo
         * @param {object} context 컨텍스트 객체
         * @note infoList를 가공하는 함수
         * @email sb.hwang@naviworks.com
         */
        processInfo() {
            this.infoList.push(
                {
                    label: "registrationStatus",
                    value: this.user.approvedSn,
                },
                {
                    label: "email",
                    value: this.user.emlAddr,
                },
                {
                    label: "organization",
                    value: this.user.companyNm,
                },
                {
                    label: "name",
                    value: this.user.userNm,
                },
                {
                    label: "dateOfBirth",
                    value: this.user.brDt,
                },
                {
                    label: "phoneNumber",
                    value: this.convertNumber(this.user.telNo),
                },
                {
                    label: "nickname",
                    value: this.user.nickNm,
                }
            );
        },
        /**
         * @method convertNumber
         * @param {object} context 컨텍스트 객체
         * @note 전화번호 형식을 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        convertNumber(phone) {
            if (phone.length < 11) {
                return (
                    phone.substring(0, 3) +
                    "-" +
                    "***" +
                    "-" +
                    phone.substring(6)
                );
            } else {
                return (
                    phone.substring(0, 3) +
                    "-" +
                    "****" +
                    "-" +
                    phone.substring(7)
                );
            }
        },
        /**
         * @method getAccount
         * @param {object} context 컨텍스트 객체
         * @note 마이페이지 조회 함수
         * @email sb.hwang@naviworks.com
         */
        getAccount() {
            this.$store.dispatch("getAccount").then((res) => {
                this.$store.commit("setMypage", res.mypage);
                this.processInfo();
            });
        },
    },
    created() {
        this.getAccount();
    },
};
</script>

<style scoped>
.row {
    margin: 50px 0 150px 0;
}
</style>
