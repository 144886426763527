<!--
   [2024/08/22 sb.hwang]
   StarXrServiceDetailRec.vue
   @note STAR-XR service 상세 화면 추천 목록 컴포넌트
-->
<template>
    <div class="service-detail-rec-wrap">
        <star-xr-service-list-item
            v-for="(service, i) in services"
            :key="i"
            :service="service"
            :showTitleRight="false"
        />
    </div>
</template>

<script>
import StarXrServiceListItem from './StarXrServiceListItem.vue';

export default {
    name: 'StarXrServiceDetailRec',
    components: {
        StarXrServiceListItem
    },
    computed: {
        srvcSn() {
            return this.$route.params.idx;
        }
    },
    data() {
        return{
            services: [
                
            ],
        }
    },
    methods: {
        /**
         * @method selectAdminServiceList
         * @note 서비스 목록 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectAdminServiceList() {
            this.$store.dispatch('selectServiceList')
            .then((res) => {
                this.services = res.servicesList.filter(service => service.srvcSn != this.srvcSn);
            })
        }
    },
    watch: {
        srvcSn() {
            this.selectAdminServiceList();
        }
    },
    created() {
        this.selectAdminServiceList();
    }
}
</script>

<style scoped> 
.service-detail-rec-wrap {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 20px;
}

::v-deep .service-item {
    height: 160px;
}
</style>