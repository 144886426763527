import { render, staticRenderFns } from "./StarXrSignUpInfoEmail.vue?vue&type=template&id=da11f776&scoped=true&"
import script from "./StarXrSignUpInfoEmail.vue?vue&type=script&lang=js&"
export * from "./StarXrSignUpInfoEmail.vue?vue&type=script&lang=js&"
import style0 from "./StarXrSignUpInfoEmail.vue?vue&type=style&index=0&id=da11f776&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da11f776",
  null
  
)

export default component.exports