<!--
   [2024/08/20 sb.hwang]
   StarXrServiceList.vue
   @note STAR-XR service 화면 
-->
<template>
    <v-container fluid class="star-xr-service-container">
        <v-row>
            <v-col class="star-xr-h1 font-semi-bold" cols="auto">
                {{ $t("starXRService") }}
            </v-col>
            <v-col cols="12" sm="6" lg="4" xl="3">
                <search-input @search="search" />
            </v-col>
        </v-row>
        <v-divider class="font-gray2" />
        <star-xr-service-list-items
            v-for="(items, i) in serviceData"
            :key="i"
            :items="items"
            :search="keyword"
        />
    </v-container>
</template>

<script>
import StarXrServiceListItems from "@/components/service/StarXrServiceListItems.vue";
import SearchInput from "@/components/com/SearchInput.vue";

export default {
    name: "StarXrServiceList",
    components: {
        StarXrServiceListItems,
        SearchInput,
    },
    data() {
        return {
            serviceData: [
                {
                    srvcCtgrySn: 1,
                    services: [],
                },
                {
                    srvcCtgrySn: 2,
                    services: [],
                },
                {
                    srvcCtgrySn: 3,
                    services: [],
                },
                {
                    srvcCtgrySn: 4,
                    services: [],
                },
            ],
            keyword: "",
        };
    },
    methods: {
        /**
         * @method search
         * @param {String} search 검색어
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search(search) {
            this.keyword = search;
        },
        /**
         * @method selectAdminServiceList
         * @note 서비스 목록 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectAdminServiceList() {
            this.$store.dispatch("selectServiceList").then((res) => {
                res.servicesList.forEach((service) => {
                    this.serviceData[service.srvcCtgrySn - 1].services.push({
                        ...service,
                    });
                });
            });
        },
    },
    created() {
        this.selectAdminServiceList();
    },
};
</script>

<style scoped>
.row {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

::v-deep .v-text-field {
    margin-top: 0;
    padding-top: 0;
}
</style>
