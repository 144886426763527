<!--
   [2024/08/29 sb.hwang]
   StarXrDocsMenuContentMarkDown.vue
   @note STAR-XR docs 메뉴 내용 마크다운 컴포넌트
-->
<template>
    <v-container
        fluid
        class="pa-0"
    >
        <div 
            v-html="renderedContent"
            class="star-xr-docs star-xr-docs-menu-content-mark-down"
        />
    </v-container>
</template>

<script>
import { marked } from 'marked';
import { gfmHeadingId } from "marked-gfm-heading-id";

export default {
    name: 'StarXrDocsMenuContentMarkDown',
    props: {
        edit: {
            type: Boolean
        }
    },
    components: {
    },
    computed: {
        routerNm() {
            return this.$route.path.split('/')[1];
        },
        docs() {
            return this.$store.state.docs;
        },
        text() {
            return this.routerNm === 'api' ? this.docs.apiText : this.docs.documentText;
        },
        renderedContent() {
            const options = {
                prefix: ''
            }
            marked.use(gfmHeadingId(options));
            return marked(this.text);
        },        
    },
    data() {
        return{
            menus: {}
        }
    },
    methods: {      
        /**
         * @method generateTableOfContents
         * @param {String} markdownContent 마크다운내용
         * @returns {String} 클래스 명
         * @note 마크다운에서 메뉴 추출하는 함수
         * @email sb.hwang@naviworks.com
         */  
        generateTableOfContents(markdownContent) {
            const tocItems = [];
            const tokens = marked.lexer(markdownContent);
            
            marked.walkTokens(tokens, (token) => {
                if(token.type  === 'heading' && token.depth < 4) {
                    tocItems.push({
                        text: token.text,
                        level: token.depth
                    })
                }
            });

            this.processMenus(tocItems);
        }, 
        /**
         * @method processMenus
         * @param {String} tocs 추출된 메뉴
         * @returns {String} 클래스 명
         * @note 추출된 메뉴를 가공하는 함수
         * @email sb.hwang@naviworks.com
         */  
        processMenus(tocs) {
            let index = -1;
            let subIndex = -1;

            tocs.map((item) => {
                if(item.level === 1) {
                    index++;
                    subIndex = -1;
                    this.menus[index] = {
                        name: item.text,
                        to: item.text.replaceAll(' ', '-'),
                        subMenus: {}
                    }
                } else if(item.level === 2) {
                    subIndex++;
                    this.menus[index].subMenus[subIndex] = {
                        name: item.text,
                        to: item.text.replaceAll(' ', '-'),
                        subsubMenus: {}
                    };
                } else if(item.level === 3) {
                    this.menus[index].subMenus[subIndex].subsubMenus[item.text] = {
                        name: item.text,
                        to: item.text.replaceAll(' ', '-')
                    };

                }
            })
            this.$store.commit('setDocMenus', this.menus);
        }
    },
    watch: {
        edit() {
            this.generateTableOfContents(this.text);
        },
        routerNm() {
            this.generateTableOfContents(this.text);
        }
    }
}
</script>

<style scoped>
@import url('@/assets/css/docs-style.css');

/* vuetify lg(<1904px) */
@media (max-width: 1904px) {
    .star-xr-document-container {
        padding: 0;
        padding-bottom: 20px
    }
}
</style>