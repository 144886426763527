/**
 * [2024/08/29 sb.hwang]
 * docMenus.js
 * @note doc 메뉴 store
 */


const state =
{
    menus: {},
    text: '',
    level: 0,
};
  
const actions=
{
};

const getters=
{
    
};

const mutations= 
{
    setDocMenus(state, menus) {
        state.menus = menus || {};
    },
    setDocMenusApi(state, docMenus) {
        state.text = docMenus.text || '';
        state.level = docMenus.level || 0;
    },
};

export default
{
    state, actions, getters, mutations
};