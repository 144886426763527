<!--
   [2024/08/26 gy.yang]
   StarXrPaymentFail.vue
   @note STAR-XR 결제 실패 화면
-->
<template>
    <v-container
        fluid
        class="full-height"
    >
        <v-row
            align="center"
            class="full-height ma-0"
        >
            <v-col
                align="center"
                cols="12"
            >
                <v-img
                    :src="require('@/assets/images/payment-fail.svg')"
                    contain
                    height="247"
                    class="mb-10"
                />
                <div class="star-xr-h4 text-center">
                    결제 중 오류가 발생했습니다.<br/>
                    결제 수단 확인 후 재시도해 주시길 바랍니다.
                </div>
                <v-btn
                    width="320"
                    height="50"
                    class="star-xr-btn-orange mt-10"
                    @click="routerPayment"
                >
                    결제하기
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrPaymentFail',
    components: {
    },
    data() {
        return{
        }
    },
    methods: {
        /**
         * @method routerPayment
         * @note 결제 화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
         routerPayment() {
            this.$router.push({
                name: 'starXrPayment'
            }).catch(()=>{});
        }
    }
}
</script>

<style scoped>
</style>