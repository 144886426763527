<!--
   [2024/08/14 sb.hwang]
   StarXrMyPageInfoEditWrap.vue
   @note STAR-XR 마이페이지 info edit wrap 
-->
<template>
    <v-row>
        <v-col
            v-for="(info, index) in infoList"
            :key="index"
            class="px-md-0 px-6"
            cols="12"
            sm="6"
            lg="4"
        >
            <star-xr-my-page-info-edit
                :label="info.label"
                :type="info.type"
                class="pa-2 pl-0"
            />
        </v-col>
    </v-row>
</template>

<script>
import StarXrMyPageInfoEdit from "./StarXrMyPageInfoEdit.vue";

export default {
    name: "StarXrMyPageInfoEditWrap",
    components: {
        StarXrMyPageInfoEdit,
    },
    data() {
        return {
            infoList: [],
        };
    },
    methods: {
        /**
         * @method processInfo
         * @param {object} context 컨텍스트 객체
         * @note infoList를 가공하는 함수
         * @email sb.hwang@naviworks.com
         */
        processInfo() {
            this.infoList.push(
                {
                    label: "email",
                    type: "emlAddr",
                },
                {
                    label: "organization",
                    type: "companyNm",
                },
                {
                    label: "nickname",
                    type: "nickNm",
                },
                {
                    label: "name",
                    type: "userNm",
                },
                {
                    label: "dateOfBirth",
                    type: "date",
                },
                {
                    label: "phoneNumber",
                    type: "phone",
                },
                {
                    label: "password",
                    type: "pwd",
                }
            );
        },
    },
    created() {
        this.processInfo();
    },
};
</script>

<style scoped>
.row {
    margin: 50px 0 150px 0;
}
.readonly::v-deep .v-input__slot input {
    color: #707070;
}
</style>
