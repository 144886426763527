<!--
   [2024/08/26 gy.yang]
   StarXrPaymentHistoryInfo.vue
   @note 결제 내역 정보 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" md="6">
                <div class="px-8">
                    <div class="star-xr-h5 font-gray6 mb-2">
                        {{ $t("orderInformation") }}
                    </div>
                    <div class="info-text font-medium mb-5">
                        {{ payment.paymentTtl }}
                    </div>
                    <div class="star-xr-h5 font-gray7">
                        {{ payment.expiredDt }} {{ $t("expiration") }}
                    </div>
                </div>
                <v-divider class="mt-8 mb-12" />
                <div class="px-8">
                    <div class="star-xr-h5 font-gray6 mb-2">
                        {{ $t("paymentAmount") }}
                    </div>
                    <div class="info-text font-medium mb-5">
                        {{ payment.price }}￦
                    </div>
                    <div class="star-xr-h5 font-gray7">
                        {{ payment.paymentDt }} {{ $t("payment") }}
                    </div>
                </div>
                <v-divider class="mt-8" />
            </v-col>
            <v-col cols="12" md="6">
                <div class="px-8">
                    <div class="star-xr-h5 font-gray6 mb-2">
                        {{ $t("orderer") }}
                    </div>
                    <div class="info-text font-medium">
                        {{ payment.userNm }}
                    </div>
                    <div class="star-xr-h5 font-gray7">
                        {{ payment.telNo }}<br />
                        {{ payment.emlAddr }}
                    </div>
                </div>
                <v-divider class="mt-7 mb-12" />
                <div class="px-8">
                    <div class="star-xr-h5 font-gray6 mb-2">
                        {{ $t("paymentMethod") }}
                    </div>
                    <div class="info-text font-medium pb-3">
                        {{ payment.paymentMtd }}
                    </div>
                </div>
                <v-divider class="mt-16" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "StarXrPaymentHistoryInfo",
    components: {},
    computed: {
        paymentIdx() {
            return this.$route.params.idx;
        },
        payment() {
            return this.$store.state.payment;
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method getPayment
         * @note 결제 상세 조회하는 함수
         * @email gy.yang@naviworks.com
         */
        getPayment() {
            this.$store
                .dispatch("getPaymentDetail", this.paymentIdx)
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getPayment();
    },
};
</script>

<style scoped>
.info-text {
    font-size: 20px;
}
</style>
