/**
 * [2024/09/05 sb.hwang]
 * config.js
 * @note 서버 연결 설정
 */
// API 서버 주소
const apiServerUrl = 'https://starxr.co.kr/starxr';

// 파일 서버 주소
const storageServerUrl = 'https://starxr.co.kr/starxr/upload/';

module.exports = {
    apiServerUrl,
    storageServerUrl
};
