<!--
   [2024/08/12 sb.hwang]
   StarXrAccountLayout.vue
   @note STAR-XR 마이페이지 레이아웃
-->
<template>
    <v-container 
        fluid
        class="star-xr-account-layout"
    >
        <v-layout
            row
            justify-center
        >
            <v-flex
                class="star-xr-account-layout-wrap d-flex"
            >
                <star-xr-account-nav 
                    v-if="$vuetify.breakpoint.mdAndUp"
                />
                <v-container
                    fluid
                    class="star-xr-account-layout-container"
                >
                    <router-view />
                </v-container>
            </v-flex>
        </v-layout>    
    </v-container>
</template>

<script>
import StarXrAccountNav from './StarXrAccountNav.vue';

export default {
    name: 'StarXrAccountLayout',
    components: {
        StarXrAccountNav
    }
}
</script>

<style scoped >
.star-xr-account-layout {
    min-height: calc(100vh - 140px);
}

.star-xr-account-layout-wrap {
    background: #ffffff;
    min-height: calc(100vh - 140px);
}

.star-xr-account-layout-container {
    position: relative;
    padding: 120px 150px;
}

@media (max-width: 1904px) {
    .star-xr-account-layout-container {
        padding: 120px 100px;
    }
}

@media (max-width: 1264px) {
    .star-xr-account-layout-container {
        padding: 40px;
    }
}
</style>