<!--
   [2024/08/26 gy.yang]
   StarXrPaymentHistoryCharge.vue
   @note 결제 내역 취소 수수료 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="chargeInfo"
            hide-default-footer
            :no-data-text="$t('noData')"
            class="star-xr-data-table read-only"
        >
            <template v-slot:body="{ items }">
                <tbody v-if="!$vuetify.breakpoint.xs">
                    <tr v-for="(item, i) in items" :key="i">
                        <td class="font-light text-center">{{ item.date }}</td>
                        <td
                            class="font-light text-center"
                            v-html="item.charge"
                        />
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr
                        v-for="(item, i) in items"
                        :key="i"
                        class="v-data-table__empty-wrapper"
                    >
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">
                                {{ $t("cancellationDate") }}
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell font-light"
                            >
                                {{ item.date }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">
                                {{ $t("cancellationFee") }}
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell font-light"
                                v-html="item.charge"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "StarXrPaymentHistoryCharge",
    setup() {},
    data() {
        return {
            headers: [
                {
                    text: this.$t("cancellationDate"),
                    value: "date",
                    align: "center",
                    width: "50%",
                    sortable: false,
                },
                {
                    text: this.$t("cancellationFee"),
                    value: "charge",
                    align: "center",
                    width: "50%",
                    sortable: false,
                },
            ],
            chargeInfo: [
                {
                    date: this.$t("unusedSameDay"),
                    charge: `<span class="font-orange">${this.$t(
                        "none"
                    )}</span>`,
                },
                {
                    date: this.$t("afterTwoToTenDays"),
                    charge: this.$t("paymentAmountPercentage", {
                        amount: "<span class='font-orange'>20%</span>",
                    }),
                },
                {
                    date: this.$t("afterTenToTwentyDays"),
                    charge: this.$t("paymentAmountPercentage", {
                        amount: "<span class='font-orange'>30%</span>",
                    }),
                },
                {
                    date: this.$t("afterTwentyToThirtyDays"),
                    charge: this.$t("paymentAmountPercentage", {
                        amount: "<span class='font-orange'>50%</span>",
                    }),
                },
                {
                    date: this.$t("afterThirtyDays"),
                    charge: `<span class="font-orange">${this.$t(
                        "cancellationNotAllowed"
                    )}</span>`,
                },
            ],
        };
    },
    methods: {},
};
</script>

<style scoped>
.star-xr-data-table::v-deep .v-data-table-header th span {
    color: #202020;
    font-family: Pretendard-Medium, "Pretendard-Medium" !important;
}

.star-xr-data-table::v-deep .v-data-table-header th,
.star-xr-data-table::v-deep tbody td {
    color: #202020;
    font-size: 14px !important;
    border-bottom: 1px solid #e2e2e2 !important;
}

.star-xr-data-table::v-deep .v-data-table-header th:not(:last-child),
.star-xr-data-table::v-deep tbody td:not(:last-child) {
    border-right: 1px solid #e2e2e2 !important;
}

.star-xr-data-table .item-bg-gray {
    color: #202020;
    background-color: #fafafa !important;
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .star-xr-data-table::v-deep .v-data-table__mobile-row__header {
        min-width: 100px;
        text-align: start;
        font-family: Pretendard-Light, "Pretendard-Light" !important;
    }

    .star-xr-data-table::v-deep .v-data-table-header th,
    .star-xr-data-table::v-deep tbody td,
    .star-xr-data-table::v-deep .v-data-table-header th:not(:last-child),
    .star-xr-data-table::v-deep tbody td:not(:last-child) {
        border: none !important;
    }

    .star-xr-data-table::v-deep .v-data-table__mobile-row {
        border-bottom: 1px solid #e2e2e2 !important;
    }
}
</style>
