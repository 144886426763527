<!--
   [2024/08/27 sb.hwang]
   StarXrBadge.vue
   @note 배지 컴포넌트
-->
<template>
    <div class="badge-wrap">
        <div class="star-xr-badge font-orange font-medium">
            {{ text }}
            <v-icon
                v-if="isDelete"
                size="16"
                class="font-orange cursor-pointer"
                @click="$emit('deleteTag')"
            >
                mdi-close
            </v-icon>
        </div>
    </div>
</template>

<script>

export default {
    name: 'StarXrBadge',
    props: {
        text: {
            type: String,
            default: '배지'
        },
        isDelete: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return{
        }
    },
}
</script>

<style scoped>
.badge-wrap {
    display: flex;
    align-items: center;
}
.star-xr-badge {
    padding: 2px 12px;
    border-radius: 16px;
    background-color: #FFF4ED;
}
</style>