<!--
   [2024/08/27 sb.hwang]
   ImgBtn.vue
   @note 이미지 버튼 컴포넌트
-->
<template>
    <v-btn
        icon 
        :class="[btnStyle, background ? 'star-xr-img-btn-background' : 'star-xr-img-btn']"
        @click="$emit('clickBtn')"
    >
        <v-img
            :src="require(`@/assets/images/${icon}.svg`)"
            contain
            height="22"
        />
    </v-btn>
</template>

<script>

export default {
    name: 'ImgBtn',
    props: {
        icon: {
            type: String
        },
        btnStyle: {
            type: String,
            default: '',
        },
        background: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return{
        }
    },
}
</script>

<style scoped>
.star-xr-img-btn {
    border: 1px solid #E2E2E2;
    border-radius: 5px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.star-xr-img-btn-background {
    border-radius: 5px !important;
    background-color: #F3F3F3 !important;
    box-shadow: none !important;
}
</style>