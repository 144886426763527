<!--
   [2024/08/22 sb.hwang]
   StarXrServiceListItem.vue
   @note STAR-XR service list 아이템 컴포넌트
-->
<template>
    <div
        class="service-item-wrap cursor-pointer"
        @click="clickToDetail(service)"
    >
        <div class="service-item" @mouseover="mouseover" @mouseout="mouseout">
            <img v-if="!hover" :src="thumbnail" class="service-item-thumbl" />
            <video
                v-else
                :src="video"
                loop
                muted
                autoplay
                playsinline
                class="service-item-thumbl"
            />
        </div>
        <div class="service-item-title">
            <div class="font-gray8 star-xr-h5 overflow-ellipsis">
                {{ service.srvcTtl }}
            </div>
            <div v-if="showTitleRight" class="service-item-title-right">
                <div>
                    <v-icon small class="font-gray5 mr-1">mdi-eye</v-icon>
                    <span class="font-gray6">{{ service.viewsCnt }}</span>
                </div>
                <div>
                    <v-icon small class="font-gray5 mr-1">mdi-thumb-up</v-icon>
                    <span class="font-gray6">{{ service.likesCnt }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storageServerUrl } from "@/config";

export default {
    name: "StarXrServiceListItem",
    props: {
        service: Object,
        showTitleRight: {
            type: Boolean,
            default: true,
        },
    },
    components: {},
    data() {
        return {
            thumbnail: "",
            video: "",
            hover: false,
        };
    },
    methods: {
        /**
         * @method clickToDetail
         * @note 서비스 상세로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDetail(service) {
            this.$store.commit("setService", service);
            this.$router.push({
                name: "starXrServiceDetail",
                params: { idx: service.srvcSn },
            });
        },
        /**
         * @method mouseover
         * @note hover상태를 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        mouseover() {
            this.hover = true;
        },
        /**
         * @method mouseout
         * @note hover상태를 변경하는 함수
         * @email sb.hwang@naviworks.com
         */
        mouseout() {
            this.hover = false;
        },
        /**
         * @method setThumbnail
         * @note 썸네일 파일 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        setThumbnail() {
            const params = {
                uploadFileSn: this.service.thbnilUploadFileSn,
            };

            this.$store.dispatch("selectFile", params).then((res) => {
                this.thumbnail = storageServerUrl + res.fileInfo.fileNm;
            });
        },
        /**
         * @method setVideo
         * @note 비디오 파일 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        setVideo() {
            const params = {
                uploadFileSn: this.service.videoUploadFileSn,
            };
            if (params.uploadFileSn != 0) {
                this.$store.dispatch("selectFile", params).then((res) => {
                    this.video = storageServerUrl + res.fileInfo.fileNm;
                });
            }
        },
    },
    watch: {
        service() {
            this.setThumbnail();
            this.setVideo();
        },
    },
    created() {
        this.setThumbnail();
        this.setVideo();
    },
};
</script>

<style scoped>
.service-item-wrap {
    min-width: 295px;
    flex-basis: calc((100% - 90px) / 4);
}

.service-item {
    min-width: 295px;
    min-height: 190px;
    height: 220px;
    background-color: #888888;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.service-item-thumbl {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.service-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-item-title-right {
    display: flex;
    gap: 13px;
}
</style>
