<!--
   [2024/08/26 gy.yang]
   StarXrPayment.vue
   @note STAR-XR 결제 화면
-->
<template>
    <v-container
        fluid
        class="view-container"
    >
        <div class="star-xr-h1 font-semi-bold mb-5">결제하기</div>
        <v-row class="px-lg-8">
            <v-col
                cols="12"
                lg="7"
                class="payment-content mt-15"
            >
                <star-xr-payment-info/>
            </v-col>
            <v-col
                cols="12"
                lg="5"
                class="payment-content mt-15"
            >
                <star-xr-payment-method/>
            </v-col>
        </v-row>
        <v-row class="pd-8 pb-16">
            <v-col
                cols="12"
                lg="7"
                class="pl-lg-8"
            >
                <v-divider class="mt-2 mb-5"/>
                <div class="star-xr-h6 font-gray6">*결제 당일 취소(미사용)가 아닌 경우 결제 취소 시 취소 수수료가 발생하며, 사용 기한에 따라 취소가 불가할 수 있습니다.</div>
            </v-col>
            <v-col
                cols="12"
                lg="5"
                class="pr-8"
            >
                <v-divider
                    v-if="$vuetify.breakpoint.lgAndUp"
                    class="mt-2 mb-5"
                />
                <div class="px-sm-8">
                    <v-btn
                        height="50"
                        block
                        class="star-xr-btn-orange"
                        @click="clickPayment"
                    >
                        결제하기
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrPaymentInfo from '@/components/payment/StarXrPaymentInfo.vue';
import StarXrPaymentMethod from '@/components/payment/StarXrPaymentMethod.vue';

export default {
    name: 'StarXrPayment',
    components: {
        StarXrPaymentInfo,
        StarXrPaymentMethod
    },
    data() {
        return{
        }
    },
    methods: {
        /**
         * @method clickPayment
         * @note 결제하기 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickPayment() {
            this.$router.push({
                name: 'starXrPaymentComplete'
            }).catch(()=>{});
        }
    }
}
</script>

<style scoped>
.payment-content::v-deep .info-text {
    font-size: 20px;
}
</style>