<!--
   [2024/08/27 gy.yang]
   StarXrDocument.vue
   @note STAR-XR Document 화면
-->
<template>
    <v-container fluid class="star-xr-document-container">
        <star-xr-docs-menu-content @select="selectDocument" />
    </v-container>
</template>

<script>
import StarXrDocsMenuContent from "@/components/docs/StarXrDocsMenuContent.vue";
import { langCd } from "@/commons/resources";

export default {
    name: "StarXrDocument",
    components: {
        StarXrDocsMenuContent,
    },
    computed: {
        langCd() {
            return langCd[this.$store.state.user.translation];
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method selectDocument
         * @param {Objace} item 선택한 아이템
         * @note document 내용 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectDocument() {
            this.$store
                .dispatch("selectDocument", { langCd: this.langCd })
                .then((res) => {
                    this.$store.commit("setDocumentText", res);
                })
                .catch(() => {});
        },
    },
    watch: {
        langCd() {
            this.selectDocument();
        },
    },
    created() {
        this.selectDocument();
    },
};
</script>

<style scoped>
.star-xr-document-container {
    height: 100%;
}

/* vuetify lg(<1904px) */
@media (max-width: 1904px) {
    .star-xr-document-container {
        padding: 0;
        padding-bottom: 20px;
    }
}
</style>
