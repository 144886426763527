<!--
   [2024/08/26 gy.yang]
   StarXrPaymentMethod.vue
   @note 결제 방식 컴포넌트
-->
<template>
    <v-container
        fluid
        class="pa-0"
    >
        <div>
            <div class="star-xr-h5 font-gray6 mb-2">결제 금액</div>
            <div class="star-xr-h2 font-bold font-orange mb-8">90일 이용권</div>
            <div class="star-xr-h5 font-gray6 mb-2">결제 방법</div>
            <div>
                <v-radio-group 
                    v-model="paymentMethod"
                    row
                    mandatory
                    hide-details
                    class="star-xr-radio-group ma-0 mb-2"
                >
                    <v-radio
                        label="신용카드"
                        :value="1"
                        class="mr-5"
                    />
                    <v-radio
                        label="네이버페이"
                        :value="2"
                        class="mr-5"
                    />
                    <v-radio
                        label="카카오페이"
                        :value="3"
                    />
                </v-radio-group>
                <v-select
                    v-if="paymentMethod == 1"
                    :items="cardList"
                    height="40"
                    hide-details
                    placeholder="카드 선택"
                    class="star-xr-select mt-5 pt-0"
                />
                <div
                    v-else
                    class="star-xr-h7 font-gray6 font-light pb-4"
                >
                    {{ paymentMethod == 2
                        ? '*네이버페이는 네이버 ID로 별도 앱 설치 없이 결제 정보를 등록하여 네이버페이 비밀번호로 결제할 수 있는 간편 결제 서비스입니다.'
                        : '*카카오페이는 카카오톡 안에서 개인 신용카드(체크카드포함)를 등록하여 카카오페이 비밀번호로 결제할 수 있는 간편 결제 서비스입니다.'
                    }}
                </div>
            </div>
            <v-row
                align="center"
                class="mt-15"
            >
                <v-col cols="auto">
                    <div class="star-xr-h6 font-light">구매 조건을 확인했으며 결제 약관에 동의합니다.</div>
                </v-col>
                <v-spacer/>
                <v-col cols="auto">
                    <v-btn
                        text
                        class="star-xr-h7 font-gray7 font-light"
                    >
                        자세히
                        <v-img
                            :src="require('@/assets/images/icon-arrow-right.svg')"
                            contain
                            height="16"
                            class="icon-img ml-1"
                        />
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'StarXrPaymentMethod',
    components: {
    },
    data() {
        return{
            paymentMethod: 1,
            cardList: ['우리카드', '농협카드', '신한카드', '국민카드']
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.icon-img {
    display: inline-block;
    vertical-align: middle;
}
</style>