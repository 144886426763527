<!--
   [2024/08/13 sb.hwang]
   StarXrMyPageInfo.vue
   @note STAR-XR 마이페이지 info 
-->
<template>
    <div class="my-page-info">
        <div class="my-page-info-label star-xr-h6 font-gray7">
            {{ $t(label) }}
        </div>
        <v-text-field
            readonly
            class="star-xr-input"
            :class="statusClass()"
            hide-details
            v-model="valueToStatus"
        />
    </div>
</template>

<script>
import { accessStatus } from "@/commons/resources.js";

export default {
    name: "StarXrMyPageInfo",
    props: {
        label: String,
        value: [String, Number],
    },
    components: {},
    computed: {
        valueToStatus() {
            return typeof this.value === "number"
                ? this.$t(this.accessStatus[this.value])
                : this.value;
        },
    },
    data() {
        return {
            accessStatus,
        };
    },
    methods: {
        /**
         * @method statusClass
         * @note 상태에 따라 클래스 부여하는 함수
         * @email sb.hwang@naviworks.com
         */
        statusClass() {
            return this.value === 1
                ? "font-green"
                : this.value === 2
                ? "font-red"
                : "";
        },
    },
};
</script>

<style scoped>
.my-page-info {
    min-width: 250px;
    max-width: 400px;
}

.star-xr-input::v-deep .v-input__slot input {
    color: #707070;
}
.font-green::v-deep .v-input__slot input {
    color: #34af4f;
}
.font-red::v-deep .v-input__slot input {
    color: #e40404;
}
</style>
