<!--
   [2024/08/20 sb.hwang]
   StarXrServiceLayout.vue
   @note STAR-XR 서비스 레이아웃
-->
<template>
    <v-container fluid class="service-content">
        <v-layout row justify-center>
            <v-flex class="service-content-wrap d-flex">
                <div
                    v-if="$route.name === 'starXrServiceList'"
                    class="service-content-banner"
                >
                    <video
                        :src="require('@/assets/images/banner_starxr.mp4')"
                        width="100%"
                        autoplay
                        loop
                    ></video>
                    <div class="service-content-text-container">
                        <div class="font-semi-bold star-xr-h1 font-white">
                            STAR XR
                        </div>
                        <div
                            class="service-content-desc font-white"
                            v-html="$t('starXRDescription')"
                        ></div>
                        <v-btn
                            width="120"
                            height="40"
                            class="service-content-btn star-xr-btn-orange"
                            @click="clickToPlay"
                        >
                            Play Now
                        </v-btn>
                    </div>
                </div>
                <v-container fluid class="service-content-container">
                    <router-view />
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "StarXrDocsLayout",
    components: {},
    methods: {
        /**
         * @method clickToPlay
         * @note tools로 이동하는 함수(서비스 play로 넘어갈 예정)
         * @email sb.hwang@naviworks.com
         */
        clickToPlay() {
            this.$router.push({
                name: "starXrTools",
            });
        },
    },
};
</script>

<style>
.service-content {
    min-height: calc(100vh - 140px);
}

.service-content-wrap {
    background: #ffffff;
    min-height: calc(100vh - 140px);
    flex-direction: column;
}

.content {
    position: absolute;
}

.service-content-banner {
    margin: 0 auto 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}

.service-content-banner video {
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.service-content-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.47);
    padding: 122px 325px;
}

.service-content-desc {
    margin-top: 20px;
}
.service-content-btn {
    margin-top: 80px;
}

.service-content-container {
    position: relative;
    padding: 122px 325px;
}

@media (max-width: 1904px) {
    .service-content-container,
    .service-content-text-container {
        padding: 100px;
    }
}

@media (max-width: 1264px) {
    .service-content-container,
    .service-content-text-container {
        padding: 40px;
    }

    .service-content-desc,
    .service-content-btn {
        margin-top: 10px;
    }
}

@media (max-width: 959px) {
    .service-content-text-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .service-content-desc {
        display: none;
    }
    .service-content-btn {
        margin-top: 0;
    }
}
</style>
