<!--
   [2024/02/13 gy.yang]
   StarXrHomeValue.vue
   @note 홈 화면 가치 컴포넌트
-->
<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col
                cols="11"
                md="9"
            >
                <div class="setion-title">{{ $t('value') }}</div>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            class="worth-box"
        >
            <v-col
                cols="11"
                md="9"
            >
                <v-row>
                    <v-col
                        v-for="(worth, i) in worthList"
                        :key="i"
                        cols="12"
                        md="6"
                        xl="3"
                    >
                        <div class="item py-8">
                            <v-img
                                :src="require(`@/assets/images/icon-${worth.value}.svg`)"
                                cover
                                width="30"
                                height="30"
                                class="mb-3"
                            />
                            <p class="item-title mb-2">
                                {{ $t(worth.title) }}
                            </p>
                            <p
                                class="item-sub-title"
                                v-html="$t(worth.sub)"
                            />
                            <span class="item-tag">{{ worth.tag }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrHomeValue',
    data() {
        return{
            worthList: [
                { title: 'create', sub: 'createContent', tag: '#Create', value: 'create' },
                { title: 'smart', sub: 'smartContent', tag: '#Smart', value: 'smart' },
                { title: 'city', sub: 'cityContent', tag: '#Real_City', value: 'city' },
                { title: 'togeter', sub: 'togeterContent', tag: '#Together', value: 'together' }
            ]
        }
    }
}
</script>

<style scoped>
.worth-box {
    background-color: #F8F8F8;
}

.item-title {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.item-tag {
    padding: 0 5px;
    color: #B4B4B4;
    font-size: 16px;
    background-color: #FFFFFF;
}
</style>