<template>
    <div class="app">
        <h1>테스트</h1>
            <p>
                금액
                <input
                    type="number"
                    class="amount"
                    v-model.number="amount"
                    placeholder="충전할 금액을 입력하세요"
                />
            </p>
        <button @click="handleChargeBtn">카카오페이</button>
    </div>
</template>
  
<script>
import axios from 'axios';
  
export default {
    name: 'KakaoPay',
    data() {
        return {
            amount: 0, // 금액을 숫자로 초기화
            nickname: 'nickname', // 필요에 따라 동적으로 변경 가능
            redirectUrl: 'http://localhost:8080/kakaoPay', // 필요에 따라 업데이트
        };
    },
    methods: {
        handleChargeBtn() {
            const pgMethod = 'kakaopay'; // 필요에 따라 동적으로 변경 가능
            this.clickChargeBtn(pgMethod, this.amount, this.nickname, this.redirectUrl);
        },
        clickChargeBtn(pg_method, amount, nickname, redirect_url) {
            const { IMP } = window;
            if (!IMP) {
            alert('Iamport SDK가 로드되지 않았습니다.');
            return;
            }
    
            IMP.init('imp20772331'); // 가맹점 번호 지정
    
            IMP.request_pay(
                {
                    pg: pg_method, // 결제 방식 지정
                    pay_method: 'card',
                    merchant_uid: `mid${new Date().getTime()}`, // 현재 시간으로 고유 ID 생성
                    name: '결제 품목 및 제목 지정',
                    amount: amount, // 충전할 금액
                    buyer_email: '구매자 이메일', // 오타 수정: buyer_emil -> buyer_email
                    buyer_name: nickname,
                    buyer_tel: '010-4242-4242',
                    buyer_addr: '서울특별시 강남구 삼성동',
                    buyer_postcode: '123-456',
                    m_redirect_url: redirect_url,
                },
                (rsp) => {
                    if (rsp.success) {
                    axios
                        .get(`https://starxr.co.kr/starxr/payment/verify/${rsp.imp_uid}`)
                        .then((response) => {
                        console.log(response);
                        alert('결제 성공');
                        })
                        .catch((error) => {
                        console.error(error);
                        alert('결제 검증 중 오류가 발생했습니다.');
                        });
                    console.log(rsp);
                    } else {
                    alert('결제 실패');
                    console.log(rsp);
                    }
                }
            );
        },
    },
  };
  </script>
  
  <style scoped>
  .app {
    text-align: center;
    margin-top: 50px;
  }
  
  .amount {
    width: 200px;
    padding: 5px;
    margin-left: 10px;
  }
  
  button {
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  </style>
  