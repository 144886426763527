<!--
   [2024/08/22 sb.hwang]
   StarXrServiceDetail.vue
   @note STAR-XR service 상세 화면 
-->
<template>
    <v-container fluid class="star-xr-service-detail-container">
        <v-row>
            <v-col cols="12" lg="9">
                <star-xr-service-detail-webgl
                    :key="srvcSn"
                    :fileDown="fileDown"
                />
            </v-col>
            <v-col cols="12" lg="3">
                <star-xr-service-detail-rec />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrServiceDetailWebgl from "@/components/service/StarXrServiceDetailWebgl.vue";
import StarXrServiceDetailRec from "@/components/service/StarXrServiceDetailRec.vue";

export default {
    name: "StarXrServiceDetail",
    components: {
        StarXrServiceDetailWebgl,
        StarXrServiceDetailRec,
    },
    data() {
        return {
            webglUploadFileUrl: "",
            fileDown: false,
        };
    },
    computed: {
        srvcSn() {
            return Number(this.$route.params.idx);
        },
        service() {
            return this.$store.state.service;
        },
    },
    methods: {
        /**
         * @method setViewCnt
         * @note 뷰 카운트 함수
         * @email sb.hwang@naviworks.com
         */
        setViewCnt() {
            const params = {
                srvcSn: this.srvcSn,
            };

            this.$store
                .dispatch("postServiceViews", params)
                .then(() => {
                    this.selectService();
                    this.getServiceLikes();
                })
                .catch(() => {});
        },
        /**
         * @method getServiceLikes
         * @note 서비스 좋아요 수 불러오는 함수
         * @email sb.hwang@naviworks.com
         */
        getServiceLikes() {
            const params = {
                srvcSn: this.srvcSn,
            };
            this.$store
                .dispatch("getServiceLikes", params)
                .then(() => {})
                .catch(() => {});
        },
        /**
         * @method selectService
         * @note unityContext를 지정해주는 함수
         * @email sb.hwang@naviworks.com
         */
        selectService() {
            this.$store
                .dispatch("selectAdminService", this.srvcSn)
                .then((res) => {
                    const data = res.srvcDetail;
                    this.$store.commit("setService", data);
                    this.$store.commit("setServiceWebglFileUrl", {});
                    this.setWebglUrl();
                });
        },
        /**
         * @method setWebglUrl
         * @note 파일 조회 함수
         * @email sb.hwang@naviworks.com
         */
        setWebglUrl() {
            const params = {
                uploadFileSn: this.service.webglUploadFileSn,
            };

            this.$store
                .dispatch("selectFile", params)
                .then((res) => {
                    this.webglUploadFileUrl = res.fileInfo.fileNm;

                    const fileUrl = {
                        webglUploadFileUrl: this.webglUploadFileUrl,
                    };

                    this.$store.commit("setServiceWebglFileUrl", fileUrl);
                    this.fileDown = true;
                })
                .catch(() => {});
        },
    },
    created() {
        this.setViewCnt();
        this.$store.commit("setServiceSn", this.srvcSn);
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.idx !== from.params.idx) {
            this.fileDown = false;
            this.setViewCnt();
            this.$store.commit("setServiceSn", this.srvcSn);
        }
        next();
    },
};
</script>

<style scoped>
.star-xr-service-detail-container {
    padding: 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
</style>
