<!--
   [2024/08/20 gy.yang]
   SearchInput.vue
   @note 검색 인풋 컴포넌트
-->
<template>
    <v-text-field
        v-model="value"
        :placeholder="$t(placeholder)"
        append-icon="mdi-magnify"
        hide-details
        spellcheck="false"
        class="search-input"
        @click:append-outer="search"
        @keyup.enter="search"
    >
    </v-text-field>
</template>

<script>
export default {
    name: "SearchInput",
    props: {
        placeholder: {
            type: String,
            default: "enterSearchTerm",
        },
        resetYn: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            value: "",
        };
    },
    methods: {
        /**
         * @method search
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search() {
            this.$emit("search", this.value);
        },
        /**
         * @method resetValue
         * @note 검색어를 초기화 시키는 함수
         * @email gy.yang@naviworks.com
         */
        resetValue() {
            this.value = "";
        },
    },
};
</script>
