import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = document.cookie.match('(^|;) ?' + "user_token" + '=([^;]*)(;|$)'); 

    if(token) {
        config.headers['Authorization'] = 'Bearer ' + token[2];
    }
    
    return config;
})

export default axios;