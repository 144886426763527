<!--
   [2024/08/28 gy.yang]
   StarXrDocsMenuContent.vue
   @note STAR-XR docs 메뉴 내용 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <div class="d-flex justify-space-between align-center mb-12">
            <div class="star-xr-h1 font-semi-bold">
                {{ $t(routerNm.toLowerCase(), { mode: $t(editMode) }) }}
            </div>
            <div v-if="user.authrtSn == 2">
                <v-btn
                    v-if="!edit"
                    width="100"
                    height="38"
                    class="star-xr-btn-gray-outlined"
                    @click="clickToEdit"
                >
                    {{ $t("edit") }}
                </v-btn>
                <div v-else>
                    <v-btn
                        width="100"
                        height="38"
                        class="star-xr-btn-gray-outlined mr-3"
                        @click="clickToCancel"
                    >
                        {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                        width="100"
                        height="38"
                        class="star-xr-btn-orange"
                        @click="clickToSave"
                    >
                        {{ $t("save") }}
                    </v-btn>
                </div>
            </div>
        </div>
        <star-xr-docs-menu-content-mark-down-edit v-if="edit" />
        <star-xr-docs-menu-content-mark-down v-else />
        <alert-dialog
            v-model="openDialog"
            width="360"
            btn-width="140"
            :title="dialogTitle"
            :content="dialogContent"
            agree="exitWithoutSaving"
            disagree="save"
            agree-style="star-xr-btn-gray-outlined"
            disagree-style="star-xr-btn-orange"
            @agree="clickToMarkDown"
            @disagree="clickToSave"
        />
    </v-container>
</template>

<script>
import StarXrDocsMenuContentMarkDown from "./StarXrDocsMenuContentMarkDown.vue";
import StarXrDocsMenuContentMarkDownEdit from "./StarXrDocsMenuContentMarkDownEdit.vue";
import AlertDialog from "@/components/com/AlertDialog.vue";
import { documentMenus } from "@/commons/menus";
import { langCd } from "@/commons/resources";
import { marked } from "marked";

export default {
    name: "StarXrDocsMenuContent",
    props: {},
    components: {
        StarXrDocsMenuContentMarkDown,
        StarXrDocsMenuContentMarkDownEdit,
        AlertDialog,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        routerNm() {
            return this.$route.path.split("/")[1].toUpperCase();
        },
        editMode() {
            return this.edit ? "edit" : "";
        },
        path() {
            return this.$route.fullPath.split("#")[1];
        },
        docs() {
            return this.$store.state.docs;
        },
        text: {
            get() {
                return this.routerNm === "API"
                    ? this.docs.apiText
                    : this.docs.documentText;
            },
            set(value) {
                let commitNm =
                    this.routerNm === "API" ? "setApiText" : "setDocumentText";
                this.$store.commit(commitNm, value);
            },
        },
        langCd() {
            return langCd[this.$store.state.user.translation];
        },
    },
    data() {
        return {
            rendering: false,
            edit: false,
            documentMenus,
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            menus: {},
        };
    },
    methods: {
        /**
         * @method clickToEdit
         * @note 편집 모드 변경 함수
         * @email gy.yang@naviworks.com
         */
        clickToEdit() {
            this.edit = true;
        },
        /**
         * @method scrollMenu
         * @param {String} idNm 스크롤할 아이디 명
         * @note 메뉴에 따른 스크롤 함수
         * @email sb.hwang@naviworks.com
         */
        scrollMenu(idNm) {
            const indexTop = document.getElementById(idNm).offsetTop;

            window.scrollTo({
                top: indexTop,
            });
        },
        /**
         * @method clickToCancel
         * @note 편집을 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToCancel() {
            this.openDialog = true;
            this.dialogTitle = "unsavedDocument";
            this.dialogContent = "unsavedContentMessage";
        },
        /**
         * @method clickToMarkDown
         * @note 저장하지 않고 나가는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToMarkDown() {
            this.edit = false;
            this.$emit("select");
        },
        /**
         * @method clickToSave
         * @note 편집한 내용을 저장하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToSave() {
            const actionNm =
                this.routerNm === "API" ? "updateApi" : "updateDocument";
            const paramsNm = this.routerNm === "API" ? "apiCn" : "docCn";

            const params = {
                [paramsNm]: this.text,
                langCd: this.langCd,
            };
            this.$store.dispatch(actionNm, params).then(() => {
                this.edit = false;
                this.$emit("select");
            });
        },
        /**
         * @method selectDocs
         * @param {Objace} item 선택한 아이템
         * @note docs 내용 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectDocs() {
            const actionNm =
                this.routerNm === "API" ? "selectApi" : "selectDocument";
            this.$store
                .dispatch(actionNm, { langCd: this.langCd })
                .then((res) => {
                    this.text = res;
                    this.generateTableOfContents(this.text);
                })
                .catch(() => {});
        },
        /**
         * @method generateTableOfContents
         * @param {String} markdownContent 마크다운내용
         * @returns {String} 클래스 명
         * @note 마크다운에서 메뉴 추출하는 함수
         * @email sb.hwang@naviworks.com
         */
        generateTableOfContents(markdownContent) {
            const tocItems = [];
            const tokens = marked.lexer(markdownContent);

            marked.walkTokens(tokens, (token) => {
                if (token.type === "heading" && token.depth < 4) {
                    tocItems.push({
                        text: token.text,
                        level: token.depth,
                    });
                }
            });

            this.processMenus(tocItems);
        },
        /**
         * @method processMenus
         * @param {String} tocs 추출된 메뉴
         * @returns {String} 클래스 명
         * @note 추출된 메뉴를 가공하는 함수
         * @email sb.hwang@naviworks.com
         */
        processMenus(tocs) {
            let index = -1;
            let subIndex = -1;

            tocs.map((item) => {
                if (item.level === 1) {
                    index++;
                    subIndex = -1;
                    this.menus[index] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                        subMenus: {},
                    };
                } else if (item.level === 2) {
                    subIndex++;
                    this.menus[index].subMenus[subIndex] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                        subsubMenus: {},
                    };
                } else if (item.level === 3) {
                    this.menus[index].subMenus[subIndex].subsubMenus[
                        item.text
                    ] = {
                        name: item.text,
                        to: item.text.replaceAll(" ", "-"),
                    };
                }
            });

            this.$store.commit("setDocMenus", this.menus);
        },
    },
    watch: {
        path(value) {
            if (document.getElementById(`${value}`)) {
                this.scrollMenu(value);
            }
        },
    },
    created() {
        this.selectDocs();
    },
};
</script>

<style scoped>
.star-xr-document-edit-btn {
    float: right;
    margin: 10px 0px;
}

@media (max-width: 1904px) {
    .star-xr-document-container {
        padding: 0;
        padding-bottom: 20px;
    }
}
</style>
