<!--
   [2024/08/20 gy.yang]
   StarXrSignUpInfoPw.vue
   @note 회원가입 비밀번호 입력 컴포넌트
-->
<template>
    <v-container fluid class="mt-15">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("password") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                    <v-text-field
                        v-model="pswd"
                        :type="showPw ? 'password' : 'text'"
                        class="star-xr-input pt-0"
                        :placeholder="$t('enterPassword')"
                    >
                        <template v-slot:append>
                            <v-btn icon @click="showPw = !showPw">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-eyes-${showPw}.svg`)
                                    "
                                    contain
                                    height="16"
                                />
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="font-gray7 px-md-0">
                    {{ $t("confirmPassword") }}
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                    <v-text-field
                        v-model="pswdCof"
                        :type="showPwCof ? 'password' : 'text'"
                        class="star-xr-input pt-0"
                        :placeholder="$t('reenterPassword')"
                    >
                        <template v-slot:append>
                            <v-btn icon @click="showPwCof = !showPwCof">
                                <v-img
                                    :src="
                                        require(`@/assets/images/icon-eyes-${showPwCof}.svg`)
                                    "
                                    contain
                                    height="16"
                                />
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <v-row align="center" justify="center" class="btn-box">
            <v-col cols="12" lg="5" class="px-lg-1 pb-md-3 pb-1">
                <v-btn
                    block
                    height="40"
                    class="star-xr-btn-orange-outlined"
                    @click="clickBack"
                >
                    {{ $t("previous") }}
                </v-btn>
            </v-col>
            <v-col cols="12" lg="5" class="px-lg-1 pt-md-3 pt-1">
                <v-btn
                    block
                    height="40"
                    :disabled="disabled"
                    class="star-xr-btn-orange"
                    @click="clickNext"
                >
                    {{ $t("next") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { signUpStep } from "@/commons/resources";

export default {
    name: "StarXrSignUpInfoPw",
    data() {
        return {
            pswd: "",
            pswdCof: "",
            showPw: true,
            showPwCof: true,
            disabled: true,
        };
    },
    methods: {
        /**
         * @method clickBack
         * @note 이전 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickBack() {
            this.$emit("nextPage", signUpStep.InfoEmail);
            this.$store.commit("setUserEmlAddr", "");
        },
        /**
         * @method clickNext
         * @note 다음 버튼 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickNext() {
            this.$emit("nextPage", signUpStep.InfoCompany);
            this.$store.commit("setUserPswd", this.pswd);
        },
    },
    watch: {
        pswdCof() {
            const validate = this.$refs.form.validate();

            if (validate && this.pswd === this.pswdCof) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    margin-top: 130px;
}
</style>
