/**
 * [2024/08/22 sb.hwang]
 * service.js
 * @note 서비스 store
 */

import axios from "../axios";
import config from "../config";

const state = {
    srvcSn: 0,
    srvcCtgrySn: 0,
    srvcTtl: "",
    companyNm: "",
    thumbl: "",
    thbnilUploadFileSn: "",
    stateSn: 0,
    uploadDt: "",
    videoUploadFileSn: "",
    srvcCn: "",
    likesCnt: 0,
    viewsCnt: 0,
    script: "",
    webglUploadFileSn: "",
    webglUploadFileUrl: "",
    loaderUrl: "",
    dataUrl: "",
    frameworkUrl: "",
    codeUrl: "",
    webGl: "",
    tag: [],
    chatServiceYn: 0,
    streamingAssetsBlobs: {},
};

const actions = {
    /**
     * @method resetService
     * @param {object} context 컨텍스트 객체
     * @note 사용자 state를 초기화 하는 함수
     * @email sb.hwang@naviworks.com
     */
    resetService(context) {
        const emptyContent = {};

        context.commit("setService", emptyContent);
    },
    /**
     * @method selectAdminServiceList
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스 관리 목록을 조회하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectAdminServiceList() {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/service`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method selectAdminService
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스 관리 상세를 조회하는 함수
     * @email sb.hwang@naviworks.com
     */
    selectAdminService(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/service/${params}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method postAdminService
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스를 등록하는 함수
     * @email sb.hwang@naviworks.com
     */
    postAdminService(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(`${config.apiServerUrl}/service`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updateAdminService
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스를 수정하는 함수
     * @email sb.hwang@naviworks.com
     */
    updateAdminService(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/service`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method updateServiceState
     * @param {object} context 컨텍스트 객체
     * @param {object} params 서비스 정보
     * @note 서비스 상태 변경하는 함수
     * @email gy.yang@naviworks.com
     */
    updateServiceState(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .put(`${config.apiServerUrl}/service/updateState`, params)
                .then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method deleteAdminService
     * @param {object} context 컨텍스트 객체
     * @param {number} srvcSn 서비스 일련번호
     * @note 서비스를 삭제하는 함수
     * @email sb.hwang@naviworks.com
     */
    deleteAdminService(_, srvcSn) {
        return new Promise(function (resolve, reject) {
            axios
                .delete(`${config.apiServerUrl}/service?srvcSn=${srvcSn}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method selectServiceList
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스 목록을 조회하는 함수
     * @email gy.yang@naviworks.com
     */
    selectServiceList() {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/service/list?searchValue=`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method getServiceLikes
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스에 좋아요 클릭 여부를 확인하는 함수
     * @email sb.hwang@naviworks.com
     */
    getServiceLikes(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .get(`${config.apiServerUrl}/service/likesYn`, { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method postServiceLikes
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스에 좋아요 누르는 함수
     * @email sb.hwang@naviworks.com
     */
    postServiceLikes(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(`${config.apiServerUrl}/service/likesCnt`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    /**
     * @method postServiceViews
     * @param {object} context 컨텍스트 객체
     * @param {object} params 사용자 정보
     * @note 서비스 뷰 카운트 함수
     * @email sb.hwang@naviworks.com
     */
    postServiceViews(_, params) {
        return new Promise(function (resolve, reject) {
            axios
                .post(`${config.apiServerUrl}/service/viewsCnt`, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const getters = {
    getServiceWebglFileUrl: function (state) {
        return {
            webglUploadFileUrl: state.webglUploadFileUrl,
        };
    },
};

const mutations = {
    setService(state, service) {
        (state.srvcCtgrySn = service.srvcCtgrySn || 0),
            (state.thumbl = service.thumbl || ""),
            (state.thbnilUploadFileSn = service.thbnilUploadFileSn || ""),
            (state.videoUploadFileSn = service.videoUploadFileSn || ""),
            (state.srvcTtl = service.srvcTtl || ""),
            (state.srvcCn = service.srvcCn || ""),
            (state.companyNm = service.companyNm || ""),
            (state.uploadDt = service.uploadDt || ""),
            (state.likesCnt = service.likesCnt || 0),
            (state.viewsCnt = service.viewsCnt || 0),
            (state.script = service.script || ""),
            (state.webglUploadFileSn = service.webglUploadFileSn || ""),
            (state.loaderUrl = service.loaderUrl || ""),
            (state.dataUrl = service.dataUrl || ""),
            (state.frameworkUrl = service.frameworkUrl || ""),
            (state.codeUrl = service.codeUrl || ""),
            (state.tag = service.tag || []),
            (state.chatServiceYn = service.chatServiceYn || 0);
    },
    setServiceSn(state, srvcSn) {
        state.srvcSn = srvcSn;
    },
    setServiceTitle(state, srvcTtl) {
        state.srvcTtl = srvcTtl;
    },
    setServiceCompany(state, companyNm) {
        state.companyNm = companyNm;
    },
    setServiceDesc(state, srvcCn) {
        state.srvcCn = srvcCn;
    },
    setServiceVideoUploadFileSn(state, videoUploadFileSn) {
        state.videoUploadFileSn = videoUploadFileSn;
    },
    setServiceThbnilUploadFileSn(state, thbnilUploadFileSn) {
        state.thbnilUploadFileSn = thbnilUploadFileSn;
    },
    setServiceCategory(state, srvcCtgrySn) {
        state.srvcCtgrySn = srvcCtgrySn;
    },
    setServiceWebGl(state, webGl) {
        state.webGl = webGl;
    },
    setServiceChatServiceYn(state, chatServiceYn) {
        state.chatServiceYn = chatServiceYn || 0;
    },
    setServiceTags(state, tag) {
        state.tag = tag;
    },
    setServiceLikesCnt(state, likesCnt) {
        state.likesCnt = likesCnt;
    },
    setServiceWebglUploadFileSn(state, webglUploadFileSn) {
        state.webglUploadFileSn = webglUploadFileSn;
    },
    setServiceWebglFileUrl(state, file) {
        state.webglUploadFileUrl = file.webglUploadFileUrl || "";
    },
    setServiceWebglUploadFileUrl(state, file) {
        state.webglUploadFileUrl = file || "";
    },
    setStreamingAssetsBlobs(state, streamingAssetsBlobs) {
        state.streamingAssetsBlobs = streamingAssetsBlobs || {};
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
};
