<!--
   [2024/09/12 gy.yang]
   StarXrMyPageChangePwDialog.vue
   @note 마이페이지 비밀번호 변경 다이얼로그 컴포넌트
-->
<template>
    <v-dialog v-model="dialog" maxWidth="427" persistent class="">
        <v-card class="py-20 px-30">
            <v-card-title>
                <v-row>
                    <v-col cols="auto" class="star-xr-h4 font-semi-bold">
                        {{ $t("changePassword") }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="px-30">
                <v-form ref="form">
                    <v-row justify="center">
                        <v-col cols="11">
                            <v-row align="center">
                                <v-col cols="3" class="font-gray6 px-0 pb-0">
                                    {{ $t("password") }}
                                </v-col>
                                <v-col cols="9" class="pa-0">
                                    <v-text-field
                                        v-model="pswd"
                                        :type="showPw ? 'password' : 'text'"
                                        class="star-xr-input pt-0"
                                        :placeholder="$t('enterPassword')"
                                        hide-details
                                    >
                                        <template v-slot:append>
                                            <v-btn
                                                icon
                                                @click="showPw = !showPw"
                                            >
                                                <v-img
                                                    :src="
                                                        require(`@/assets/images/icon-eyes-${showPw}.svg`)
                                                    "
                                                    contain
                                                    height="16"
                                                />
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col class="pa-0 star-xr-h7" cols="12">
                                    <div
                                        class="text-right font-red"
                                        v-if="!isMatch"
                                    >
                                        {{ $t("passwordMismatch") }}
                                    </div>
                                    <div v-else>&nbsp;</div>
                                </v-col>
                                <v-col cols="3" class="font-gray6 px-0 pb-0">
                                    {{ $t("newPassword") }}
                                </v-col>
                                <v-col cols="9" class="px-0">
                                    <v-text-field
                                        v-model="newPswd"
                                        :type="showNewPw ? 'password' : 'text'"
                                        class="star-xr-input"
                                        hide-details
                                        :placeholder="$t('enterNewPassword')"
                                    >
                                        <template v-slot:append>
                                            <v-btn
                                                icon
                                                @click="showNewPw = !showNewPw"
                                            >
                                                <v-img
                                                    :src="
                                                        require(`@/assets/images/icon-eyes-${showNewPw}.svg`)
                                                    "
                                                    contain
                                                    height="16"
                                                />
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3" class="font-gray6 px-0 pb-0">
                                    {{ $t("confirmPassword") }}
                                </v-col>
                                <v-col cols="9" class="px-0">
                                    <v-text-field
                                        v-model="pswdCof"
                                        :type="showPwCof ? 'password' : 'text'"
                                        class="star-xr-input"
                                        hide-details
                                        :placeholder="$t('reenterPassword')"
                                    >
                                        <template v-slot:append>
                                            <v-btn
                                                icon
                                                @click="showPwCof = !showPwCof"
                                            >
                                                <v-img
                                                    :src="
                                                        require(`@/assets/images/icon-eyes-${showPwCof}.svg`)
                                                    "
                                                    contain
                                                    height="16"
                                                />
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row justify="center" class="mt-8">
                    <v-col cols="12" sm="6" lg="3" class="pr-sm-1 mr-5">
                        <v-btn
                            block
                            height="40"
                            class="star-xr-btn-gray-outlined"
                            @click="dialog = false"
                        >
                            {{ $t("cancel") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3" class="pt-sm-3 pt-0 pl-sm-1">
                        <v-btn
                            block
                            height="40"
                            :disabled="disabled"
                            class="star-xr-btn-orange"
                            @click="updatePswd"
                        >
                            {{ $t("change") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "StarXrMyPageChangePwDialog",
    props: {
        value: {
            type: Boolean,
        },
    },
    computed: {
        dialog: {
            get() {
                if (this.value === true) {
                    this.resetPswd();
                }

                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    data() {
        return {
            pswd: "",
            newPswd: "",
            pswdCof: "",
            showPw: true,
            showNewPw: true,
            showPwCof: true,
            isMatch: true,
            disabled: true,
        };
    },
    methods: {
        /**
         * @method resetPswd
         * @note 데이터 리셋하는 함수
         * @email sb.hwang@naviworks.com
         */
        resetPswd() {
            this.pswd = "";
            this.newPswd = "";
            this.pswdCof = "";
        },
        /**
         * @method checkOldPswd
         * @note 기존 비밀번호 확인 함수
         * @email sb.hwang@naviworks.com
         */
        checkOldPswd(value) {
            if (value) {
                const params = {
                    emlAddr: this.$store.state.user.emlAddr,
                    pswd: this.pswd,
                };
                this.$store
                    .dispatch("checkOldPswd", params)
                    .then((res) => {
                        if (res.checkOldPswd === "다시 입력 부탁드립니다.") {
                            this.isMatch = false;
                        } else {
                            this.isMatch = true;
                        }
                    })
                    .catch(() => {});
            } else {
                this.isMatched = false;
            }
        },
        /**
         * @method updatePswd
         * @note 비밀번호 변경 함수
         * @email sb.hwang@naviworks.com
         */
        updatePswd() {
            const params = {
                emlAddr: this.$store.state.user.emlAddr,
                pswd: this.newPswd,
            };
            this.$store
                .dispatch("updatePswd", params)
                .then(() => {
                    this.dialog = false;
                    this.$emit("completeEdit");
                })
                .catch(() => {});
        },
        /**
         * @method checkValidate
         * @note 유효성 검사 함수
         * @email sb.hwang@naviworks.com
         */
        checkValidate() {
            return (
                this.pswd !== "" &&
                this.isMatch &&
                this.newPswd !== "" &&
                this.newPswd === this.pswdCof
            );
        },
    },
    watch: {
        pswd(value) {
            this.checkOldPswd(value);
        },
        newPswd() {
            if (this.checkValidate()) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        pswdCof() {
            if (this.checkValidate()) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
    },
};
</script>

<style scoped>
.info-box {
    min-height: 120px;
    border-radius: 5px;
    background-color: #fafafa;
}
</style>
