<!--
   [2024/08/27 sb.hwang]
   StarXrCommunityDetailAnswer.vue
   @note STAR-XR Community 게시글 상세 답변 컴포넌트
-->
<template>
    <div class="star-xr-community-detail-answer-wrap">
        <div v-if="answer.accountSn == user.accountSn || user.authrtSn == 2">
            <v-menu bottom offset-y v-if="!editMode">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="star-xr-community-detail-answer-mode"
                    >
                        <v-icon size="22"> mdi-dots-horizontal </v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="clickToEdit">
                        <v-img
                            :src="require(`@/assets/images/icon-edit.svg`)"
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-gray8 font-medium">
                            {{ $t("edit") }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickToDelete">
                        <v-img
                            :src="
                                require(`@/assets/images/icon-delete-outline.svg`)
                            "
                            contain
                            width="16"
                            height="16"
                        />
                        <v-list-item-title class="font-red font-medium">
                            {{ $t("edit") }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div v-else class="star-xr-community-detail-answer-mode">
                <v-btn
                    class="star-xr-btn-gray-outlined font-semi-bold"
                    width="50px"
                    height="26px"
                    min-width="auto"
                    @click="clickToCancel"
                >
                    {{ $t("cancel") }}
                </v-btn>
                <v-btn
                    class="star-xr-btn-orange font-semi-bold"
                    width="50px"
                    height="26px"
                    min-width="auto"
                    @click="clickToSave"
                >
                    {{ $t("save") }}
                </v-btn>
            </div>
        </div>

        <div class="star-xr-community-detail-title">
            <div class="star-xr-h5 font-semi-bold">
                {{ answer.userNm }}
            </div>
            <div class="star-xr-h6 font-light font-gray7">
                {{ answer.responseDt }}
            </div>
            <div v-if="editMode" class="star-xr-h6 font-light font-orange">
                {{ $t("editing") }}
            </div>
        </div>
        <div
            v-if="!editMode"
            class="star-xr-community-detail-answer-content star-xr-h6"
            v-html="answer.responseCn"
        ></div>
        <vue-editor
            v-else
            v-model="content"
            class="star-xr-text-editor mb-3"
            :placeholder="$t('writeContent')"
        ></vue-editor>

        <alert-dialog
            v-model="openDialog"
            :title="dialogTitle"
            :content="dialogContent"
            title-style="font-red"
            agree-style="star-xr-btn-red-outlined"
            @agree="agreeToDelete"
        />
    </div>
</template>

<script>
import AlertDialog from "@/components/com/AlertDialog.vue";
import { VueEditor } from "vue2-editor";

export default {
    name: "StarXrCommunityDetailAnswer",
    components: {
        AlertDialog,
        VueEditor,
    },
    props: {
        answer: {
            type: Object,
        },
    },
    data() {
        return {
            openDialog: false,
            dialogTitle: "",
            dialogContent: "",
            editMode: false,
            content: "",
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        item() {
            return this.$store.state.community;
        },
    },
    methods: {
        /**
         * @method clickToEdit
         * @note 댓글 편집모드로 전환하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToEdit() {
            this.editMode = !this.editMode;
        },
        /**
         * @method clickToCancel
         * @note 댓글 편집을 취소하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToCancel() {
            this.editMode = !this.editMode;
        },
        /**
         * @method clickToSave
         * @note 댓글 편집을 저장하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToSave() {
            this.editMode = !this.editMode;
            this.$emit("editContent", this.content, this.answer.responseSn);
        },
        /**
         * @method clickToDelete
         * @note 댓글 삭제alert 띄우는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDelete() {
            this.openDialog = true;
            this.dialogTitle = "deleteReply";
            this.dialogContent = "deleteReplyConfirmation";
        },
        /**
         * @method agreeToDelete
         * @note 댓글을 삭제하는 함수
         * @email sb.hwang@naviworks.com
         */
        agreeToDelete() {
            // 삭제
            this.$emit("deleteContent", this.answer.responseSn);
        },
    },
    created() {
        this.content = this.answer.responseCn;
    },
};
</script>

<style scoped>
.star-xr-community-detail-answer-mode {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    gap: 10px;
}

.star-xr-community-detail-answer-wrap {
    background-color: #fafafa;
    padding: 20px;
    position: relative;

    margin-bottom: 10px;
}

.star-xr-community-detail-title {
    display: flex;
    align-items: center;
    gap: 10px;

    margin-bottom: 18px;
}

.star-xr-community-detail-answer-content {
    line-height: 140%;
}

.v-list {
    background-color: #f5f5f5;
}

.v-list-item {
    gap: 3px;
}

.v-list-item__title {
    font-size: 14px;
}

::v-deep .ql-container {
    background-color: #ffffff;
}
</style>
