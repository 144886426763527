<!--
   [2024/08/16 sb.hwang]
   StarXrApi.vue
   @note STAR-XR api 화면 
-->
<template>
    <v-container fluid class="star-xr-api-container">
        <star-xr-docs-menu-content @select="selectApi" />
    </v-container>
</template>

<script>
import StarXrDocsMenuContent from "@/components/docs/StarXrDocsMenuContent.vue";
import { langCd } from "@/commons/resources";

export default {
    name: "StarXrApi",
    components: {
        StarXrDocsMenuContent,
    },
    computed: {
        langCd() {
            return langCd[this.$store.state.user.translation];
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * @method selectApi
         * @param {Objace} item 선택한 아이템
         * @note api 내용 조회하는 함수
         * @email sb.hwang@naviworks.com
         */
        selectApi() {
            this.$store
                .dispatch("selectApi", { langCd: this.langCd })
                .then((res) => {
                    this.$store.commit("setApiText", res);
                })
                .catch(() => {});
        },
    },
    created() {
        this.selectApi();
    },
    watch: {
        langCd() {
            this.selectApi();
        },
    },
};
</script>

<style scoped>
.star-xr-api-container {
    height: 100%;
}

/* vuetify lg(<1904px) */
@media (max-width: 1904px) {
    .star-xr-api-container {
        padding: 0;
        padding-bottom: 20px;
    }
}
</style>
