<!--
   [2024/02/13 gy.yang]
   StarXrHomeNews.vue
   @note 홈 화면 소식 컴포넌트
-->
<template>
    <v-container fluid>
        <div class="setion-title">{{ $t('news') }}</div>
        <v-row>
            <v-col
                v-for="(news, i) in newsList"
                :key="i"
                cols="12"
                md="6"
                lg="4"
            >
                <v-img
                    :src="require(`@/assets/images/${news.value}.svg`)"
                    cover
                    height="210"
                    class="img"
                >
                    <div class="pt-7 px-8">
                        <p
                            class="img-title"
                            v-html="news.title"
                        />
                        <p
                            class="img-sub"
                            v-html="news.sub"
                        />
                    </div>
                </v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrHomeNews',
    data() {
        return{
            newsList: [
                { title: 'Join us at MWC<br/>Barcelona 2024', sub: '26-29 February 2024<br/>Fira Gran Via, Barcelona<br/>Booth No. 7C62-8', value: 'mwc-bg' },
                { title: 'STAR-XR Beta<br/>Release Coming Soon!', sub: '', value: 'star-xr-bg' }
            ]
        }
    }
}
</script>

<style scoped>
.img {
    border-radius: 5px;
}

.img-title {
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 500;
    line-height: 28px;
}

.img-sub {
    color: #AFB8C1;
    font-size: 20px;
    line-height: 25px;
}
</style>