<!--
   [2024/08/09 gy.yang]
   StarXrSignUp.vue
   @note STAR-XR 회원가입 화면
-->
<template>
    <v-container fluid fill-height class="main-wrap">
        <v-row justify="center">
            <v-col class="px-md-0 px-6" cols="12" sm="8" lg="4" xl="3">
                <v-card class="signup-box py-12">
                    <v-row justify="center">
                        <v-col cols="10" xl="9">
                            <div class="star-xr-h2 font-semi-bold text-center">
                                {{ $t(page.title) }}
                            </div>
                            <template>
                                <component
                                    :is="page.component"
                                    @nextPage="movePage"
                                    @backPage="movePage"
                                    @clickSignUp="clickSignUp"
                                />
                            </template>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrSignUpTerms from "@/components/login/StarXrSignUpTerms.vue";
import StarXrSignUpInfoEmail from "@/components/login/StarXrSignUpInfoEmail.vue";
import StarXrSignUpCheckEmail from "@/components/login/StarXrSignUpCheckEmail.vue";
import StarXrSignUpInfoPw from "@/components/login/StarXrSignUpInfoPw.vue";
import StarXrSignUpInfoCompany from "@/components/login/StarXrSignUpInfoCompany.vue";
import StarXrSignUpInfoName from "@/components/login/StarXrSignUpInfoName.vue";
import StarXrSignUpEnd from "@/components/login/StarXrSignUpEnd.vue";
import { signUpStep } from "@/commons/resources";

export default {
    name: "StarXrSignUp",
    components: {
        StarXrSignUpTerms,
        StarXrSignUpInfoEmail,
        StarXrSignUpCheckEmail,
        StarXrSignUpInfoPw,
        StarXrSignUpInfoCompany,
        StarXrSignUpInfoName,
        StarXrSignUpEnd,
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    data() {
        return {
            page: signUpStep.Terms,
        };
    },
    methods: {
        /**
         * @method clickSignUp
         * @note 회원가입 완료해주는 함수
         * @email sb.hwang@naviworks.com
         */
        clickSignUp() {
            this.userSignup();
        },
        /**
         * @method movePage
         * @param {String} newPage
         * @note 회원가입 화면 변경 함수
         * @email gy.yang@naviworks.com
         */
        movePage(newPage) {
            this.page = newPage;
        },
        /**
         * @method userSignup
         * @note 회원가입하는 함수
         * @email sb.hwang@naviworks.com
         */
        userSignup() {
            const params = {
                emlAddr: this.user.emlAddr,
                pswd: this.user.pswd,
                userNm: this.user.userNm,
                brDt: this.user.brDt,
                nickNm: this.user.nickNm,
                telNo: this.user.telNo,
                companyNm: this.user.companyNm,
            };

            this.$store
                .dispatch("userSignup", params)
                .then(() => {
                    this.$store.dispatch("resetUser");
                    this.movePage(signUpStep.End);
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.main-wrap {
    max-height: 100%;
    background-color: #f9f9f9;
}

.signup-box {
    min-height: 470px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: none !important;
}

.star-xr-input::v-deep .v-input__slot {
    margin-top: 3px !important;
    margin-bottom: 1px !important;
}
</style>
