<!--
   [2024/08/20 gy.yang]
   starXrAdminUser.vue
   @note STAR-XR 사용자 관리 화면
-->
<template>
    <v-container fluid class="admin-user-container">
        <div class="star-xr-h1 font-semi-bold mb-12">
            {{ $t("userManagement") }}
        </div>
        <v-row>
            <v-col cols="10" sm="6" lg="4" xl="3">
                <search-input @search="search" />
            </v-col>
            <v-col cols="12" class="pt-0">
                <star-xr-admin-user-list :search="keyword" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StarXrAdminUserList from "@/components/account/StarXrAdminUserList.vue";
import SearchInput from "@/components/com/SearchInput.vue";

export default {
    name: "StarXrMyPage",
    components: {
        StarXrAdminUserList,
        SearchInput,
    },
    data() {
        return {
            editMode: false,
            btnName: "edit",
            keyword: "",
        };
    },
    methods: {
        /**
         * @method search
         * @param {String} search 검색어
         * @note 검색 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        search(search) {
            this.keyword = search;
        },
        changeBtnNm() {
            if (this.editMode) {
                this.btnName = "cancel";
            } else {
                this.btnName = "edit";
            }
        },
        changeEditMode() {
            this.editMode = !this.editMode;
            this.changeBtnNm();
        },
        saveEdit() {
            this.changeEditMode();
            //저장
        },
    },
};
</script>

<style scoped>
.admin-user-container {
    padding: 0;
    padding-right: 195px;
}

@media (max-width: 1904px) {
    .container {
        padding-right: 0;
    }
}
</style>
