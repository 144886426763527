<!--
   [2024/08/30 sb.hwang]
   StarXrAvatarKeyGuide.vue
   @note STAR-XR 아바타 편집 키 가이드 컴포넌트 
-->
<template>
    <div class="avatar-key-guide-wrap">
        <div class="avatar-key-guide">
            <div class="font-medium star-xr-h5">
                {{ $t("avatarEditKeyGuide") }}
            </div>
            <div class="avatar-key-guide-content-wrap">
                <star-xr-avatar-key-guide-content
                    v-for="(content, i) in contents"
                    :key="i"
                    :title="$t(content.title)"
                    :desc="$t(content.desc)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StarXrAvatarKeyGuideContent from "./StarXrAvatarKeyGuideContent.vue";

export default {
    name: "StarXrAvatarKeyGuide",
    components: {
        StarXrAvatarKeyGuideContent,
    },
    data() {
        return {
            contents: [
                {
                    title: "move",
                    desc: "moveKey",
                },
                {
                    title: "rotate",
                    desc: "rotateKey",
                },
                {
                    title: "zoom",
                    desc: "zoomKey",
                },
                {
                    title: "resetCamera",
                    desc: "resetCameraKey",
                },
            ],
        };
    },
    methods: {},
    created() {},
};
</script>

<style scoped>
.avatar-key-guide-wrap {
    min-width: 130px;
    background-color: #fafafa;
    border-radius: 10px;
}

.avatar-key-guide {
    padding: 14px 20px;
    word-break: keep-all;

    display: flex;
    flex-direction: column;
    gap: 30px;
}

.avatar-key-guide-content-wrap {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media (max-width: 1279px) {
    .avatar-key-guide-wrap {
        width: 100%;
        min-width: 960px;
    }
}
</style>
