<!--
   [2024/08/26 sb.hwang]
   FileInput.vue
   @note 파일 첨부 컴포넌트
-->
<template>
    <div class="file-input">
        <input
            :id="id"
            :accept="accept"
            type="file"
            class="real-file-input"
            ref="fileUploader"
            @change="onFileChange"
            @click="onFileClick"
        />
        <label
            :for="labelFor"
            :class="{ 'cursor-pointer': !disabled }"
            class="row ma-0 align-center"
        >
            <v-text-field
                :value="file ? file.name : ''"
                :class="{ active: !disabled }"
                :append-icon="file ? 'mdi-close' : ''"
                readonly
                hide-details
                :placeholder="placeholder"
                class="star-xr-input star-xr-file-input mt-0 pt-0"
                @click:append="clearFile"
            >
                <template v-slot:append-outer>
                    <div class="star-xr-file-button ma-0">
                        {{ $t("fileUpload") }}
                    </div>
                </template>
            </v-text-field>
        </label>
    </div>
</template>

<script>
export default {
    name: "FileInput",
    props: {
        value: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
        },
        id: {
            type: String,
            default: "file-upload-input",
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {
        file: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        labelFor() {
            return this.disabled ? "" : this.id;
        },
    },
    methods: {
        /**
         * @method onFileChange
         * @note 파일 변경 함수
         * @email sb.hwang@naviworks.com
         */
        onFileChange(event) {
            this.file = event.target.files[0];
            this.$refs.fileUploader.value = ""; // input 요소의 value 값 초기화
            this.$emit("fileChange");
        },
        /**
         * @method onFileClick
         * @note 파일 클릭 함수
         * @email sb.hwang@naviworks.com
         */
        onFileClick() {
            this.$emit("fileClick");
        },
        /**
         * @method clearFile
         * @note 파일 초기화 함수
         * @email sb.hwang@naviworks.com
         */
        clearFile() {
            this.$emit("clearFile");
        },
    },
};
</script>

<style>
.file-input {
    display: flex;
    align-items: center;
    gap: 9px;
}

.real-file-input {
    display: none;
}

.star-xr-file-input.active::v-deep .v-input__slot {
    opacity: 1 !important;
}

.star-xr-input .v-input__append-inner {
    margin: auto;
    padding-left: 0 !important;
    padding-right: 10px;
}
.star-xr-input .v-icon {
    font-size: 16px;
    color: #1e1e1e;
}

.star-xr-file-button {
    box-shadow: none !important;
    height: 40px !important;
    border-radius: 5px !important;
    border: 1px solid #b4b4b4;
    min-width: 89px;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 9px;
}
</style>
