<!--
   [2024/02/16 gy.yang]
   StarXrNav.vue
   @note 웹 페이지 메뉴 Nav
-->
<template>
    <v-navigation-drawer
        v-model="show"
        fixed
        temporary
        color="#FFFFFF"
        class="py-0"
    >
        <v-list class="py-0">
            <v-card
                tile
                color="#0A0A0A"
                elevation="0"
                height="70"
                class="d-flex align-center mb-3 pr-12"
            >
                <v-img
                    :src="require('@/assets/images/star-xr-logo.svg')"
                    contain
                    height="35"
                    class="cursor-pointer"
                    @click="routerHome"
                />
            </v-card>
            <v-list-item
                v-for="(menu, i) in menus"
                :key="i"
                :to="menu.to"
                class="px-10"
            >
                {{ menu.name }}
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { menus } from '@/commons/menus.js';

export default {
    name: 'StarXrMenuNav',
    props: {
        showNav: {
            type: Boolean
        }
    },
    data() {
        return {
            menus
        };
    },
    computed: {
        show: {
            get() {
                return this.showNav;
            },
            set(value) {
                this.$emit('update:show-nav', value);
            }
        }
    },
    methods: {
        /**
         * @method routerHome
         * @note 메인화면으로 이동하는 함수
         * @email gy.yang@naviworks.com
         */
        routerHome() {
            if(this.$route.name != 'starXrHome') {
                this.$router.push({
                    name: 'starXrHome'
                });
            }
        }
    }
}
</script>

<style>
.star-xr-menus-item {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: unset;
    color: #000000 !important;
}

</style>