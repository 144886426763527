<!--
   [2024/08/26 gy.yang]
   StarXrPaymentHistoryList.vue
   @note 결제 내역 목록 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="historyList"
            :items-per-page="itemsPerPage"
            :search="search"
            :page.sync="page"
            hide-default-footer
            :no-data-text="$t('noData')"
            :no-results-text="$t('noData')"
            class="star-xr-data-table"
            @click:row="clickItem"
            @page-count="pageCount = $event"
        >
            <template v-slot:[`item.paymentTtl`]="{ item }">
                <span class="font-color-black font-medium star-xr-h5">{{
                    item.paymentTtl
                }}</span>
            </template>
            <template v-slot:[`item.paymentDt`]="{ item }">
                <span
                    class="star-xr-h5 font-gray7"
                    :class="{
                        'text-line font-gray5': item.stateSn < 1,
                    }"
                >
                    {{ item.paymentDt }}
                </span>
            </template>
            <template v-slot:[`item.expiredDt`]="{ item }">
                <span
                    class="star-xr-h5 font-gray7"
                    :class="{
                        'text-line font-gray5': item.stateSn < 1,
                    }"
                >
                    {{ item.expiredDt }}
                </span>
            </template>
            <template v-slot:[`item.stateSn`]="{ item }">
                <span
                    class="star-xr-h5"
                    :class="{
                        'font-gray7': item.stateSn,
                        'font-gray5': !item.stateSn,
                    }"
                >
                    {{ item.stateSn > 0 ? "결제 완료" : "취소" }}
                </span>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            class="star-xr-pagination mt-16"
        />
    </v-container>
</template>

<script>
export default {
    name: "StarXrPaymentHistoryList",
    props: {
        search: {
            type: String,
        },
    },
    components: {},
    data() {
        return {
            historyList: [],
            page: 1,
            itemsPerPage: 10,
            pageCount: 0,
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t("orderInformation"), value: "paymentTtl" },
                {
                    text: this.$t("paymentDate"),
                    value: "paymentDt",
                    align: "center",
                },
                {
                    text: this.$t("expirationDate"),
                    value: "expiredDt",
                    align: "center",
                },
                { text: this.$t("status"), value: "stateSn", align: "center" },
            ];
        },
    },
    methods: {
        /**
         * @method clickItem
         * @param {Objace} item 선택한 아이템
         * @note 아이템 클릭 이벤트 함수
         * @email gy.yang@naviworks.com
         */
        clickItem(item) {
            this.$router
                .push({
                    name: "starXrPaymentHistoryDetail",
                    params: { idx: item.paymentSn },
                })
                .catch(() => {});
        },
        /**
         * @method getPayments
         * @note 결제 내역 반환하는 함수
         * @email gy.yang@naviworks.com
         */
        getPayments() {
            this.$store
                .dispatch("getPaymentList")
                .then((res) => {
                    this.historyList = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    created() {
        this.getPayments();
    },
};
</script>

<style scoped>
.text-line {
    text-decoration: line-through;
}

.star-xr-data-table::v-deep .v-data-table-header th {
    padding-right: 0;
}
</style>
