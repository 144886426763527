<!--
   [2024/02/13 gy.yang]
   StarXrHomeFeature.vue
   @note 홈 화면 특징 컴포넌트
-->
<template>
    <v-container fluid>
        <div class="setion-title">{{ $t('feature') }}</div>
        <v-row>
            <v-col
                v-for="(feature, i) in featureList"
                :key="i"
                cols="12"
                md="6"
                xl="3"
            >
                <div class="item py-8">
                    <v-img
                        :src="require(`@/assets/images/icon-${feature.value}.svg`)"
                        cover
                        width="30"
                        height="30"
                        class="mb-3"
                    />
                    <p class="item-title mb-2">
                        {{ feature.title }}
                    </p>
                    <p
                        class="item-sub-title"
                        v-html="$t(feature.sub)"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'StarXrHomeFeature',
    data() {
        return{
            featureList: [
                { title: 'Social Network Service', sub: 'service', value: 'service' },
                { title: 'NFT, E-COMMERCE', sub: 'nft', value: 'nft' },
                { title: 'GIS, Healthcare', sub: 'gis', value: 'gis' },
                { title: 'AI NPC', sub: 'ai', value: 'ai' }
            ]
        }
    }
}
</script>

<style scoped>
.item {
    min-height: 240px;
}

/* vuetify md(<1280px) */
@media (max-width: 1279px) {
    .item {
        min-height: 240px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .item {
        min-height: 210px;
    }
}
</style>