<!--
   [2024/02/13 gy.yang]
   StarXrLayout.vue
   @note STAR-XR 웹 페이지 레이아웃
-->
<template>
    <v-app>
        <star-xr-header @onControlMenu="showNav = !showNav" />
        <star-xr-nav :show-nav.sync="showNav" />
        <v-main class="star-xr-main-wrap">
            <router-view />
        </v-main>
        <star-xr-footer />
    </v-app>
</template>

<script>
import StarXrHeader from './StarXrHeader.vue';
import StarXrNav from './StarXrNav.vue';
import StarXrFooter from './StarXrFooter.vue';
import { windowScrollTop } from '@/commons/functions.js';

export default {
    name: 'StarXrLayout',
    components: {
        StarXrHeader,
        StarXrNav,
        StarXrFooter
    },
    data() {
        return {
            showNav: false
        };
    },
    watch: {
        $route() {
            windowScrollTop();
        }
    }
}
</script>

<style scoped>
.star-xr-main-wrap::v-deep .view-container {
    padding: 120px 325px;
}

/* vuetify lg(<1904px) */
@media (max-width: 1903px) {
    .star-xr-main-wrap::v-deep .view-container {
        padding: 100px 150px;
    }
}

/* vuetify md(<1264px) */
@media (max-width: 1263px) {
    .star-xr-main-wrap::v-deep .view-container {
        padding: 100px 120px;
    }
}

/* vuetify sm(<960px) */
@media (max-width: 959px) {
    .star-xr-main-wrap::v-deep .view-container {
        padding: 80px;
    }
}

/* vuetify xs(<600px) */
@media (max-width: 599px) {
    .star-xr-main-wrap::v-deep .view-container {
        padding: 40px;
    }
}
</style>