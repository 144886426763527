<!--
   [2024/02/14 gy.yang]
   StarXrHomeUseCasesDialog.vue
   @note 적용사례 영상 다이얼로그 컴포넌트
-->
<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
        max-width="980"
    >
        <v-card>
            <v-container class="full-height">
                <v-row class="px-0">
                    <v-col class="dialog-title pl-7 pt-4 pb-0">
                        {{ $t(title) }}
                    </v-col>
                    <v-col class="pr-6 pt-4 pb-0 text-right">
                        <v-icon
                            color="#000000"
                            @click="dialog = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <iframe
                            :src="`https://www.youtube.com/embed/${video}`"
                            style="border-radius: 5px; display: block;"
                            width="100%"
                            height="534"
                            frameborder="0"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'StarXrHomeUseCasesDialog',
    props: {
        value: {
            type: Boolean
        },
        example: {
            type: Object
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    data() {
        return {
            title: '',
            video: '',
        };
    },
    watch: {
        dialog(value) {
            if(value) {
                this.title = this.example.title;
                this.video = this.$i18n.locale == 'ko' ? this.example.toKo : this.example.toEn;
            } else {
                this.title = '';
                this.video = '';
            }
        }
    }
}
</script>

<style scoped>
.dialog-title {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
}
</style>