<!--
   [2024/08/27 sb.hwang]
   StarXrAdminServiceList.vue
   @note 서비스 관리 서비스 목록 컴포넌트
-->
<template>
    <v-container fluid class="pa-0">
        <v-data-table
            :headers="headers"
            :items="serviceList"
            :items-per-page="itemsPerPage"
            :search="search"
            :page.sync="page"
            hide-default-footer
            :no-data-text="$t('noData')"
            :no-results-text="$t('noData')"
            class="star-xr-data-table"
            @click:row="clickToDetail"
            @page-count="pageCount = $event"
        >
            <template v-slot:[`item.srvcTtl`]="{ item }">
                <span class="font-color-black font-medium star-xr-h5">{{
                    item.srvcTtl
                }}</span>
            </template>
            <template v-slot:[`item.companyNm`]="{ item }">
                <span class="font-color-black font-medium star-xr-h5">{{
                    item.companyNm
                }}</span>
            </template>
            <template v-slot:[`item.category`]="{ item }">
                <span class="font-color-black font-medium star-xr-h5">{{
                    $t(srvcCategory[item.srvcCtgrySn])
                }}</span>
            </template>
            <template v-slot:[`item.state`]="{ item }">
                <v-switch
                    v-model="item.stateSn"
                    hide-details
                    :true-value="1"
                    :false-value="0"
                    class="star-xr-switch"
                    @click.stop
                    @change="changeState(item)"
                />
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            class="star-xr-pagination mt-16"
        />
    </v-container>
</template>

<script>
import { getPageLength, windowScrollTop } from "@/commons/functions.js";
import { serviceStatus, srvcCategory } from "@/commons/resources.js";

export default {
    name: "StarXrAdminServiceList",
    props: {
        search: {
            type: String,
        },
    },
    components: {},
    computed: {
        pageLength() {
            return getPageLength(this.serviceList.length, this.itemsPerPage);
        },
        headers() {
            return [
                { text: this.$t("title"), value: "srvcTtl" },
                { text: this.$t("developer"), value: "companyNm" },
                {
                    text: this.$t("categories"),
                    value: "category",
                    align: "center",
                },
                {
                    text: this.$t("registrationDate"),
                    value: "uploadDt",
                    align: "center",
                },
                {
                    text: this.$t("upload"),
                    value: "state",
                    align: "center",
                    sortable: false,
                },
            ];
        },
    },
    data() {
        return {
            serviceList: [],
            page: 1,
            itemsPerPage: 10,
            pageCount: 0,
            serviceStatus,
            srvcCategory,
        };
    },
    methods: {
        /**
         * @method clickToDetail
         * @param {Objace} item 선택한 아이템
         * @note 서비스 정보로 이동하는 함수
         * @email sb.hwang@naviworks.com
         */
        clickToDetail(item) {
            this.$store.commit("setServiceSn", item.srvcSn);
            this.$router.push({
                name: "starXrAdminServiceDetail",
                params: { idx: item.srvcSn },
            });
        },
        /**
         * @method changeState
         * @param {Objace} item 선택한 아이템
         * @note 서비스 상태 변경 함수
         * @email gy.yang@naviworks.com
         */
        changeState(item) {
            const params = {
                srvcSn: item.srvcSn,
                stateSn: item.stateSn,
            };

            this.$store.dispatch("updateServiceState", params).catch((err) => {
                console.log(err);
            });
        },
        /**
         * @method selectAdminServiceList
         * @note 서비스 목록 불러오는 함수
         * @email gy.yang@naviworks.com
         */
        selectAdminServiceList() {
            this.$store.dispatch("selectAdminServiceList").then((res) => {
                this.serviceList = res.servicesList;
            });
        },
    },
    created() {
        this.selectAdminServiceList();
    },
    watch: {
        page() {
            windowScrollTop();
        },
    },
};
</script>

<style scoped></style>
