<!--
   [2024/08/26 sb.hwang]
   StarXrCommunityList.vue
   @note STAR-XR community list 컴포넌트
-->
<template>
    <div>
        <template v-if="items && items.length > 0">
            <star-xr-community-list-item
                v-for="(item, i) in pageList"
                :key="i"
                :item="item"
                @clickItem="$emit('clickItem', $event)"
            />
        </template>
        <div class="text-center my-8">
        <v-pagination
            v-model="page"
            :length="pageLength"
            class="star-xr-pagination mt-16"
        />
        </div>
    </div>
</template>

<script>
import StarXrCommunityListItem from './StarXrCommunityListItem.vue';
import { getPageLength, getPageList, windowScrollTop } from '@/commons/functions.js';

export default {
    name: 'StarXrCommunityList',
    components: { 
        StarXrCommunityListItem
    },
    props: {
        items: {
            type: Array
        },
        rows: {
            type: Number,
            default: 6
        },
    },
    data() {
        return {
            page: 1,
        };
    },
    computed: {
        /**
         * @method pageLength
         * @note 페이지 수를 가져오는 함수
         * @email sb.hwang@naviworks.com
         */
        pageLength(){
            return getPageLength(this.items.length, this.rows);
        },
        /**
         * @method pageList
         * @note 페이지에 전시할 아이템을 가져오는 함수
         * @email sb.hwang@naviworks.com
         */
        pageList() {
            return getPageList(this.page, this.items, this.rows);
        }
    },
    watch: {
        page() {
            windowScrollTop();
        }
    }
}
</script>

<style scoped>

</style>