import Vue from "vue";
import VueRouter from "vue-router";
import { getCurrMenuTitle, windowScrollTop } from "@/commons/functions.js";
import StarXrLayout from "../layouts/StarXrLayout.vue";
import StarXrHome from "../views/home/StarXrHome.vue";
import StarXrLogin from "@/views/login/StarXrLogin.vue";
import StarXrSignUp from "@/views/login/StarXrSignUp.vue";
import StarXrFindPw from "@/views/login/StarXrFindPw.vue";
import starXrDocsLayout from "../layouts/docs/StarXrDocsLayout.vue";
import StarXrApi from "../views/api/StarXrApi.vue";
import StarXrDocument from "@/views/document/StarXrDocument.vue";
import StarXrTools from "@/views/tools/StarXrTools.vue";
import StarXrPayment from "@/views/payment/StarXrPayment.vue";
import StarXrPaymentComplete from "@/views/payment/StarXrPaymentComplete.vue";
import StarXrPaymentFail from "@/views/payment/StarXrPaymentFail.vue";
import KakaoPay from "@/views/payment/kakao/KakaoPay.vue";
import KakaoPayTest from "@/views/payment/kakao/KakaoPayTest.vue";
import KakaoPayEnd from "@/views/payment/kakao/KakaoPayEnd.vue";
import StarXrCommunity from "@/views/community/StarXrCommunity.vue";
import StarXrCommunityDetail from "@/views/community/StarXrCommunityDetail.vue";
import StarXrCommunityEdit from "@/views/community/StarXrCommunityEdit.vue";
import starXrServiceLayout from "../layouts/service/StarXrServiceLayout.vue";
import StarXrServiceList from "../views/service/StarXrServiceList.vue";
import StarXrServiceDetail from "@/views/service/StarXrServiceDetail.vue";
import StarXrAccountLayout from "@/layouts/accountLayout/StarXrAccountLayout.vue";
import accountRoutes from "./account";

Vue.use(VueRouter);

const checkAuth = async function (requireAuth) {
    if (requireAuth === undefined) return undefined;

    const loginRedirect = requireAuth ? undefined : { name: "starXrHome" };
    const logoutRedirect = requireAuth ? { name: "starXrLogin" } : undefined;

    if (!Vue.prototype.$cookies.get("user_token")) {
        return logoutRedirect;
    } else {
        return loginRedirect;
    }
};

const routes = [
    {
        // 임시 이동
        path: "/",
        redirect: {
            name: "starXrHome",
        },
    },
    {
        path: "/kakaoPay",
        name: "kakaoPay",
        component: KakaoPay,
    },
    {
        path: "/kakaoPay-test",
        name: "kakaoPayTest",
        component: KakaoPayTest,
    },
    {
        path: "/kakaoPay-end",
        name: "kakaoPayEnd",
        component: KakaoPayEnd,
    },
    {
        path: "/",
        component: StarXrLayout,
        children: [
            {
                path: "/",
                name: "starXrHome",
                component: StarXrHome,
            },
            {
                path: "/login",
                name: "starXrLogin",
                component: StarXrLogin,
            },
            {
                path: "/signup",
                name: "starXrSignUp",
                component: StarXrSignUp,
            },
            {
                path: "/find/pw",
                name: "starXrFindPw",
                component: StarXrFindPw,
            },
            {
                path: "/api",
                component: starXrDocsLayout,
                children: [
                    {
                        path: "",
                        name: "starXrApi",
                        component: StarXrApi,
                    },
                ],
            },
            {
                path: "/document",
                component: starXrDocsLayout,
                children: [
                    {
                        path: "",
                        name: "starXrDocument",
                        component: StarXrDocument,
                    },
                ],
            },
            {
                path: "/tools",
                name: "starXrTools",
                component: StarXrTools,
            },
            {
                path: "/payment",
                name: "starXrPayment",
                component: StarXrPayment,
            },
            {
                path: "/payment/complete",
                name: "starXrPaymentComplete",
                component: StarXrPaymentComplete,
            },
            {
                path: "/payment/fail",
                name: "starXrPaymentFail",
                component: StarXrPaymentFail,
            },
            {
                path: "/community",
                name: "starXrCommunity",
                component: StarXrCommunity,
            },
            {
                path: "/community/detail/:idx",
                name: "starXrCommunityDetail",
                component: StarXrCommunityDetail,
            },
            {
                path: "/community/edit/:idx",
                name: "starXrCommunityEdit",
                component: StarXrCommunityEdit,
            },
            {
                path: "service",
                component: starXrServiceLayout,
                children: [
                    {
                        path: "",
                        name: "starXrServiceList",
                        component: StarXrServiceList,
                    },
                    {
                        path: ":idx",
                        name: "starXrServiceDetail",
                        component: StarXrServiceDetail,
                    },
                ],
                meta: { requireAuth: true },
            },
            {
                path: "/account",
                component: StarXrAccountLayout,
                children: accountRoutes,
                meta: { requireAuth: true },
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    let requireAuth = to.matched.some((item) => item.meta.requireAuth);

    if (!requireAuth) {
        // 로그아웃 상태 필수(false) 값이 있는지 확인
        const isNotUndefined = to.matched.some(
            (item) => item.meta.requireAuth === false
        );
        requireAuth = isNotUndefined ? false : undefined;
    }

    const redirect = await checkAuth(requireAuth);

    if (redirect) {
        setTimeout(() => {
            next(redirect);
        }, 1);
    } else {
        setTimeout(() => {
            next();
        }, 1);
    }
});

router.afterEach((to) => {
    windowScrollTop();
    document.body.tabIndex = 0;
    document.body.focus();
    document.body.tabIndex = undefined;
    document.title = `${getCurrMenuTitle(to)}`;
});
export default router;
